import withLayout from '../../../layout/withLayout'
import WEB_PATHS from '../../../constant/webPath'
import { useHistory, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import userApi from '../../../api/user.api'
import IUserTransactionBet from '../../../model/UserApi/IUserTransactionBet'
import { MENU_TITLE } from '../../../constant/menuTitle'
import TabMenu from '../layout/index'
// import IUserTransactionBetList from '../../../model/UserApi/IUserTransactionBetList'
import { dateFormat } from '../../../utils/dateFormat'
import IUserHistoryTransaction from '../../../model/UserApi/IUserHistoryTransaction'
import toast from 'react-hot-toast'
import ToastStateIcon from '../../../components/ToastStateIcon'
import IToastState from '../../../model/toastState'

const TransactionHistoryBetList = (): React.ReactElement => {
  const { id }: any = useParams()
  const [transactionBetData, setTransactionBetData] = useState<IUserTransactionBet>({
    cntAll: 0,
    cntAwaiting: 0,
    cntCancel: 0,
    cntLost: 0,
    cnt_won: 0,
    complete: 0,
    groupList: [],
    notComplete: 0,
    total: 0
  })
  const history = useHistory()
  useEffect(() => {
    const fetchLimitLateDate = async () => {
      try {
        const data = await userApi.userTransaction(id)
        setTransactionBetData(data)
      } catch (e) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    }
    fetchLimitLateDate()
  }, [])
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const backPage = (): void => {
    history.push(WEB_PATHS.USER_EDIT.replace(':id', String(id)))
  }
  const viewTransactionBet = (idBetTransaction: string | number): void => {
    const replaceUrl = WEB_PATHS.USER_TRANSACTION_BET_BY_ID.replace(':id', String(id))
    history.push({
      pathname: replaceUrl.replace(':idBet', String(idBetTransaction))
    })
  }
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-[1000px] pb-2.5">
        <div className="flex px-2 items-center scrollbar-hide overflow-auto w-full">
          <div className="sm:w-[5%] w-[30px]">#</div>
          <div className="sm:w-[15%] w-[150px] text-left">ประเภทเกม</div>
          <div className="sm:w-[15%] w-[220px] text-left">วันที่</div>
          <div className="sm:w-[15%] w-[220px] text-left">รอบที่</div>
          <div className="sm:w-[15%] w-[200px] text-right">จำนวนเงินทั้งหมด</div>
          <div className="sm:w-[15%] w-[150px] text-right">ราคาสุทธิ</div>
          <div className="sm:w-[10%] w-[150px] text-left px-2.5">สถานะ</div>
          <div className="sm:w-[10%] w-[100px] text-left"></div>
        </div>
        {renderContent(transactionBetData.groupList)}
      </div>
    )
  }
  const renderContent = (item: IUserHistoryTransaction[]): React.ReactNode => {
    if (item.length <= 0) return <div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2">ไม่มีข้อมูล</div>
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={index}>
        <div className="sm:w-[5%] w-[30px] text-text-6">{index+1}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6">{content.lottoName}</div>
        <div className="sm:w-[15%] w-[220px] overflow-hidden truncate text-16px text-text-6">{dateFormat(content.gameDate, 'dd-MM-yyyy HH:mm')}</div>
        <div className="sm:w-[15%] w-[220px] overflow-hidden truncate text-16px text-text-6">{dateFormat(content.roundDate, 'dd-MM-yyyy HH:mm')}</div>
        <div className="sm:w-[15%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-right">{content.total.toLocaleString('th')} ฿</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-right">{content.resultPrice.toLocaleString('th')}​ ฿</div>
        <div className="sm:w-[10%] w-[150px] overflow-hidden truncate text-16px text-text-6 px-2.5">{content.statusString}</div>
        <button onClick={(): void => viewTransactionBet(Number(content.transactionId))} className="group sm:w-[10%] flex justify-end w-[100px]">
          <svg className="group-hover:hidden block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#1E2339"/>
            <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#1E2339"/>
          </svg>
          <svg className="group-hover:block hidden" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#0F2898"/>
            <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#0F2898"/>
          </svg>
        </button>
      </div>
      )
    })
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.USER_TRANSACTION_GAME}</span>
      </div>
      <div>
        <TabMenu state={MENU_TITLE.USER_TRANSACTION_GAME} id={id} />
      </div>
      <div className="flex flex-1 sm:flex-row flex-col gap-3 pt-3 pb-5">
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">รายการทั้งหมด</span>
          <span className="text-text-0">{transactionBetData.total.toLocaleString('th')} THB</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">รายการที่สำเร็จ</span>
          <span className="text-text-0">{transactionBetData.complete.toLocaleString('th')} THB</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">รายการไม่ที่ยังไม่สำเร็จ</span>
          <span className="text-text-0">{transactionBetData.notComplete.toLocaleString('th')} THB</span>
        </div>
      </div>
      <div className="sm:w-full w-full min-w-full scrollbar-hide overflow-x-scroll">
        {renderTable()}
      </div>
    </div>
  )
}

export default withLayout(TransactionHistoryBetList, 'User')