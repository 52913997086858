import withLayout from "../../../layout/withLayout"
import WEB_TITLE from "../../../constant/webTitle"
import BeradCrump from "../../../components/BreadCrump"
import { MENU_TITLE } from "../../../constant/menuTitle"
import { Formik } from "formik"
import IToastState from "../../../model/toastState"
import toast from "react-hot-toast"
import ToastStateIcon from "../../../components/ToastStateIcon"
import { useState, useEffect } from 'react'
import IGameYeeGeeSetting from "../../../model/GameYeegee/IGameYeeGee"
import gameApi from "../../../api/game.api"
import * as Yup from 'yup'
import Input from "../../../components/Input"
import Button from "../../../components/Button"

const GameYeeGeeSetting = () => {
  const [yeegeeSetting, setYeegeeSetting] = useState<IGameYeeGeeSetting>({
    id: 0,
    credit: 0,
    customNumber: 0,
    customCredit: 0,
    conditionRate: 0
  })
  const fetchYeeGeeSetting = async (): Promise<void> => {
    try {
      const response = await gameApi.getYeeGeeSetting()
      setYeegeeSetting(response)
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
    }
  }
  useEffect(() => {
    fetchYeeGeeSetting()
  },[])
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const schema = Yup.object().shape({
    credit: Yup.number()
      .typeError('กรุณากรอกตัวเลขเท่านั้น')
      .required('จำเป็นต้องกรอกข้อมูล'),
    customNumber: Yup.number()
      .typeError('กรุณากรอกตัวเลขเท่านั้น')
      .required('จำเป็นต้องกรอกข้อมูล'),
    customCredit: Yup.number()
      .typeError('กรุณากรอกตัวเลขเท่านั้น')
      .required('จำเป็นต้องกรอกข้อมูล'),
    conditionRate: Yup.number()
      .typeError('กรุณากรอกตัวเลขเท่านั้น')
      .required('จำเป็นต้องกรอกข้อมูล')
  })
  return (
    <div>
      <BeradCrump headTitle={MENU_TITLE.GAME} showIcon={true} classTitle="font-katnitBold" title={MENU_TITLE.GAME_YEEGEE} />
      <div>
      <Formik
        initialValues={{
          id: yeegeeSetting.id,
          credit: yeegeeSetting.credit,
          customNumber: yeegeeSetting.customNumber,
          customCredit: yeegeeSetting.customCredit,
          conditionRate: yeegeeSetting.conditionRate
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values: any) => {
          const result = {
            id: Number(values.id),
            credit: Number(values.credit),
            customNumber: Number(values.customNumber),
            customCredit: Number(values.customCredit),
            conditionRate: Number(values.conditionRate)
          }
          try {
              await gameApi.saveYeeGeeSetting(result)
              fetchYeeGeeSetting()
              toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
            } catch (e) {
              toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
            }
          }
        }>
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Input
              name="credit"
              type="text"
              value={props.values.credit}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput="จำนวนเงินโบนัสสำหรับคนยิงคนสุดท้าย"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={props.touched.credit && props.errors.credit}
            />
            <Input
              name="customNumber"
              type="text"
              value={props.values.customNumber}
              placeholder="กรอกลำดับที่ เช่น 17"
              textLabelTopInput="ลำดับที่ต้องการให้ได้รับรางวัล"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={props.touched.customNumber && props.errors.customNumber}
            />
            <Input
              name="customCredit"
              type="text"
              value={props.values.customCredit}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput="จำนวนเงินโบนัสสำหรับคนที่ติดอันดับ"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={props.touched.customCredit && props.errors.customCredit}
            />
            <Input
              name="conditionRate"
              type="text"
              value={props.values.conditionRate}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput="ยอดเงินขั้นต่ำที่ต้องการให้เล่น"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={props.touched.conditionRate && props.errors.conditionRate}
            />
            <Button
              type="submit"
              buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-3 text-16px"
              buttonText="บันทึก"
            />
          </form>
        )}
      </Formik>
      </div>
    </div>
  )
}

export default withLayout(GameYeeGeeSetting, WEB_TITLE.GAME, WEB_TITLE.GAME_YEEKEE)