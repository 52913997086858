import usePortal from '../hook/usePortal'

const Modal = ({
  element,
}: {
  element: React.ReactElement
}): React.ReactElement => {
  const renderModal = (): React.ReactElement => {
    return element
  }
  return usePortal([renderModal(),'#myportal'])
}

export default Modal