import withLayout from '../../../layout/withLayout'
import { useEffect, useState } from 'react'
import gameApi from '../../../api/game.api'
import { Formik } from 'formik'
import Button from '../../../components/Button'
import * as Yup from 'yup'
import Input from '../../../components/Input'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BeradCrump from '../../../components/BreadCrump'
import IGameLimit from '../../../model/GameRateApi/IGameLimit'
import IGameLimitGov from '../../../model/GameRateApi/IGameLimitGov'
import WEB_TITLE from '../../../constant/webTitle'
import IGameTypeSelect from '../../../model/IGameTypeSelect'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'

const GameRate = (): React.ReactElement => {
  const [typeSelect, setTypeSelect] = useState<IGameTypeSelect[]>([])
  const [rateGameSelect, setRateGameSelect] = useState<number>(0)
  const [indexRange, setIndexRange] = useState(0)
  const [rateGameSetting, setRateGameSetting] = useState<IGameLimit>({
    id: 0,
    name: '',
    description: '',
    govLottoRateTier: []
  })
  const fectLateGameSetting = async (id: number) => {
    try {
      const response = await gameApi.getGameRateSetting(String(id))
      setIndexRange(response.govLottoRateTier[0].id)
      setRateGameSetting(response)
    } catch (error) {
      alert(error)
    }
  }
  useEffect(() => {
    const fetchTypeSelect = async (): Promise<void> => {
      try {
        const response = await gameApi.getGameType(String(1))
        setTypeSelect(response)
      } catch (error) {
        alert(error)
      }
    }
    fetchTypeSelect()
  }, [])
  useEffect(() => {
    if (rateGameSelect > 0) fectLateGameSetting(rateGameSelect)
  }, [rateGameSelect])
  const schema = Yup.object().shape({
    govLottoRateTier: Yup.array().of(
      Yup.object().shape({
        priceStart: Yup.string()
          .required('required')
          .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น'),
        priceEnd: Yup.string()
        .required('required')
        .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น'),
        payAmount: Yup.string()
          .required('required')
          .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
      })
    )
  })
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderRow = (props: any): React.ReactElement => {
    return props.values.govLottoRateTier.map((item: any, index: number) => {
      return (
        <div key={index}>
          <div
            className="items-center mb-4 flex whitespace-nowrap sm:w-2/4 w-full gap-3"
            key={index}>
            <div className="w-[4%]">{index + 1}.</div>
            <Input
              name={`govLottoRateTier.${index}.priceStart`}
              type="text"
              value={item.priceStart}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput={`ราคาเริ่มต้นที่ ${index + 1}`}
              showTextLabelTopInput={false}
              classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
              inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-right border border-border-0 h-8 text-text-2 py-3 px-2.5 rounded-lg"
              divClassName="flex flex-col sm:w-[20%] w-[26%]"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={
                props.errors &&
                props.errors.govLottoRateTier &&
                props.errors.govLottoRateTier[index] &&
                props.errors.govLottoRateTier[index].priceStart &&
                props.touched &&
                props.touched.govLottoRateTier &&
                props.touched.govLottoRateTier[index] &&
                props.touched.govLottoRateTier[index].priceStart && props.errors.govLottoRateTier[index].priceStart
              }
            />
            <Input
              name={`govLottoRateTier.${index}.priceEnd`}
              type="text"
              value={item.priceEnd}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput={`ราคาสิ้นสุดที่ ${index + 1}`}
              showTextLabelTopInput={false}
              classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
              inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-right border border-border-0 h-8 text-text-2 py-3 px-2.5 rounded-lg"
              divClassName="flex flex-col sm:w-[20%] w-[25%]"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={
                props.errors &&
                props.errors.govLottoRateTier &&
                props.errors.govLottoRateTier[index] &&
                props.errors.govLottoRateTier[index].priceEnd &&
                props.touched &&
                props.touched.govLottoRateTier &&
                props.touched.govLottoRateTier[index] &&
                props.touched.govLottoRateTier[index].priceEnd && props.errors.govLottoRateTier[index].priceEnd
              }
            />
            <Input
              name={`govLottoRateTier.${index}.payAmount`}
              type="text"
              value={item.payAmount}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput={`เลทจ่าย ${index + 1}`}
              showTextLabelTopInput={false}
              classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
              inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-right border border-border-0 h-8 text-text-2 py-3 px-2.5 rounded-lg"
              divClassName="flex flex-col sm:w-[40%] w-[30%]"
              disabled={false}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
              error={
                props.errors &&
                props.errors.govLottoRateTier &&
                props.errors.govLottoRateTier[index] &&
                props.errors.govLottoRateTier[index].payAmount &&
                props.touched &&
                props.touched.govLottoRateTier &&
                props.touched.govLottoRateTier[index] &&
                props.touched.govLottoRateTier[index].payAmount && props.errors.govLottoRateTier[index].payAmount
              }
            />
            <div className="flex flex-row sm:items-center sm:w-[15%]">
              { props.values.govLottoRateTier.length > 1 &&
              <div className="flex items-center sm:mr-4 group">
                <button
                  type="button"
                  className="group"
                  onClick={() => removeRow(props as any, index as number)}
                >
                  <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="30" height="30" rx="5" stroke="#D83434" strokeWidth="2"/>
                    <rect x="6" y="14" width="20" height="4" fill="#D83434"/>
                  </svg>
                  <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="30" height="30" rx="5" stroke="#B00909" strokeWidth="2"/>
                    <rect x="6" y="14" width="20" height="4" fill="#B00909"/>
                  </svg>
                </button>
              </div>
              }
              { props.values.govLottoRateTier.length === index + 1 &&
                <div className={props.values.govLottoRateTier.length < 7 ? 'sm:flex items-center group hidden' : 'hidden'}>
                  <button
                    type="button"
                    onClick={() => addRow(props)}>
                      <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="30" height="30" rx="5" stroke="#3473D8" strokeWidth="2"/>
                        <rect x="6" y="14" width="20" height="4" fill="#3473D8"/>
                        <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#3473D8"/>
                      </svg>
                      <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="30" height="30" rx="5" stroke="#0944DD" strokeWidth="2"/>
                        <rect x="6" y="14" width="20" height="4" fill="#0944DD"/>
                        <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#0944DD"/>
                      </svg>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      )
    })
  }
  const addRow = (props: any) => {
    if (props.values.govLottoRateTier.length < 7){
      props.setValues({
        govLottoRateTier: [...props.values.govLottoRateTier, {
          priceEnd: '',
          priceStart: '',
          payAmount: ''
        }]
      })
    }
  }
  const removeRow = (props: any, index: number) => {
    const data = props.values.govLottoRateTier
    data.splice(index, 1)
    props.setValues({
      govLottoRateTier: data
    })
  }
  const renderSelectType = (): React.ReactNode => {
    if (rateGameSelect < 0) return
    return (
      <select
      className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
        name="reateGameSelect"
        value={rateGameSelect}
        onChange={(e) => {
        setRateGameSelect(Number(e.target.value))
      }}>
        <option value="0">กรุณาเลือกประเภทตัวเลข</option>
        {
          typeSelect.map(item => {
            return (<option key={item.id} value={item.id}>{item.value}</option>)
          })
        }
        </select>
    )
  }
  const findSelect = (): React.ReactElement => {
    return (
      <div className="pb-3">{typeSelect[typeSelect.findIndex(item => item.id === rateGameSelect)].value}</div>
    )
  }
  return (
    <div className="flex flex-col">
      <BeradCrump headTitle={MENU_TITLE.GAME} showIcon={true} classTitle="font-katnitBold" title={MENU_TITLE.GAME_LIMIT_RATE} />
      <div className="pt-6 pb-3 sm:w-2/4 w-full">
        {renderSelectType()}
      </div>
      { rateGameSelect > 0 &&
        <Formik
          initialValues={{
            id: rateGameSetting.id,
            name: rateGameSetting.name,
            description: rateGameSetting.description,
            govLottoRateTier: rateGameSetting.govLottoRateTier
          }}
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (values) => {
            try {
              let indexId = indexRange
              const data = values.govLottoRateTier.reduce((array: IGameLimitGov[], current) => {
                array.push({
                  gameCateId: rateGameSetting.id,
                  id: indexId,
                  payAmount: Number(current.payAmount),
                  priceEnd: Number(current.priceEnd),
                  priceStart: Number(current.priceStart),
                  rateType: rateGameSelect,
                })
                indexId++
                return array
              }, [])
              await gameApi.updateGameRateSetting(data)
              setRateGameSelect(0)
              toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
            } catch (e) {
              toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
            }
          }}>
            { props => (
              <form onSubmit={props.handleSubmit}>
                <div className="flex justify-between items-center">
                  <div className="text-20px sm:hidden block">
                    {findSelect()}
                  </div>
                  <div className={props.values.govLottoRateTier.length < 7 ? 'sm:hidden items-center flex justify-end' : 'hidden'}>
                    <button
                      type="button"
                      className="group"
                      onClick={() => addRow(props)}>
                        <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#3473D8" strokeWidth="2"/>
                          <rect x="6" y="14" width="20" height="4" fill="#3473D8"/>
                          <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#3473D8"/>
                        </svg>
                        <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#0944DD" strokeWidth="2"/>
                          <rect x="6" y="14" width="20" height="4" fill="#0944DD"/>
                          <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#0944DD"/>
                        </svg>
                    </button>
                  </div>
                </div>
                <div className="w-full">
                  <div className="pb-2.5 flex gap-3 sm:w-2/4 w-full">
                    <div className="w-[4%] text-16px">#</div>
                    <div className="sm:w-[21%] w-[28%] text-16px">ราคาเริ่มต้น</div>
                    <div className="sm:w-[20%] w-[26%] text-16px">ราคาสิ้นสุด</div>
                    <div className="sm:w-[40%] w-[30%] text-16px">เรทราคา</div>
                    <div className="sm:w-[16%] w-[12%] text-16px"></div>
                  </div>
                  <div>
                    {renderRow(props as object)}
                  </div>
                </div>
                <Button
                  buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
                  buttonText="บันทึก"
                  type="submit"
                />
            </form>
            )
          }
        </Formik>
      }
    </div>
  )
}

export default withLayout(GameRate,  WEB_TITLE.GAME, WEB_TITLE.GAME_RATE)