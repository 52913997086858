const API_PATHS = {
  LOGIN: 'admin/login',
  MEMBER_PROFILE: '/user/profile', 
  DASHBOARD: '/admin/wallet',
  TEXT_RUN: 'home',
  TEXT_RUN_SAVE: 'admin/home',
  SWITCH: 'admin/setting',
  BOT_SETTING_WITHDRAW: 'admin/setting/bot/withdraw',
  BOT_SETTING_DEPOSIT: 'admin/setting/bot/deposit',
  BOT_SETTING_DEPOSIT_GROUP: 'admin/setting/bot/deposit/banks',
  BANK: 'bank',
  BANK_ADMIN: 'bank/admin',
  BANK_LIST_ADMIN: 'admin/bank/admin',
  BANK_GROUP: 'admin/bank/group',
  BANK_GROUP_ID: 'admin/bank/group/:id',
  BANK_ID: 'admin/bank/admin/:id',
  BLOG: 'blog',
  BLOG_PIN: 'admin/blog/pinned',
  BLOG_SAVE: 'admin/blog',
  BLOG_ID: 'admin/blog/:id',
  GAME_RATE: 'admin/setting/game/rate/:id',
  GAME_RATE_SYSTEM: 'admin/setting/system-limit/:id',
  GAME: 'admin/setting/game/:id',
  GAME_RATE_USER_ID: 'admin/users/payrate/:id',
  GAME_RATE_USER: 'admin/users/payrate',
  GAME_ROUND: 'admin/setting/game-round',
  GAME_YEE_GEE: 'admin/setting/yeekee',
  GAME_SET_SETTING_GET: 'admin/setting/set/:id',
  GAME_SET_SETTING_UPDATE: 'admin/setting/set',
  USER: 'admin/users',
  USER_BAN: 'admin/users/ban',
  USER_AFFILIATE_LIST: 'admin/user/:id/affiliate/list',
  USER_AFFILIATE_REVENUE: 'admin/user/:id/affiliate/revenue',
  USER_AFFILIATE_HISTORY: 'admin/user/:id/affiliate/history',
  ADMIN: 'admin/users/admin',
  USER_ID: 'admin/users/:id',
  USER_FINANCE_HISTORY: 'admin/user/:id/finance-history',
  USER_WALLET_BY_ID: 'admin/user/:id/wallet',
  USER_WALLET_UPDATE_BY_ID: 'admin/users/add-amount',
  USER_WALLET_UPDATE_SUB_AMOUNT_BY_ID: 'admin/users/sub-amount',
  USER_AMOUNT_HISTORY: 'admin/user/:id/transactions/approved',
  DEPOSIT_MENU: 'admin/deposit/group',
  DEPOSIT_LIST: 'admin/deposit',
  DEPOSIT_TEMP_HISTORY: 'admin/bank/temp',
  DEPOSIT_LIST_ID: 'admin/deposit/:id',
  WITHDRAW_MENU: 'admin/withdraw/group',
  WITHDRAW: 'admin/withdraw',
  WITHDRAW_TYPE: 'admin/withdraw/:type',
  WITHDRAW_APPROVE_ALL: 'admin/withdraw/batch/approve',
  QUICK_PANEL: 'admin/game-panel',
  QUICK_PANEL_RESULT: 'admin/game-panel/:id/head-summary',
  QUICK_PANEL_DETAILS: 'admin/game-panel/:id/detail',
  QUICK_PANEL_NUMBER_BY_ID: 'admin/game-panel/:id/by-number',
  QUICK_PANEL_GAME_CANCEL_BY_ID: 'admin/game-panel/:id/cancel',
  QUICK_PANEL_GAME_CALCULATE_BET: 'admin/game-panel/:id/calculate-poll',
  QUICK_PANEL_GAME_CALCULATE_BET_RESULT: 'admin/game-panel/calculate-poll/result',
  QUICK_PANEL_GAME_CALCULATE: 'admin/game-panel/calculate-poll/calculate',
  USER_HISTORY: 'admin/users/transactions',
  USER_TRANSACTION_BET: 'admin/user/:id/play/history',
  USER_TRANSACTION_BET_BY_ID: 'admin/user/:id/play/history/:idBet',
  QUICK_PANEL_GAME_ROUND: 'admin/game-panel/:id/calculate-poll/round',
  QUICK_PANEL_GAME_RE_CALCULATE: 'admin/game-panel/recalculate-poll',
  QUICK_PANEL_GAME_RESULT_TOTAL_BET: 'admin/game-panel/overview-income/:roundId',
  GAME_TYPE_SELECT: 'admin/setting/game/:cate/type',
  GAME_ROUND_GENERATION: 'admin/setting/game-round/generate',
  GAME_ROUND_GENERATION_BY_ID: 'admin/setting/game-round/:id',
  GAME_ROUND_GAME_LIST: 'admin/setting/game-round/game-list',
  GAME_ALL_RESULT: 'admin/game-panel/:id/all-detail',
  GAME_PRIME_RESULT: 'admin/game-panel/:id/won-detail',
  GAME_ALL_ROUND: 'admin/game-panel/:id/detail/round',
  WITHDRAW_AUTO_LIST_HEAD: 'admin/bank/bot/transfer',
  WITHDRAW_AUTO_BANK_ALL_LIST: 'admin/bank/admin/list',
  WITHDRAW_AUTO_BANK_UPDATE: 'admin/bank/bot/transfer',
  BANK_TRANFER_LIST: 'admin/bank/admin/list/alladmin',
  BANK_TRANFER_WITHDRAW: 'admin/bank/admin/transfer/admin-to-admin',
  LINE_NOTIFICATION_SETTING: 'admin/setting/line',
  BANK_TRANFER_FOR_NOTI: 'admin/bank/admin/remaining-transfer',
  USER_SET_GAME_LIMIT_ALL: 'admin/setting/game/user-limit/all'
}

export default API_PATHS