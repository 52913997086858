import * as Yup from 'yup'

export const passwordSchema = {
  password: Yup.string()
    .required('กรุณากรอกรหัสผ่าน')
}
const schema = Yup.object({
  username: Yup.string()
    .min(8)
    .required('กรุณากรอกชื่อผู้ใช้'),
  ...passwordSchema
  
});

export default schema