import api from './index'
import API_PATHS from '../constant/apiPath'
import IFilter from '../model/IFilter'
import IBlogData from '../model/BlogApi/IBlogData'
import IBlog from '../model/BlogApi/IBlog'

const blogApi = {
  blogList: async(queryFilter: IFilter): Promise<IBlogData> => {
    try {
      const response = await api.get(API_PATHS.BLOG, {params: {...queryFilter}})
      return response.data
    } catch (e) {
      throw e
    }
  },
  pinBlog: async(): Promise<IBlog[]> => {
    try {
      const response = await api.get(API_PATHS.BLOG_PIN)
      return response.data.data
    } catch (e) {
      throw e
    }
  },
  saveBlog: async(values: IBlog): Promise<void> => {
    try {
      await api.post(API_PATHS.BLOG_SAVE, values)
    } catch (e) {
      throw e
    }
  },
  getBlogById: async(id: number): Promise<IBlog> => {
    try {
      const response = await api.get(API_PATHS.BLOG_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveBlogById: async(values: IBlog): Promise<void> => {
    try {
      await api.put(API_PATHS.BLOG_SAVE, values)
    } catch (e) {
      throw e
    }
  },
  deleteBlog: async(id: number): Promise<void> => {
    try {
      await api.delete(API_PATHS.BLOG_ID.replace(':id', String(id)))
    } catch (e) {
      throw e
    }
  }
}

export default blogApi