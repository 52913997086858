import { useHistory } from 'react-router-dom'
import ToastStateIcon from '../components/ToastStateIcon'
import IToastState from '../model/toastState'
import toast from 'react-hot-toast'

const NavTop = ({
  username,
  showLogout,
  onChangeState,
  showMenuFull
}: {
  username: string
  showLogout: boolean
  onChangeState: () => void
  showMenuFull?: boolean
}) => {
  const history = useHistory()
  const logout = (): void => {
    localStorage.removeItem('accessToken')
    toastActive({state: 'success', description: 'ออกจากระบบสำเร็จ', duration: 500})
    history.push({ pathname: '/' })
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <div className="w-full h-full flex flex-col justify-end">
      <button
        onClick={(): void => { logout() }}
        className={
          showLogout
          ? 'flex items-center justify-center w-full px-2.5 py-3 transition-all h-auto duration-500 sm:bg-background-0 bg-background-2 hover:bg-background-2 group'
          : 'flex items-center justify-center w-full px-0 py-0 bg-background-4 h-0 duration-200 opacity-0'
        }>
          <span className={
            showLogout
            ? 'text-12px sm:text-text-3 text-white block group-hover:text-white'
            : 'text-12px text-text-3 hidden'
          }>ออกจากระบบ</span>
      </button>
      <div className="w-full flex sm:justify-center justify-between items-center sm:px-5 px-6 pb-2.5 pt-2 cursor-pointer" onClick={onChangeState}>
        <div className={showMenuFull ? 'flex flex-row w-full items-center sm:justify-center' : 'flex flex-row w-full items-center'}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 3.78947C4 5.87874 5.79467 7.57895 8 7.57895C10.2053 7.57895 12 5.87874 12 3.78947C12 1.70021 10.2053 0 8 0C5.79467 0 4 1.70021 4 3.78947ZM15.1111 16H16V15.1579C16 11.9082 13.208 9.26316 9.77778 9.26316H6.22222C2.79111 9.26316 0 11.9082 0 15.1579V16H15.1111Z" fill="#878787"/>
          </svg>
          <span className={showMenuFull ? 'sm:hidden block' : 'sm:block block'}>
            <span className="text-12px text-text-3 capitalize pl-1.5 pr-5">
              {username.length > 0 ? username : '-'}
            </span>
          </span>
        </div>
        <span className={showMenuFull ? 'sm:hidden block' : 'sm:block block'}>
          <svg className={showLogout ? 'transition-all rotate-0 duration-500' : 'rotate-180 duration-500'} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9761 5.85117L6.40903 0.154686C6.20694 -0.0515636 5.79328 -0.0515635 5.59276 0.154687L0.0240982 5.85117C0.0103942 5.86518 0.00218484 5.88174 0.000379831 5.899C-0.00142518 5.91626 0.00324477 5.93355 0.0138721 5.94896C0.0244994 5.96438 0.0406689 5.9773 0.0605883 5.98631C0.0805078 5.99532 0.103399 6.00006 0.126725 6L2.72727 6C2.80779 6 2.88358 5.9707 2.93095 5.92266L6.00011 2.625L9.27273 5.92266C9.32009 5.9707 9.39588 6 9.4764 6L11.8735 6C11.9761 6 12.0361 5.91328 11.9761 5.85117Z" fill="#878787"/>
          </svg>
        </span>
      </div>
    </div>
  )
}

export default NavTop