import { useState } from 'react'

interface IUseModal {
  stateModal: boolean
  onChangeStateModal: () => void
}

function useModal (): IUseModal {
  const [stateModal, setStateModal] = useState<boolean>(false)
  const onChangeStateModal = (): void => {
    setStateModal(!stateModal)
  }
  return {
    stateModal,
    onChangeStateModal
  }
}

export default useModal