import withLayout from "../../../layout/withLayout"
import WEB_TITLE from "../../../constant/webTitle"
import BeradCrump from "../../../components/BreadCrump"
import { MENU_TITLE } from "../../../constant/menuTitle"
import { FormikProps, useFormik } from "formik"
import gameSetPriceRate from "../../../validation/gameSetPriceRate"
import IToastState from "../../../model/toastState"
import toast from "react-hot-toast"
import ToastStateIcon from "../../../components/ToastStateIcon"
import { useState, useEffect } from 'react'
import gameApi from "../../../api/game.api"
import IGameSetSetting from "../../../model/GameSet/IGameSetSetting"
import Input from "../../../components/Input"

const GameSetSetting = () => {
  const [gameSetSetting, setGameSetSetting] = useState<IGameSetSetting>({
    gameCateId: 0,
    rateAmount: 0
  })
  const fetchGameSetSetting = async (): Promise<void> => {
    try {
      const response = await gameApi.getGameSetSetting()
      console.log(response, 'sdfads')
      setGameSetSetting(response)
    } catch (error) {
      toastActive({
        state: 'error',
        description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้',
        duration: 500
      })
    }
  }
  useEffect(() => {
    fetchGameSetSetting()
  },[])

  const onSubmit = async (): Promise<void> => {
    const result = {
      gameCateId: formik.values.gameCateId,
      rateAmount: Number(formik.values.rateAmount)
    }
    console.log(result, 'safsdfs')
    try {
      await gameApi.gameSetUpdateSetting(result)
      fetchGameSetSetting()
      toastActive({
        state: 'success',
        description: 'อัพข้อมูลสำเร็จ',
        duration: 500
      })
    } catch (error) {
      toastActive({
        state: 'error',
        description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้',
        duration: 500
      })
      console.log('error')
    }
  }
  const formik: FormikProps<IGameSetSetting> = useFormik<IGameSetSetting>({
    initialValues: {
      gameCateId: gameSetSetting.gameCateId,
      id: Number(gameSetSetting.id),
      rateAmount: gameSetSetting.rateAmount
    },
    enableReinitialize: true,
    validationSchema: gameSetPriceRate,
    onSubmit: onSubmit
  })
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <div>
      <BeradCrump
        headTitle={MENU_TITLE.GAME}
        showIcon={true}
        classTitle="font-katnitBold"
        title={MENU_TITLE.GAME_SET_SETTING}
      />
      <div>
        <div className="py-4">
          <Input
            name="lineLink"
            type="text"
            value={formik.values.rateAmount}
            placeholder="กรุณากรอกราคาต่อชุด"
            textLabelTopInput="ราคาต่อ 1 ชุด"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
            divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
            disabled={false}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.rateAmount && formik.errors.rateAmount}
          />
        </div>
        <button
          type="button"
          onClick={(): void => formik.handleSubmit()}
          className="px-2 py-1 rounded-lg bg-background-2 text-white hover:text-text-1">
            บันทึก
        </button>
      </div>
    </div>
  )
}

export default withLayout(GameSetSetting, WEB_TITLE.GAME, WEB_TITLE.GAME_SET)