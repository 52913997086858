import api from './index'
import API_PATHS from '../constant/apiPath'
import IWithdrawMenu from '../model/WithdrawApi/IWithdrawMenu'
import IWithdraw from '../model/WithdrawApi/IWithdraw'
import IWithdrawAllSelect from '../model/WithdrawApi/IWithdrawAllSelect'
import IWithdrawSubmit from '../model/WithdrawApi/IWithdrawSubmit'
import IWithdrawAutoHead from '../model/WithdrawApi/IWithdrawAutoHeadList';
import IWithdrawAutoBankList from '../model/WithdrawApi/IWithdrawAutoBankList';
import IWithdrawAutoBankSelect from '../model/WithdrawApi/IWithdrawAutoBankSelect';

const withdrawApi = {
  withdrawGroupMenu: async(): Promise<IWithdrawMenu[]> => {
    try {
      const response = await api.get(API_PATHS.WITHDRAW_MENU)
      return response.data
    } catch (e) {
      throw e
    }
  },
  withdraw: async(type: string): Promise<IWithdraw[]> => {
    try {
      const response = await api.get(API_PATHS.WITHDRAW_TYPE.replace(':type', type))
      return response.data
    } catch (e) {
      throw e
    }
  },
  withdrawApproveReject: async(values: IWithdrawSubmit): Promise<void> => {
    try {
      await api.post(API_PATHS.WITHDRAW, values)
    } catch (e) {
      throw e
    }
  },
  withdrawApproveAll: async(values: IWithdrawAllSelect): Promise<void> => {
    try {
      await api.post(API_PATHS.WITHDRAW_APPROVE_ALL, values)
    } catch (e) {
      throw e
    }
  },
  withdrawAutoGetHead: async(): Promise<IWithdrawAutoHead[]> => {
    try {
      const result = await api.get(API_PATHS.WITHDRAW_AUTO_LIST_HEAD)
      return result.data
    } catch (e) {
      throw e
    }
  },
  withdrawAutoGetListBank: async(): Promise<IWithdrawAutoBankList[]> => {
    try {
      const result = await api.get(API_PATHS.WITHDRAW_AUTO_BANK_ALL_LIST)
      return result.data
    } catch (e) {
      throw e
    }
  },
  withdrawAutoUpdateBank: async(values: IWithdrawAutoBankSelect): Promise<void> => {
    try {
      await api.post(API_PATHS.WITHDRAW_AUTO_BANK_UPDATE, values)
    } catch (e) {
      throw e
    }
  },
}

export default withdrawApi