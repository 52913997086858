
import withLayout from '../../layout/withLayout'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import depositApi from '../../api/deposit.api'
import Button from '../../components/Button'
import { format, utcToZonedTime } from 'date-fns-tz'
import song from '../../public/sound/soundNotification.mp3'
import ModalConfirms from '../../components/ModalConfirms'
import useModal from '../../hook/useModal'
import alertNotification from '../../constant/alertNotification'
import WEB_TITLE from '../../constant/webTitle'
import IDeposit from '../../model/DepositApi/IDeposit'
import BeradCrump from '../../components/BreadCrump'
import { MENU_TITLE } from '../../constant/menuTitle'
import IUserHistory from '../../model/UserApi/IUserHistory'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'
import WEB_PATHS from '../../constant/webPath'
import Modal from '../../components/Modal'

const Deposit = () => {
  const { stateModal, onChangeStateModal } = useModal()
  const { type }: any = useParams()
  const history = useHistory()
  const audio = new Audio(song)
  const [modal, setModal] = useState(false)
  const [modalSlip, setModalSlip] = useState('')
  const [switchAudio, setSwitchAudio] = useState<string>(localStorage.getItem(alertNotification.deposit) != null ? localStorage.getItem(alertNotification.deposit) as string : 'true')
  const [depositRemark, setDepositRemark] = useState('')
  const [depositGroup, setDepositGroup] = useState<IDeposit[]>([])
  const [dataSubmit, setDataSubmit] = useState({
    id: 0,
    state: 0
  })
  const fetchDepositData = async (value: string | number) => {
    const response = await depositApi.deposit(value)
    const checkCount = depositGroup.length < response.length ? true : false
    const data = localStorage.getItem(alertNotification.deposit)
    if (data !== null) {
      data === 'true' && !checkCount ? audio.play() : audio.pause()
    } else {
      localStorage.setItem(alertNotification.deposit, 'true')
    }
    if (response.length > depositGroup.length) {
      setDepositGroup(response)
      toastActive({state: 'success', description: 'โหลดข้อมูลสำเร็จ', duration: 500})
    } else {
      toastActive({state: 'warning', description: 'ยังไม่มีข้อมูลอัพเดท', duration: 500})
    }
  }
  const fetchDepositDataBeforSubmit = async (value: string | number) => {
    try {
      const response = await depositApi.deposit(value)
    setDepositGroup(response)
    toastActive({state: 'success', description: 'โหลดข้อมูลสำเร็จ', duration: 500})
    } catch (error) {
      toastActive({state: 'warning', description: 'ยังไม่มีข้อมูลอัพเดท', duration: 500})
    }
  }
  useEffect(() => {
    if (type === 'all') {
      const intervalSet = setInterval(() => { fetchDepositData(type) }, 30000)
      intervalSet
      localStorage.setItem('interval deposit', String(intervalSet))
      fetchDepositData(type)
      return () => clearInterval(intervalSet)
    } else {
      const intervalStorage = localStorage.getItem('interval deposit')
      intervalStorage != null ? clearInterval(Number(intervalStorage)) : ''
      intervalStorage != null ? localStorage.removeItem('interval deposit') : ''
      fetchDepositData(type)
    }
  }, [type])
  const renderTable = (childContent: IUserHistory[]): React.ReactElement => {
    if (childContent.length <= 0) return <div className="flex border-border-0 border-t-2 items-center justify-center bg-white py-1.5 px-2 w-full">ยังไม่มีข้อมูล</div>
    return (
      <div className="overflow-auto scrollbar-hide sm:w-[1560px] w-fit pb-2.5">
        <div className="flex px-2 w-full items-center scrollbar-hide overflow-auto">
          <div className="sm:w-[35px] w-[30px]">#</div>
          <div className="sm:w-[100px] w-[100px] text-left">Username</div>
          <div className="sm:w-[100px] w-[100px] text-right">จำนวนเงิน</div>
          <div className="sm:w-[150px] w-[150px] text-left px-2.5">เวลาโอน</div>
          <div className="sm:w-[220px] w-[120px] text-left">ชื่อธนาคารที่โอน</div>
          <div className="sm:w-[120px] w-[120px] text-left">เลขที่บัญชี</div>
          <div className="sm:w-[150px] w-[120px] text-left">ชื่อธนาคารที่ฝาก</div>
          <div className="sm:w-[100px] w-[120px] text-center">รูปสลิบ</div>
          <div className="sm:w-[110px] w-[120px] text-left px-2.5">สถานะ</div>
          <div className="sm:w-[200px] w-[200px] px-2.5"></div>
        </div>
        {renderContent(childContent)}
      </div>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderModalSlip = (): React.ReactElement => {
    return (
      <div id="modalConfirm" className={
        modal
          ? 'fixed z-40 inset-0 flex items-center justify-center min-h-screen'
          : 'fixed -z-30 inset-0'
      }>
        <div
          onClick={(): void => setModal(false)}
          className={
            modal
              ? 'overflow-auto scrollbar-hide inset-0 w-full h-full z-50 fixed bg-modal-0 bg-opacity-80 transition-opacity'
              : 'hidden'
          }></div>
          <div className={
          modal
            ? 'z-50 opacity-100 transition-all duration-300 w-auto container bg-background-1 rounded-lg shadow-card my-4 mx-auto'
            : 'z-0 opacity-0 duration-500 bg-background-1 rounded-lg'
        }>
          <div className="p-4">
            <div className="flex justify-between">
              <span>รูปสลิป</span>
              <svg onClick={(): void => { setModal(false), setModalSlip('') }} className="cursor-pointer hover:opacity-70" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 11.266C14 11.5802 13.89 11.8474 13.67 12.0673L12.0673 13.67C11.8474 13.89 11.5802 14 11.266 14C10.9517 14 10.6846 13.89 10.4646 13.67L7 10.2054L3.53535 13.67C3.31538 13.89 3.04826 14 2.73401 14C2.41975 14 2.15264 13.89 1.93266 13.67L0.329966 12.0673C0.109989 11.8474 0 11.5802 0 11.266C0 10.9517 0.109989 10.6846 0.329966 10.4646L3.79461 7L0.329966 3.53535C0.109989 3.31538 0 3.04826 0 2.73401C0 2.41975 0.109989 2.15264 0.329966 1.93266L1.93266 0.329966C2.15264 0.109989 2.41975 0 2.73401 0C3.04826 0 3.31538 0.109989 3.53535 0.329966L7 3.79461L10.4646 0.329966C10.6846 0.109989 10.9517 0 11.266 0C11.5802 0 11.8474 0.109989 12.0673 0.329966L13.67 1.93266C13.89 2.15264 14 2.41975 14 2.73401C14 3.04826 13.89 3.31538 13.67 3.53535L10.2054 7L13.67 10.4646C13.89 10.6846 14 10.9517 14 11.266Z" fill="#1E2339"/>
              </svg>
            </div>
          </div>
          <div className="flex justify-center p-3">
            <img className="max-h-96 max-w-max" src={modalSlip} alt="" />
          </div>
        </div>
      </div>
    )
  }
  const renderContent = (item: IUserHistory[]): React.ReactElement => {
    if (item.length <= 0) {
      return (
        <div className="flex border-border-0 border-t-2 items-center justify-center bg-white py-1.5 px-2 w-full">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div>
        {item.map((deposit, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[35px] w-[30px] text-text-6">{index +1}.</div>
              <div className="sm:w-[100px] w-[100px] overflow-hidden truncate text-16px text-text-6">
                <span onClick={(): unknown => deposit.username
                    ? history.push({pathname: WEB_PATHS.USER_EDIT.replace(':id', String(deposit.userId))})
                    : ''
                  } className="hover:cursor-pointer hover:underline hover:text-blue-600">
                  {deposit.username ? deposit.username : '-'}
                </span>
              </div>
              <div className="sm:w-[100px] w-[100px] text-16px text-text-6 text-right">{deposit.amount.toLocaleString('th')} ฿</div>
              <div className="sm:w-[150px] w-[150px] text-14px text-text-6 text-left px-2.5">{renderDateTime(deposit.transferredAt)}</div>
              <div className="sm:w-[220px] w-[120px] overflow-hidden truncate text-16px text-text-6 text-left">{deposit.bankName.length ? deposit.bankName : '-'}</div>
              <div className="sm:w-[120px] w-[120px] overflow-hidden truncate text-16px text-text-6 text-left">{deposit.bankAccount.length ? deposit.bankAccount : '-'}</div>
              <div className="sm:w-[150px] w-[120px] overflow-hidden truncate text-16px text-text-6 text-left">{deposit.bankOwnerName.length ? deposit.bankOwnerName : '-'}</div>
              {deposit.slipUrl !== '' && 
                <div onClick={(): void => {
                    setModalSlip(deposit.slipUrl)
                    setModal(true)
                  }} className="sm:w-[100px] h-[18px] hover:cursor-pointer hover:text-blue-700 w-[120px] overflow-hidden truncate text-16px text-text-6 text-center">
                    ดูรูปสลิป
                </div>
              }
              {deposit.slipUrl === '' && 
                <div className="sm:w-[100px] h-[18px] w-[120px] text-16px text-text-6 text-center">
                  -
                </div>
              }
              <div className="sm:w-[110px] w-[120px] text-16px text-text-6 text-left px-2.5">{renderStatus(deposit.status)}</div>
              <div className="sm:w-[200px] w-[200px] px-2.5">
                <div className="group flex justify-end w-full gap-2">
                  <button className="bg-button-0 hover:bg-button-3 text-16px text-white rounded-lg py-1 px-3 w-20" onClick={(): void => {
                    setDataSubmit({
                      id: deposit.id,
                      state: 1
                    })
                    setDepositRemark('')
                    onChangeStateModal()
                  }}>อนุมัติ</button>
                  <button className="bg-button-1 hover:bg-button-4 text-16px text-white rounded-lg py-1 px-3 w-20" onClick={(): void => {
                    setDataSubmit({
                      id: deposit.id,
                      state: 2
                    })
                    setDepositRemark('')
                    onChangeStateModal()
                  }}>ไม่อนุมัติ</button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const renderDepositList = (): React.ReactNode => {
    if (depositGroup.length <= 0) return <div className="w-full text-center text-16px text-text-2">ไม่มีข้อมูล</div>
    return depositGroup.map((deposit: any, index: number): React.ReactNode => {
      return (
        <div key={index}>
          <div className="pb-2.5 flex flex-col">
            <div className="w-full flex items-center pb-1">
              <label className="text-text-2 text-16px w-24">เลขบัญชี</label>
              <span className="text-text-0 max-w-max">{deposit.adminBankAccount}</span>
            </div>
            <div className="w-full flex items-center">
              <label className="text-text-2 text-16px w-24">ชื่อธนาคาร</label>
              <span className="text-text-0 max-w-max">{deposit.bankName}</span>
            </div>
          </div>
          <div className="sm:w-auto w-full overflow-scroll scrollbar-hide">
            {renderTable(deposit.userHistories)}
          </div>
        </div>
      )
    })
  }
  const approveDeposit = async () => {
    try {
      await depositApi.depositApproveReject({id: Number(dataSubmit.id), status: Number(dataSubmit.state), remark: depositRemark})
      toastActive({state: 'success', description: Number(dataSubmit.state) === 1 ? 'อนุมัติรายการโอนเงินสำเร็จ' : 'ยกเลิกรายโอนนี้สำเร็จ', duration: 500})
      setDepositRemark('')
      onChangeStateModal()
      setDataSubmit({
        id: 0,
        state: 0
      })
      fetchDepositDataBeforSubmit(type)
    }catch (e) {
      toastActive({state: 'error', description: Number(dataSubmit.state) === 1 ? 'เกิดข้อผิดพลาด ไม่สามารถอนุมัติรายการโอนเงินได้' : 'เกิดข้อผิดพลาด ไม่สามารถยกเลิกรายโอนนี้ได้', duration: 500})
    }
  }
  const renderDateTime = (date: any): string => {
    const newDate = new Date(date)
    const timeZone = 'Asia/Bangkok'
    const nyDate = utcToZonedTime(newDate, timeZone)
    return format(nyDate, 'yyy-MM-dd HH:mm')
  }
  const renderStatus = (status: number): React.ReactElement => {
    const text = status === 0 ? 'รอดำเนินการ' : 'เกิดข้อผิดพลาด'
    return (
      <div className={status === 0 ? 'text-text-0' : 'text-text-0'}>
        {text}
      </div>
    )
  }
  const addRemark = (event: string): void => {
    setDepositRemark(event)
  }
  const renderInputSwitch = (value: string) => {
    return value === 'true'
      ? <input
          type="checkbox"
          defaultChecked={Boolean(value)}
          onClick={() => chageSwitch(value as string)}
          className="toggle-active toggle-checkbox absolute block w-5 h-5 top-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer right-0.5"/>
      : <input
          type="checkbox"
          defaultChecked={Boolean(value)}
          onClick={() => chageSwitch(value as string)}
          className="toggle-checkbox absolute block w-5 h-5 top-0.5 left-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer"/>
  }
  const renderLabel = (value: string) => {
    return value === 'true'
    ? <label className="toggle-label block h-6 rounded-full bg-background-10 cursor-pointer"></label>
    : <label className="label-toggle-active toggle-label block h-6 rounded-full bg-background-9 cursor-pointer"></label>
  }
  const chageSwitch = (value: string) => {
    const state = value === 'true' ? 'false' : 'true'
    setSwitchAudio(state)
    localStorage.setItem(alertNotification.deposit, state)
  }
  const renderInputRemark = (): React.ReactElement => {
    return (
      <div>
        <input
          className="px-2 py-1 border border-gray-300 bg-white text-gray-900 focus:border-blue-500 rounded-md shadow-sm w-full h-16"
          type="text"
          name={depositRemark}
          value={depositRemark}
          placeholder="กรอกหมายเหตุ"
          onChange={(e) => addRemark(e.target.value)}
        />
        {/* { errorRemark &&
          <label className="flex items-center pt-1.5">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM6.54545 3.27273C6.54545 3.12806 6.48799 2.98933 6.38569 2.88703C6.2834 2.78474 6.14466 2.72727 6 2.72727C5.85534 2.72727 5.7166 2.78474 5.61431 2.88703C5.51201 2.98933 5.45455 3.12806 5.45455 3.27273V6.54545C5.45455 6.69012 5.51201 6.82886 5.61431 6.93115C5.7166 7.03344 5.85534 7.09091 6 7.09091C6.14466 7.09091 6.2834 7.03344 6.38569 6.93115C6.48799 6.82886 6.54545 6.69012 6.54545 6.54545V3.27273ZM6.54545 8.45455C6.54545 8.30988 6.48799 8.17114 6.38569 8.06885C6.2834 7.96656 6.14466 7.90909 6 7.90909C5.85534 7.90909 5.7166 7.96656 5.61431 8.06885C5.51201 8.17114 5.45455 8.30988 5.45455 8.45455V8.72727C5.45455 8.87194 5.51201 9.01068 5.61431 9.11297C5.7166 9.21526 5.85534 9.27273 6 9.27273C6.14466 9.27273 6.2834 9.21526 6.38569 9.11297C6.48799 9.01068 6.54545 8.87194 6.54545 8.72727V8.45455Z" fill="#DC1B1B"/>
            </svg>
            <p className="text-red-500 text-xs text-left pl-1">กรุณากรอกหมายเหตุ</p>
          </label>
        } */}
      </div>
    )
  }
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col justify-between sm:pb-6 pb-2">
        <BeradCrump headTitle={MENU_TITLE.DEPOSIT} showIcon={true} classTitle="font-katnitBold" title={type === 'all' ? 'รวมทุกธนาคาร' : ''} />
        <div
          className="sm:text-right sm:flex flex justify-between items-center sm:pt-0 pt-7">
          <div>
            <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
              {renderInputSwitch(switchAudio as string)}
              {renderLabel(switchAudio as string)}
            </div>
            <label className="sm:pr-3 sm:pl-5 pl-3">เสียงเตือน</label>
          </div>
          <Button
            type="button"
            buttonClassName="bg-background-10 hover:bg-background-11 text-white text-16px sm:w-auto max-w-max px-3 py-2 rounded-lg"
            buttonText="ดึงข้อมูล"
            onClick={() => fetchDepositData(type as string | number)}
          />
        </div>
      </div>
      {type >= 0 &&
      <div className="w-full text-right">
      <Button
          type="button"
          buttonClassName="bg-background-10 hover:bg-background-11 text-white text-16px sm:w-auto max-w-max px-3 py-2 rounded-lg"
          buttonText="ประวัติเงินเข้าบัญชี"
          onClick={(): void => history.push({
            pathname: WEB_PATHS.DEPOSIT_TEMP,
            search: `?type=0&groupbank=${String(type)}`
          })}
        />
      </div>
      }
      {renderDepositList()}
      {modal &&
        <Modal element={renderModalSlip()} />
      }
      <ModalConfirms
        showModalConfirm={stateModal}
        titleHeader="คำเตือน"
        detailHeader={ Number(dataSubmit.state) === 1 ? 'คุณต้องการอนุมัติรายการโอน ใช่หรือไม่ ?' : 'คุณต้องการยกเลิกรายการโอน ใช่หรือไม่ ?' }
        btnButtonConfirm={approveDeposit}
        showRemark={true}
        btnButtonCancel={onChangeStateModal}
        customElement={renderInputRemark()}
        textButtonConfirm="ตกลง"
        textButtonCancel="ยกเลิก"
      />
    </div>
  )
}

export default withLayout(Deposit, WEB_TITLE.DEPOSIT)