import withLayout from '../../../layout/withLayout'
import { useHistory, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import userApi from '../../../api/user.api'
import IUserTransactionBetGroup from '../../../model/UserApi/IUserTransactionBetGroup'
import WEB_PATHS from '../../../constant/webPath'
import IUserTransactionBetList from '../../../model/UserApi/IUserTransactionBetList'
import { dateFormat } from '../../../utils/dateFormat'
import { MENU_TITLE } from '../../../constant/menuTitle'
import toast from 'react-hot-toast'
import ToastStateIcon from '../../../components/ToastStateIcon'
import IToastState from '../../../model/toastState'

const TransactionBetGroup = (): React.ReactElement => {
  const { id, idBet }: any = useParams()
  const history = useHistory()
  const backPage = (): void => {
    history.push(WEB_PATHS.USER_EDIT.replace(':id', String(id)))
  }
  const [transactionBetGroup, setTransactionBetGroup] = useState<IUserTransactionBetGroup>({
    awaitingCnt: 0,
    awaitingTotal: 0,
    cancelCnt: 0,
    cancelTotal: 0,
    flag: '',
    gameCateId: 0,
    gameCateName: '',
    gameGroupId: 0,
    groupList: [],
    headTransactionId: 0,
    isActive: true,
    loseCnt: 0,
    loseTotal: 0,
    pollNo: '',
    roundDate: '',
    roundString: '',
    status: 0,
    statusString: '',
    transactionDate: '',
    wonCnt: 0,
    wonTotal: 0
  })
  useEffect(() => {
    const fetchTransactionBetGroup = async () => {
      try {
        const data = await userApi.userTransactionGroup(String(id), idBet)
        setTransactionBetGroup(data)
      } catch (e) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    }
    fetchTransactionBetGroup()
  }, [])
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-[1000px] pb-2.5">
        <div className="flex px-2 items-center overflow-auto scrollbar-hide w-full">
          <div className="sm:w-[5%] w-[30px]">#</div>
          <div className="sm:w-[20%] w-[150px] text-left">ประเภทเกม</div>
          <div className="sm:w-[20%] w-[220px] text-left">เลขที่เล่น</div>
          <div className="sm:w-[20%] w-[200px] text-right">จำนวนเงินทั้งหมด</div>
          <div className="sm:w-[20%] w-[150px] text-right">ราคาสุทธิ</div>
          <div className="sm:w-[15%] w-[150px] text-left px-2.5">สถานะ</div>
        </div>
        {renderContent(transactionBetGroup.groupList)}
      </div>
    )
  }
  const renderContent = (item: IUserTransactionBetList[]): React.ReactNode => {
    if (item.length <= 0) return <div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2">ไม่มีข้อมูล</div>
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={index}>
        <div className="sm:w-[5%] w-[30px] text-text-6">{index+1}</div>
        <div className="sm:w-[20%] w-[150px] overflow-hidden truncate text-16px text-text-6">{content.gameTypeName}</div>
        <div className="sm:w-[20%] w-[220px] overflow-hidden truncate text-16px text-text-6">{content.betNumber}</div>
        <div className="sm:w-[20%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-right">{content.betPrice.toLocaleString('th')}</div>
        <div className="sm:w-[20%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-right">{content.resultPrice.toLocaleString('th')}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 px-2.5">{content.statusString}</div>
      </div>
      )
    })
  }
  return (
    <div>
      <div className="flex items-center w-fit cursor-pointer" onClick={ () => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.USER_GAME_PLAY_HISTORY}</span>
      </div>
      <div className="flex flex-1 sm:w-1/4 w-full sm:flex-col flex-col gap-3 pt-3 pb-5">
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">หมายเลขรายการ</span>
          <span className="text-text-0">#{transactionBetGroup.pollNo}</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">ประเภทเกม</span>
          <span className="text-text-0">{transactionBetGroup.gameCateName} / {dateFormat(transactionBetGroup.roundDate, 'dd-MM-yyyy')}</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">สถานะรอ / จำนวนเงิน</span>
          <span className="text-text-0">{transactionBetGroup.awaitingCnt.toLocaleString('th')} รายการ / {transactionBetGroup.awaitingTotal.toLocaleString('th')} THB</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">สถานะยกเลิก / จำนวนเงิน</span>
          <span className="text-text-0">{transactionBetGroup.cancelCnt.toLocaleString('th')} รายการ / {transactionBetGroup.cancelTotal.toLocaleString('th')} THB</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">สถานะชนะ / จำนวนเงิน</span>
          <span className="text-text-0">{transactionBetGroup.wonCnt.toLocaleString('th')} รายการ / {transactionBetGroup.wonTotal.toLocaleString('th')} THB</span>
        </div>
      </div>
      <div className="sm:w-full w-full min-w-full scrollbar-hide overflow-x-scroll">
        {renderTable()}
      </div>
    </div>
  )
}

export default withLayout(TransactionBetGroup, 'User')