import classnames from 'classnames'

const InputSelectOption = ({
  name,
  value,
  options,
  inputClassName,
  textLabelTopSelect,
  labelOptionText,
  showTextLabelTopInput,
  classNameLabel,
  divClassName,
  onChange,onBlur,error
}: {
  name: string
  value: string
  options: any[]
  onBlur: (e: any) => void
  onChange: (e: any) => void
  error?: any;
  inputClassName?: string
  labelOptionText: string
  showTextLabelTopInput: boolean
  classNameLabel: string
  divClassName: string
  textLabelTopSelect?: string
}): React.ReactElement => {
  return (
    <div className={classnames(divClassName)}>
      <label className={classnames(showTextLabelTopInput ? 'text-black' : 'hidden', classNameLabel)}>{textLabelTopSelect}</label>
      <select
        className={inputClassName +
          ' bg-white rounded text-sm shadow border border-gray-300 placeholder-gray-500 text-gray-900 bg-no-repeat bg-right w-full'}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        aria-label="select"
      >
        <option value="">{labelOptionText}</option>
        {options.map((o, index) => {
          return (
            <option key={index} value={o.id}>
              {o.title}
            </option>
          )
        })}
      </select>
      {error ? <p className="text-red-500 text-xs pt-0.5">{error}</p> : null}
    </div>
  )
}
export default InputSelectOption;