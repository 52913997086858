import classnames from 'classnames'
import React from 'react'

const Button = ({
  type,
  buttonClassName,
  buttonText,
  onClick,
}:  {
  type: 'button' | 'submit',
  buttonClassName: string,
  buttonText: string,
  onClick?: (e: any) => void
}): React.ReactElement => {
  return (
    <button
      type={type}
      className={classnames(
        'border border-transparent shadow-sm rounded-md',
        buttonClassName
      )}
      onClick={onClick}>
      {buttonText}
    </button>
  )
}

export default Button
