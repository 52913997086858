import classNames from 'classnames'
import React from 'react'
import usePagination from '../hook/usePagination'
import IFilter from '../model/IFilter'

const Pagination = ({
  filter,
  count,
  divClassName
}: {
  filter: IFilter
  count: number
  divClassName?: string
}): React.ReactElement => {
  const { pageMax, pageNumber, pageSelect, nextPage, backPage, jumpToPage } = usePagination(filter, count)
  const renderPagination = (): React.ReactNode => {
    if (count <= 0 ) return
    return pageNumber.map((pageNumber: number, index: number) => {
      console.log(pageNumber, 'saf')
      return (
        <div
          key={index}
          className={pageNumber  === pageSelect ? 'text-16px text-text-2 pr-2.5' : 'text-16px text-text-0 hover:text-text-2 pr-2.5 cursor-pointer'}
          onClick={(): void => jumpToPage(pageNumber)}
        >
          {pageNumber}
        </div>
      )
    })
  }
  const renderPageNext = (): React.ReactElement => {
    return (
      <div
        className={pageSelect != pageMax ? 'cursor-pointer' : 'hidden'}
        onClick={(): void => nextPage()}
      >
        {pageMax > 1 &&
          <div className="text-14px text-text-0 hover:text-text-2">Next</div>
        }
      </div>
    )
  }
  const renderPageBack = (): React.ReactElement => {
    return (
      <div
        className={pageSelect != 1 && pageMax > 1 ? 'cursor-pointer' : 'hidden'}
        onClick={(): void => backPage()}
      >
        <div className="text-14px pr-2.5 text-text-0 hover:text-text-2">Previous</div>
      </div>
    )
  }
  return (
    <div className={classNames('flex flex-row items-center', divClassName)}>
      {renderPageBack()}
      {renderPagination()}
      {renderPageNext()}
    </div>
  )
}

export default Pagination