import WEB_TITLE from "../../../constant/webTitle"
import withLayout from "../../../layout/withLayout"
import { useParams, useHistory } from "react-router-dom"
import WEB_PATHS from "../../../constant/webPath"
import gameApi from "../../../api/game.api"
import IGameRound from "../../../model/GamePanelApi/IGameRound"
import { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from 'yup'
import Input from "../../../components/Input"
import Button from "../../../components/Button"
import { zonedTimeToUtc } from 'date-fns-tz'
import { formatISO } from 'date-fns'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import { dateFormat } from "../../../utils/dateFormat"

const GameRoundEdit = (): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const titleHeader = 'แก้ไขข้อมูลเกมส์ Round'
  const [gameRound, setGameRound] = useState<IGameRound>({
    id: 0,
    gameRoleId: 0,
    gameCateId: 0,
    dateType: 0,
    startDate: '',
    endDate: '',
    isActive: false,
    openDate: '',
    closeDate: '',
    createdAt: '',
    updatedAt: '',
  })
  const backPage = () => {
    history.push({
      pathname: WEB_PATHS.GAME_ROUND
    })
  }
  const fetchGameRoundById = async (id: string): Promise<void> => {
    const response = await gameApi.getGameRoundById(id)
    setGameRound(response)
  }
  useEffect(() => {
    fetchGameRoundById(String(id))
  }, [])
  const schema = Yup.object().shape({
    // startDate: Yup.date().default(() => new Date()).required('กรุณาเลือกวันที่เริ่ม'),
    // endDate: Yup.date()
    //   .when('startDate', (startDate, schema) => startDate && schema.min(startDate, 'วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น')),
    openDate: Yup.date().default(() => new Date()).required('กรุณาเลือกวันที่เปิด'),
    closeDate: Yup.date().when('openDate', (openDate, schema) => openDate && schema.min(openDate, 'วันที่ปิดต้องมากกว่าวันที่เปิด'))
  })
  const renderSwitch = (props: any) => {
    return (
      <div
        onClick={(): void => {
          props.setFieldValue('isActive', !props.values.isActive)
        }}
        className="relative sm:w-[50px] w-[50px]">
        <input
          type="checkbox"
          name="isBotActive"
          defaultChecked={false}
          value={props.values.isActive}
          className={ props.values.isActive
            ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
            : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
          }
        />
        <label className={ props.values.isActive
          ? 'toggle-label block h-6 rounded-full bg-background-10 cursor-pointer'
          : 'toggle-label block h-6 rounded-full bg-background-9 cursor-pointer'
          }>
        </label>
      </div>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const genDateTimeFormat = (date: Date | string): any => {
    if (date) {
      return formatISO(new Date(date)).toLocaleString().replace(':00+07:00', '')
    }
  }
  return (
    <div>
      <div className="text-2xl max-w-max flex items-center cursor-pointer hover:opacity-50" onClick={ () => backPage()}>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px">{titleHeader}</span>
      </div>
      <div className="w-full overflow-scroll scrollbar-hide">
        <Formik
            initialValues={{
              id: gameRound.id,
              gameRoleId: gameRound.gameRoleId,
              gameCateId: gameRound.gameCateId,
              dateType: gameRound.dateType,
              startDate: gameRound.startDate,
              endDate: gameRound.endDate,
              isActive: gameRound.isActive,
              openDate: gameRound.openDate,
              closeDate: gameRound.closeDate,
              createdAt: gameRound.createdAt,
              updatedAt: gameRound.updatedAt
            }}
            enableReinitialize
            validationSchema={schema}
            onSubmit={async (values) => {
              const data = {
                startDate: zonedTimeToUtc(new Date(values.startDate), 'Asia/Bangkok').toUTCString(),
                endDate: zonedTimeToUtc(new Date(values.endDate), 'Asia/Bangkok').toUTCString(),
                openDate: zonedTimeToUtc(new Date(values.openDate), 'Asia/Bangkok').toUTCString(),
                closeDate: zonedTimeToUtc(new Date(values.closeDate), 'Asia/Bangkok').toUTCString(),
                isActive: values.isActive
              }
              try {
                await gameApi.updateGameRoundById(id, data)
                toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
                history.push(WEB_PATHS.GAME_ROUND)                
              } catch (e) {
                toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
              }
            }}>
              { props => (
                <form className="sm:w-[1200px] w-[1200px] overflow-scroll scrollbar-hide" onSubmit={props.handleSubmit}>
                  <div className="flex px-2 py-3 w-full items-center border-b border-t-0 border-r-0 border-l-0 border border-b-border-0">
                    {/* <div className="sm:w-[20%] w-[230px] sm:min-w-max text-16px text-text-2">วันที่เริ่ม</div> */}
                    {/* <div className="sm:w-[20%] w-[240px] sm:min-w-max text-16px text-text-2">วันที่สิ้นสุด</div> */}
                    <div className="sm:w-[20%] w-[240px] sm:min-w-max text-16px text-text-2">วันที่เปิดแทง</div>
                    <div className="sm:w-[20%] w-[230px] sm:min-w-max text-16px text-text-2">วันที่ปิดแทง</div>
                    <div className="sm:w-[50px] w-[100px] sm:min-w-max text-16px text-text-2">เปิด-ปิด</div>
                  </div>
                  <div>
                    <div className="flex flex-col w-full items-center pb-3">
                      <div className="w-full flex items-center">
                        {/* <Input
                          name="startDate"
                          type="datetime-local"
                          value={genDateTimeFormat(props.values.startDate)}
                          placeholder=""
                          textLabelTopInput="วันที่ เริ่ม"
                          showTextLabelTopInput={false}
                          classNameLabel="text-gray-500 block"
                          inputClassName="mt-2 text-right rounded-lg"
                          divClassName="flex flex-col pr-2 sm:w-[20%] w-[20%] h-[66px] sm:min-w-max text-16px text-text-2"
                          disabled={false}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          error={
                            props.errors &&
                            props.errors.startDate &&
                            props.touched &&
                            props.touched.startDate && props.errors.startDate
                          }
                        />
                        <Input
                          name="endDate"
                          type="datetime-local"
                          value={genDateTimeFormat(props.values.endDate)}
                          placeholder=""
                          textLabelTopInput="วันที่ สิ้นสุด"
                          showTextLabelTopInput={false}
                          classNameLabel="text-gray-500 block"
                          inputClassName="mt-2 text-right rounded-lg"
                          divClassName="flex flex-col pr-2 sm:w-[20%] w-[20%] h-[66px] sm:min-w-max text-16px text-text-2"
                          disabled={false}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          error={
                            props.errors &&
                            props.errors.endDate &&
                            props.touched &&
                            props.touched.endDate && props.errors.endDate
                          }
                        /> */}
                        <Input
                          name="openDate"
                          type="datetime-local"
                          value={genDateTimeFormat(props.values.openDate)}
                          placeholder=""
                          textLabelTopInput="วันที่ เปิดแทง"
                          showTextLabelTopInput={false}
                          classNameLabel="text-gray-500 block"
                          inputClassName="mt-2 text-right rounded-lg"
                          divClassName="flex flex-col pr-2 sm:w-[20%] w-[20%] h-[66px] sm:min-w-max text-16px text-text-2"
                          disabled={false}
                          minDate={`${dateFormat(new Date(), 'yyyy-MM-dd')}T00:00`}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          error={
                            props.errors &&
                            props.errors.openDate &&
                            props.touched &&
                            props.touched.openDate && props.errors.openDate
                          }
                        />
                        <Input
                          name="closeDate"
                          type="datetime-local"
                          value={genDateTimeFormat(props.values.closeDate)}
                          placeholder=""
                          textLabelTopInput="วันที่ ปิดรับแทง"
                          showTextLabelTopInput={false}
                          classNameLabel="text-gray-500 block"
                          inputClassName="mt-2 text-right rounded-lg"
                          divClassName="flex flex-col pr-2 sm:w-[20%] w-[20%] h-[66px] sm:min-w-max text-16px text-text-2"
                          disabled={false}
                          minDate={`${dateFormat(new Date(), 'yyyy-MM-dd')}T00:00`}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          error={
                            props.errors &&
                            props.errors.closeDate &&
                            props.touched &&
                            props.touched.closeDate && props.errors.closeDate
                          }
                        />
                        {renderSwitch(props)}
                      </div>
                    </div>
                  </div>
                  <Button
                    buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
                    buttonText="บันทึก"
                    type="submit"
                  />
                </form>
              )
            }
        </Formik>
      </div>
    </div>
  )
}

export default withLayout(GameRoundEdit, WEB_TITLE.GAME, 'GAME ROUND')