import * as Yup from 'yup'

const userSubAmountSchema = Yup.object({
  amount: Yup.string()
    .required('Required')
    .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
    .test('bankId', 
    'จำนวนเงินต้องมากกว่า 0',
    function(amount) { 
      return Number(amount) > 0 ? true : false
   }),
  remark: Yup.string()
    .required('Required'),
});

export default userSubAmountSchema