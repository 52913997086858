import PubSub from 'pubsub-js'

interface usePubSub {
  subscribeForError: () => void
}

function usePubSub (): usePubSub {
  const subscribeForError = (): void => {
    PubSub.subscribe('REDIRECT_TO_LOGIN', (async (): Promise<void> => {
      localStorage.removeItem('accessToken')
    }))
  }
  return {
    subscribeForError
  }
}

export default usePubSub