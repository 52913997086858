import renderRoute from './route/config'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import usePubSub from './hook/usePubsub'
import { useEffect } from 'react'

function App(): React.ReactElement {
  const { subscribeForError } = usePubSub()
  useEffect(() => {
    subscribeForError()
  }, [])
  return (
    <Router>
      <Switch>
        {renderRoute()}
      </Switch>
    </Router>
  )
}

export default App;