import api from './index'
import API_PATHS from '../constant/apiPath'
import IDepositMenu from '../model/DepositApi/IDepositMenu'
import IDeposit from '../model/DepositApi/IDeposit'
import IFilterBankTemp from '../model/IFilterBankTemp'
import IDepositBankTemp from '../model/DepositApi/IDepositBankTemp'

const depositApi = {
  depositGroupMenu: async(): Promise<IDepositMenu[]> => {
    try {
      const response = await api.get(API_PATHS.DEPOSIT_MENU)
      return response.data
    } catch (e) {
      throw e
    }
  },
  deposit: async(id: string | number): Promise<IDeposit[]> => {
    try {
      const response = id === 'all'
        ? await api.get(API_PATHS.DEPOSIT_LIST)
        : await api.get(API_PATHS.DEPOSIT_LIST_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  depositApproveReject: async(values: any): Promise<void> => {
    try {
      await api.post(API_PATHS.DEPOSIT_LIST, values)
    } catch (e) {
      throw e
    }
  },
  depositTempHistory: async(query: IFilterBankTemp): Promise<IDepositBankTemp> => {
    try {
      const response = await api.get(API_PATHS.DEPOSIT_TEMP_HISTORY, {params: {...query}})
      return response.data
    } catch (e) {
      throw e
    }
  }
}

export default depositApi