import * as Yup from 'yup'

const schema = Yup.object({
  bankId: Yup.string()
    .required('required'),
  bankAccount: Yup.string()
    .min(10, 'หมายเลขธนาคารต้องมีอย่างน้อย 10 ตัวอักษร')
    .max(20, 'หมายเลขธนาคารต้องไม่เกิน 20 ตัวอักษร')
    .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
    .required('required'),
  groupBankId: Yup.string()
    .required('required'),
  bankType: Yup.string()
    .required('required'),
  pinCode: Yup.string()
    .required('required'),
  userLogin: Yup.string()
    .required('required'),
  password: Yup.string()
    .required('required'),
  bankOwnerName: Yup.string()
    .required('required'),
  selecttextLogin: Yup.string()
    .required('required'),
  deviceId: Yup.string()
    .required('required'),
  apiRefresh: Yup.string()
    .required('required'),
  apiAccountNumber: Yup.string()
    .required('required'),
  accessToken: Yup.string()
    .required('required')
});

export default schema