import withLayout from '../../../layout/withLayout'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import React from 'react'
import { Formik } from 'formik'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import schema from '../../../validation/textRunSchema'
import textRunApi from '../../../api/textRun.api'
import WEB_TITLE from '../../../constant/webTitle'
import WEB_PATHS from '../../../constant/webPath'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BreadCrump from '../../../components/BreadCrump'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IHomePageSetting from '../../../model/SettingsApi/IHomePageSetting'

const HomePageSetting = () => {
  const history = useHistory()
  const [runningText, setRunningText] = useState<IHomePageSetting>({
    id: 0,
    lineLink: '',
    runningText: ''
  })
  useEffect(() => {
    (async function fetchData(): Promise<void> {
      try {
        const response = await textRunApi.textRun()
        setRunningText(response)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <Formik
      initialValues={{
        id: runningText.id,
        runningText: runningText.runningText,
        lineLink: runningText.lineLink
      }}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values): Promise<void> => {
        try {
          await textRunApi.saveTextRun(values)
          toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
          history.push({pathname: WEB_PATHS.SETTING_HOMEPAGE })
        } catch (e) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
        }
      }}
    >
    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }): React.ReactElement => (
      <form onSubmit={handleSubmit}>
        <BreadCrump headTitle="ระบบ" showIcon={true} title={MENU_TITLE.HOME_TEXT_RUN} />
        <div className="pt-6">
          <div className="flex flex-col">
            <div>
              <Input
                name="runningText"
                type="text"
                value={values.runningText}
                placeholder="กรุณากรอกข้อความวิ่งหน้าโฮม"
                textLabelTopInput="ข้อความวิ่งหน้าโฮมเพจ"
                showTextLabelTopInput={true}
                classNameLabel="text-gray-500 mb-2"
                inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
                divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
                disabled={false}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.runningText && errors.runningText}
              />
            </div>
            <div>
              <Input
                name="lineLink"
                type="text"
                value={values.lineLink}
                placeholder="ไอดีไลน์ Ads"
                textLabelTopInput="ไอดีไลน์ Ads"
                showTextLabelTopInput={true}
                classNameLabel="text-gray-500 mb-2"
                inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
                divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
                disabled={false}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.lineLink && errors.lineLink}
              />
            </div>
            <div>
              <Button
                type="submit"
                buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-3 text-16px"
                buttonText="บันทึก"
              />
            </div>
          </div>
        </div>
      </form>
    )}
    </Formik>
  )
}

export default withLayout(HomePageSetting, WEB_TITLE.SETTINGS)