import withLayout from '../../../layout/withLayout'
import WEB_TITLE from '../../../constant/webTitle'
import IGameRoundSelect from '../../../model/IGameRoundSelect'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import WEB_PATHS from '../../../constant/webPath'
import gameApi from '../../../api/game.api'
import IGameRound from '../../../model/GamePanelApi/IGameRound'
import { Formik } from 'formik'
import Input from '../../../components/Input'
import * as Yup from 'yup'
import Button from '../../../components/Button'
import { zonedTimeToUtc } from 'date-fns-tz'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'

const GameRoundCreate = (): React.ReactElement => {
  const history = useHistory()
  const titleHeader = 'สร้างงวดของเกมทั้งปี'
  const [gameRoundGen, setGameRoundGen] = useState<IGameRound[]>([])
  const [yearSelect, setYearSelect] = useState<string>('')
  const [gameRoundSelect, setGameRoundSelect] = useState<number>(0)
  const [gameRoundSelectList, setGameRoundSelectList] = useState<IGameRoundSelect[]>([])
  const fetchGameRoundGeneration = async (): Promise<void> => {
    try {
      const response = await gameApi.getGameRoundGen(String(gameRoundSelect), String(yearSelect))
      setGameRoundGen(response)
    } catch (e: any) {
      if (e.errorCode === '0201003') {
        toastActive({state: 'warning', description: 'เกิดข้อผิดพลาด ข้อมูลนี้ถูกสร้างไปแล้ว', duration: 500})
        setYearSelect('')
      }
      setGameRoundGen([])
    }
  }
  useEffect(() => {
    fetchGameRoundSelectList()
  }, [])
  useEffect(() => {
    if (gameRoundSelect > 0 && yearSelect !== '') {
      fetchGameRoundGeneration()
    } else {
      setGameRoundGen([])
    }
  }, [gameRoundSelect, yearSelect])
  const fetchGameRoundSelectList = async (): Promise<void> => {
    const response = await gameApi.getGameRoundSelect()
    setGameRoundSelectList(response)
  }
  const backPage = () => {
    history.push({
      pathname: WEB_PATHS.GAME_ROUND
    })
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderSelectYear = (): React.ReactNode => {
    return (
      <select
        name="typeSelect"
        className="rounded-lg border border-border-0 bg-white text-text-2 py-1.5 px-3 sm:ml-3 mb-3 sm:mb-0 sm:w-[20%] w-full"
        onChange={(e: any): void => setYearSelect(e.target.value)}
        value={yearSelect}
      >
        <option value="">กรุณาเลือกปี</option>
          <option
            value={String(new Date().getFullYear())}
          >
            {new Date().getFullYear()}
          </option>
          <option
            value={String(new Date().getFullYear()+1)}
          >
            {new Date().getFullYear()+1}
          </option>
          <option
            value={String(new Date().getFullYear()+2)}
          >
            {new Date().getFullYear()+2}
          </option>
      </select>
    )
  }
  const renderSelect = (): React.ReactNode => {
    if (gameRoundSelectList.length < 1) return
    return (
      <select
        name="typeYear"
        className="rounded-lg border border-border-0 bg-white text-text-2 py-1.5 px-3 sm:mb-0 mb-3 sm:w-[40%] w-full"
        onChange={(e: any): void => setGameRoundSelect(e.target.value)}
        value={gameRoundSelect}
      >
        <option value="">กรุณาเลือกประเภทหวย</option>
        {gameRoundSelectList.map((type, index) => {
          return (
          <option
            key={index} value={type.gameCateId}
          >
            {type.name}
          </option>)
        })}
      </select>
    )
  }
  const renderSwitch = (round: any, props: any, index: number) => {
    return (
      <div
        onClick={(): void => {
          props.setFieldValue(`gameRoundGen.${index}.isActive`, !props.values.gameRoundGen[index].isActive)
        }}
        className="relative sm:w-[50px] w-[50px]">
        <input
          type="checkbox"
          name="isBotActive"
          defaultChecked={false}
          value={round.isActive}
          className={ round.isActive
            ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
            : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
          }
        />
        <label className={ round.isActive
          ? 'toggle-label block h-6 rounded-full bg-background-10 cursor-pointer'
          : 'toggle-label block h-6 rounded-full bg-background-9 cursor-pointer'
          }>
        </label>
      </div>
    )
  }
  const renderGameRoundSet = (props: any): React.ReactNode => {
    if (props.values.length <= 0) return 
    return props.values.gameRoundGen.map((round: IGameRound, index: number) => {
      const genDateTimeFormat = (date: Date | string):  any => {
        if (date) {
          return String(date).replace(':00Z', '')
        }
      }
      return (
        <div className="flex w-full items-center px-2" key={index}>
          <div className="sm:w-[3%] w-[3%] sm:min-w-max">{index+1}.</div>
          {/* <Input
            name={`gameRoundGen.${index}.startDate`}
            type="datetime-local"
            value={genDateTimeFormat(round.startDate)}
            placeholder=""
            textLabelTopInput="วันที่ เริ่ม"
            showTextLabelTopInput={false}
            classNameLabel="text-text-2 block"
            inputClassName="mt-2 text-right rounded-lg"
            divClassName="flex sm:w-[20%] w-[20%] sm:min-w-max text-16px text-text-2"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.gameRoundGen &&
              props.errors.gameRoundGen[index] &&
              props.errors.gameRoundGen[index].startDate &&
              props.touched &&
              props.touched.gameRoundGen &&
              props.touched.gameRoundGen[index] &&
              props.touched.gameRoundGen[index].startDate && props.errors.gameRoundGen[index].startDate
            }
          />
          <Input
            name={`gameRoundGen.${index}.endDate`}
            type="datetime-local"
            value={genDateTimeFormat(round.endDate)}
            placeholder=""
            textLabelTopInput="วันที่ สิ้นสุด"
            showTextLabelTopInput={false}
            classNameLabel="text-text-2 block"
            inputClassName="mt-2 text-right rounded-lg"
            divClassName="flex sm:w-[20%] w-[20%] sm:min-w-max text-16px text-text-2"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.gameRoundGen &&
              props.errors.gameRoundGen[index] &&
              props.errors.gameRoundGen[index].endDate &&
              props.touched &&
              props.touched.gameRoundGen &&
              props.touched.gameRoundGen[index] &&
              props.touched.gameRoundGen[index].endDate && props.errors.gameRoundGen[index].endDate
            }
          /> */}
          <Input
            name={`gameRoundGen.${index}.openDate`}
            type="datetime-local"
            value={genDateTimeFormat(round.openDate)}
            placeholder=""
            textLabelTopInput="วันที่ เปิดแทง"
            showTextLabelTopInput={false}
            classNameLabel="text-text-2 block"
            inputClassName="mt-2 text-right rounded-lg"
            divClassName="flex sm:w-[20%] w-[20%] sm:min-w-max text-16px text-text-2"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.gameRoundGen &&
              props.errors.gameRoundGen[index] &&
              props.errors.gameRoundGen[index].openDate &&
              props.touched &&
              props.touched.gameRoundGen &&
              props.touched.gameRoundGen[index] &&
              props.touched.gameRoundGen[index].openDate && props.errors.gameRoundGen[index].openDate
            }
          />
          <Input
            name={`gameRoundGen.${index}.closeDate`}
            type="datetime-local"
            value={genDateTimeFormat(round.closeDate)}
            placeholder=""
            textLabelTopInput="วันที่ ปิดรับแทง"
            showTextLabelTopInput={false}
            classNameLabel="text-text-2 block rounded-lg"
            inputClassName="mt-2 text-right rounded-lg"
            divClassName="flex sm:w-[20%] w-[20%] sm:min-w-max text-16px text-text-2"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.gameRoundGen &&
              props.errors.gameRoundGen[index] &&
              props.errors.gameRoundGen[index].closeDate &&
              props.touched &&
              props.touched.gameRoundGen &&
              props.touched.gameRoundGen[index] &&
              props.touched.gameRoundGen[index].closeDate && props.errors.gameRoundGen[index].closeDate
            }
          />
        {renderSwitch(round, props, index)}
      </div>
      )
    })
  }
  const schema = Yup.object().shape({
    gameRoundGen: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().default(() => new Date()).required('กรุณาเลือกวันที่เริ่ม'),
        endDate: Yup
        .date()
        .when(
            "startDate",
            (startDate, schema) => startDate && schema.min(startDate,
            "วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น")),
        openDate: Yup.date().default(() => new Date()).required('กรุณาเลือกวันที่เปิด'),
        closeDate: Yup
        .date()
        .when(
            "openDate",
            (openDate, schema) => openDate && schema.min(openDate,
            "วันที่ปิดต้องมากกว่าวันที่เปิด"))
      })
    )
  })
  const setDateTimeFormat = (item: IGameRound[]): any => {
    return item.map((item: IGameRound): any => {
      return {...item, 
        id: item.id,
        gameRoleId: item.gameRoleId,
        gameCateId: item.gameCateId,
        dateType: item.dateType,
        startDate: zonedTimeToUtc(new Date(item.startDate), 'Asia/Bangkok').toUTCString(),
        endDate: zonedTimeToUtc(new Date(item.endDate), 'Asia/Bangkok').toUTCString(),
        isActive: item.isActive,
        openDate: zonedTimeToUtc(new Date(item.openDate), 'Asia/Bangkok').toUTCString(),
        closeDate: zonedTimeToUtc(new Date(item.closeDate), 'Asia/Bangkok').toUTCString(),
        createdAt: item.createdAt,
        updatedAt: item.updatedAt
      }
    })
  }
  return (
    <div>
      <div className="text-2xl max-w-max flex items-center cursor-pointer hover:opacity-50" onClick={ () => backPage()}>
          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
          </svg>
        <span className="ml-3 text-20px">{titleHeader}</span>
      </div>
      <div className="sm:w-[60%] w-full sm:block flex flex-col items-center pt-6 pb-4">
        {renderSelect()}
        {renderSelectYear()}
      </div>
      <div className="w-full overflow-scroll scrollbar-hide">
      <Formik
          initialValues={{
            gameRoundGen: gameRoundGen
          }}
          enableReinitialize
          validationSchema={schema}
          onSubmit={async (values) => {
            const data = setDateTimeFormat(values.gameRoundGen)
            try {
              await gameApi.postGameRound(data)
              toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
              history.push(WEB_PATHS.GAME_ROUND)
            } catch (e) {
              toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
            }
          }}>
            { props => (
              <form className="sm:w-[1200px] w-[1200px] overflow-scroll scrollbar-hide" onSubmit={props.handleSubmit}>
                {props.values.gameRoundGen.length > 0 &&
                  <div>
                    <div className="flex px-2 py-3 w-full items-center border-b border-t-0 border-r-0 border-l-0 border border-b-border-0">
                      <div className="sm:w-[3%] w-[40px] sm:min-w-max">#</div>
                      {/* <div className="sm:w-[20%] w-[230px] sm:min-w-max text-16px text-text-2">วันที่เริ่ม</div> */}
                      {/* <div className="sm:w-[20%] w-[240px] sm:min-w-max text-16px text-text-2">วันที่สิ้นสุด</div> */}
                      <div className="sm:w-[20%] w-[240px] sm:min-w-max text-16px text-text-2">วันที่เปิดแทง</div>
                      <div className="sm:w-[20%] w-[230px] sm:min-w-max text-16px text-text-2">วันที่ปิดแทง</div>
                      <div className="sm:w-[50px] w-[100px] sm:min-w-max text-16px text-text-2">เปิด-ปิด</div>
                    </div>
                    {renderGameRoundSet(props)}
                  </div>
                }
                <div className={props.values.gameRoundGen.length <= 0 ? 'hidden' : 'pt-3'}>
                  <Button
                    buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
                    buttonText="บันทึก"
                    type="submit"
                  />
                </div>
              </form>
            )
          }
      </Formik>
      </div>
    </div>
  )
}

export default withLayout(GameRoundCreate, WEB_TITLE.GAME, 'GAME ROUND')