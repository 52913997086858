
import withLayout from '../../layout/withLayout'
import { useParams, useHistory } from 'react-router'
import Button from '../../components/Button'
import withdrawApi from '../../api/withdraw.api'
import { useEffect, useState } from 'react'
import { format, utcToZonedTime } from 'date-fns-tz'
import song from '../../public/sound/soundNotification.mp3'
import useModal from '../../hook/useModal'
import ModalConfirms from '../../components/ModalConfirms'
import alertNotification from '../../constant/alertNotification'
import IWithdraw from '../../model/WithdrawApi/IWithdraw'
import BeradCrump from '../../components/BreadCrump'
import { MENU_TITLE } from '../../constant/menuTitle'
import IUserHistory from '../../model/UserApi/IUserHistory'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'
import WEB_PATHS from '../../constant/webPath'

const withDraw = () => {
  const { type }: any = useParams()
  const history = useHistory()
  const [modalApproveAll, setModalApproveAll] = useState(false)
  const { stateModal, onChangeStateModal } = useModal()
  const [dataSubmit, setDataSubmit] = useState({
    id: 0,
    state: 0
  })
  const audio = new Audio(song)
  const [switchAudio, setSwitchAudio] = useState<string>(localStorage.getItem(alertNotification.withdraw) != null ? localStorage.getItem(alertNotification.withdraw) as string : 'true')
  const [multiSelectWithdraw, setMultiSelectWithdraw] = useState<number[]>([])
  const [withdrawRemark, setWithdrawRemark] = useState('')
  const [withdraw, setWithdraw] = useState<IWithdraw[]>([])
  const fetchWithDrawData = async (value: string) => {
    const response = await withdrawApi.withdraw(value)
    const data = localStorage.getItem(alertNotification.withdraw)
    if (data != null) {
      data === 'true' ? audio.play() : audio.pause()
    } else {
      localStorage.setItem(alertNotification.withdraw, 'true')
    }
    if (response[0].userHistories.length > withdraw.length) {
      setWithdraw(response)
      toastActive({state: 'success', description: 'โหลดข้อมูลสำเร็จ', duration: 500})
    } else if (response[0].userHistories.length <= 0 || response[0].userHistories.length === null) {
      setWithdraw([])
      toastActive({state: 'warning', description: 'ไม่มีข้อมูล', duration: 500})
    } else {
      toastActive({state: 'warning', description: 'ยังไม่มีข้อมูลอัพเดท', duration: 500})
    }
  }
  useEffect(() => {
    if (type === 'all') {
      const intervalSet = setInterval(() => { fetchWithDrawData(type) }, 30000)
      intervalSet
      localStorage.setItem('interval withdraw', String(intervalSet))
      fetchWithDrawData(type)
      return () => clearInterval(intervalSet)
    } else {
      const intervalStorage = localStorage.getItem('interval withdraw')
      intervalStorage != null ? clearInterval(Number(intervalStorage)) : ''
      intervalStorage != null ? localStorage.removeItem('interval withdraw') : ''
      fetchWithDrawData(type)
    }
  }, [type])
  const renderWithdrawList = (): React.ReactNode => {
    if (withdraw.length <= 0) return <div className="flex border-border-0 border-t-2 justify-center items-center bg-white py-1.5 px-2 w-full">ยังไม่มีข้อมูล</div>
    return withdraw.map((item: IWithdraw, index: number) => {
      return (
        <div key={index}>
          <div className="sm:w-full w-full overflow-scroll scrollbar-hide">
            {renderTable(item.userHistories)}
          </div>
        </div>
      )
    })
  }
  const renderDateTime = (date: any): any => {
    const newDate = new Date(date)
    const timeZone = 'Asia/Bangkok'
    const nyDate = utcToZonedTime(newDate, timeZone)
    return format(nyDate, 'dd-MM-yyyy HH:mm')
  }
  const renderStatus = (status: number): React.ReactElement => {
    const text = status === 0 ? 'รอดำเนินการ' : 'เกิดข้อผิดพลาด'
    return (
      <div className={status === 0 ? 'text-text-0' : 'text-text-0'}>
        {text}
      </div>
    )
  }
  const rejectWithdrawSubmit = async () => {
    try {
      await withdrawApi.withdrawApproveReject({id: dataSubmit.id, status: dataSubmit.state, remark: withdrawRemark})
      toastActive({state: 'success', description: 'ยกเลิกรายโอนนี้สำเร็จ', duration: 500})
      setWithdrawRemark('')
      onChangeStateModal()
      fetchWithDrawData(type as string)
      setMultiSelectWithdraw([])
    }catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถยกเลิกการโอนนี้ได้', duration: 500})
    }
  }
  const approveAllSelect = async (): Promise<void> => {
    if (multiSelectWithdraw.length > 0) {
      try {
        await withdrawApi.withdrawApproveAll({ id: multiSelectWithdraw, remark: withdrawRemark})
        setMultiSelectWithdraw([])
        toastActive({state: 'success', description: 'อนุมัติรายการทั้งหมดสำเร็จ', duration: 500})
        setWithdrawRemark('')
        closeModalApproveAll()
        fetchWithDrawData(type as string)
      }catch (e) {
        closeModalApproveAll()
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถอนุมัติการโอนทั้งหมดได้', duration: 500})
      }
    } else {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด กรุณาเลือกรายการที่ต้องการโอน', duration: 500})
    }
  }
  const ApproveWithdrawSubmit = async () => {
    try {
      await withdrawApi.withdrawApproveReject({id: dataSubmit.id, status: dataSubmit.state, remark: withdrawRemark})
      toastActive({state: 'success', description: 'อนุมัติรายการโอนเงินสำเร็จ' , duration: 500})
      onChangeStateModal()
      fetchWithDrawData(type as string)
      setMultiSelectWithdraw([])
    }catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถอนุมัติการโอนนี้ได้', duration: 500})
    }
  }
  const addRemark = (event: any) => {
    setWithdrawRemark(event)
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[300px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const copyToClipboard = (id: string): void => {
    const TempText = document.createElement('textarea')
    TempText.value = String(id)
    document.body.appendChild(TempText)
    TempText.select()
    document.execCommand('copy')
    document.body.removeChild(TempText)
    toastActive({state: 'success', description: 'เลือกและคัดลอกสำเร็จ', duration: 500})
  }
  const checkMultiSelect = (id: number, bankNumber: string): void => {
    if (multiSelectWithdraw.some(ids => Number(ids) === Number(id))) {
      const newArray = multiSelectWithdraw
      setMultiSelectWithdraw(newArray.filter(item => item !== id))
      toastActive({state: 'success', description: 'ยกเลิกการเลือกสำเร็จ', duration: 500})
    } else {
      setMultiSelectWithdraw((multiSelectWithdraw) => [...multiSelectWithdraw, id])
      copyToClipboard(String(bankNumber))
    }
  }
  const renderTable = (childContent: IUserHistory[]): React.ReactElement => {
    if (childContent.length <= 0) return <div className="flex border-border-0 border-t-2 items-center justify-center bg-white py-1.5 px-2 w-full">ยังไม่มีข้อมูล</div>
    return (
      <div className="overflow-auto scrollbar-hide sm:w-[1640px] w-[1350px] pb-2.5">
        <div className="flex px-2 w-full items-center">
          <div className="sm:w-[35px] w-[30px]">#</div>
          <div className="sm:w-[100px] w-[100px] text-left">Username</div>
          <div className="sm:w-[100px] w-[120px] text-right">จำนวนเงิน</div>
          <div className="sm:w-[180px] w-[120px] text-left px-2.5">ชื่อธนาคาร</div>
          <div className="sm:w-[150px] w-[150px] text-left">เลขที่บัญชี</div>
          <div className="sm:w-[190px] w-[160px] text-left">ชื่อบัญชีธนาคาร</div>
          <div className="sm:w-[160px] w-[120px] text-left px-2.5">สถานะ</div>
          <div className="sm:w-[175px] w-[150px] text-left px-2.5">เวลาสร้าง</div>
          <div className="sm:w-[350px] w-[200px] px-2.5"></div>
        </div>
        {renderContent(childContent)}
      </div>
    )
  }
  const renderContent = (item: IUserHistory[]): React.ReactElement => {
    if (item.length <= 0) {
      return (
        <div className="w-full items-center text-20px text-center text-text-6 justify-center flex">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div>
        {item.map((withdraw, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[35px] w-[30px] text-text-6 overflow-hidden truncate">{index +1}.</div>
              <div className="sm:w-[100px] w-[100px] overflow-hidden truncate text-16px text-text-6">
                <span onClick={(): unknown => withdraw.username
                    ? history.push({pathname: WEB_PATHS.USER_EDIT.replace(':id', String(withdraw.userId))})
                    : ''
                  } className="hover:cursor-pointer hover:underline hover:text-blue-600">
                  {withdraw.username ? withdraw.username : '-'}
                </span>
              </div>
              <div className="sm:w-[100px] w-[120px] text-16px text-text-6 text-right">{withdraw.amount.toLocaleString('th')} ฿</div>
              <div className="sm:w-[180px] w-[120px] overflow-hidden truncate text-16px text-text-6 text-left px-2.5">{withdraw.bankName.length ? withdraw.bankName : '-'}</div>
              <div className="sm:w-[150px] w-[150px] flex flex-row text-16px text-text-6 text-left px-2.5">
                <label className="flex" htmlFor={`checkBankAccount-${withdraw.id}`}>
                <input
                  onClick={(): void => checkMultiSelect(withdraw.id, withdraw.bankAccount)}
                  type="checkbox"
                  id={`checkBankAccount-${withdraw.id}`}
                  name={`checkBankAccount-${withdraw.id}`}
                  value={withdraw.id}
                />
                <span className="pl-2">{withdraw.bankAccount.length ? withdraw.bankAccount : '-'}</span>
                </label>
              </div>
              <div className="sm:w-[190px] w-[160px] text-16px text-text-6 overflow-hidden truncate text-left px-2.5">{withdraw.bankOwnerName.length ? withdraw.bankOwnerName : '-'}</div>
              <div className="sm:w-[160px] w-[120px] text-16px text-text-6 text-left px-2.5">{renderStatus(withdraw.status)}</div>
              <div className="sm:w-[175px] w-[150px] text-16px text-text-6 overflow-hidden truncate text-left px-2.5">{renderDateTime(withdraw.createdAt)}</div>
              <div className="sm:w-[350px] w-[350px] px-2.5">
                <div className="group flex justify-end w-full gap-2">
                  <button className="bg-button-5 hover:bg-button-6 text-16px text-white rounded-lg py-1 px-3 w-auto" onClick={(): void => {
                    setDataSubmit({
                      id: withdraw.id,
                      state: 1
                    })
                    setWithdrawRemark('')
                    onChangeStateModal()
                  }}>อนุมัติ</button>
                  <button className="bg-button-0 hover:bg-button-3 text-16px text-white rounded-lg py-1 px-3 w-auto" onClick={(): void => {
                    setDataSubmit({
                      id: withdraw.id,
                      state: 3
                    })
                    setWithdrawRemark('')
                    onChangeStateModal()
                  }}>อนุมัติและโอน</button>
                  <button className="bg-button-1 hover:bg-button-4 text-16px text-white rounded-lg py-1 px-3 w-auto" onClick={(): void => {
                    setDataSubmit({
                      id: withdraw.id,
                      state: 2
                    })
                    setWithdrawRemark('')
                    onChangeStateModal()
                  }}>ไม่อนุมัติ</button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const renderInputSwitch = (value: string) => {
    return value === 'true'
      ? <input
          type="checkbox"
          defaultChecked={Boolean(value)}
          onClick={() => chageSwitch(value as string)}
          className="toggle-active toggle-checkbox absolute block w-5 h-5 top-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer right-0.5"/>
      : <input
          type="checkbox"
          defaultChecked={Boolean(value)}
          onClick={() => chageSwitch(value as string)}
          className="toggle-checkbox absolute block w-5 h-5 top-0.5 left-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer"/>
  }
  const renderLabel = (value: string) => {
    return value === 'true'
      ? <label className="toggle-label block h-6 rounded-full bg-background-10 cursor-pointer"></label>
      : <label className="label-toggle-active toggle-label block h-6 rounded-full bg-background-9 cursor-pointer"></label>
  }
  const chageSwitch = (value: string) => {
    const state = value === 'true' ? 'false' : 'true'
    setSwitchAudio(state)
    localStorage.setItem(alertNotification.withdraw, state)
  }
  const renderInputRemark = (): React.ReactElement => {
    return (
      <div>
        <input
          className="px-2 py-1 border border-gray-300 bg-white text-gray-900 focus:border-blue-500 rounded-md shadow-sm w-full h-16"
          type="text"
          name={withdrawRemark}
          value={withdrawRemark}
          placeholder="กรอกหมายเหตุ"
          onChange={
            (e) => {
              addRemark(e.target.value)
              setWithdrawRemark(e.target.value)
          }}
        />
      </div>
    )
  }
  const closeModalApproveAll = (): void => {
    setModalApproveAll(false)
  }
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col justify-between sm:pb-6 pb-2">
        <BeradCrump headTitle={MENU_TITLE.WITHDRAW} showIcon={true} classTitle="font-katnitBold" title={type === 'all' ? 'รวมทุกธนาคาร' : ''} />
        <div
          className="sm:text-right sm:flex flex sm:flex-col flex-col sm:justify-between sm:items-center sm:pt-0 pt-7">
          <div className="flex justify-between w-full">
            <div>
              <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
                {renderInputSwitch(switchAudio as string)}
                {renderLabel(switchAudio as string)}
              </div>
              <label className="sm:pr-3 sm:pl-5 pl-3">เสียงเตือน</label>
            </div>
            <Button
              type="button"
              buttonClassName="bg-background-10 hover:bg-background-11 text-white text-16px sm:w-auto max-w-max px-3 py-2 rounded-lg"
              buttonText="ดึงข้อมูล"
              onClick={() => fetchWithDrawData(type as string)}
            />
          </div>
          <div className="w-full pt-3 text-right bg-backgro">
            <Button
              type="button"
              buttonClassName={
                multiSelectWithdraw.length > 0
                ? 'bg-background-10 ml-2 hover:bg-background-11 w-auto text-white text-16px sm:w-auto px-3 py-2 rounded-lg'
                : 'bg-background-9 ml-2 cursor-not-allowed text-white w-auto text-16px sm:w-auto px-3 py-2 rounded-lg'
              }
                buttonText="อนุมัติที่เลือกทั้งหมด"
              onClick={() => multiSelectWithdraw.length > 0 ? setModalApproveAll(true) : ''}
            />
          </div>
        </div>
      </div>
      {renderWithdrawList()}
      <ModalConfirms
        showModalConfirm={stateModal}
        titleHeader="คำเตือน"
        detailHeader={ Number(dataSubmit.state) === 1 || Number(dataSubmit.state) === 3 ? 'คุณต้องการอนุมัติรายการโอน ใช่หรือไม่ ?' : 'คุณต้องการยกเลิกรายการโอน ใช่หรือไม่ ?' }
        btnButtonConfirm={Number(dataSubmit.state === 2) ? rejectWithdrawSubmit : ApproveWithdrawSubmit}
        showRemark={true}
        btnButtonCancel={onChangeStateModal}
        customElement={renderInputRemark()}
        textButtonConfirm="ตกลง"
        textButtonCancel="ยกเลิก"
      />
      <ModalConfirms
        showModalConfirm={modalApproveAll}
        titleHeader="คำเตือน"
        detailHeader="คุณต้องการอนุมัติรายการโอนทั้งหมดนี้ ใช่หรือไม่"
        btnButtonConfirm={approveAllSelect}
        showRemark={true}
        btnButtonCancel={closeModalApproveAll}
        customElement={renderInputRemark()}
        textButtonConfirm="ตกลง"
        textButtonCancel="ยกเลิก"
      />
    </div>
  )
}


export default withLayout(withDraw, 'Withdraw')