enum Member {
  'Member',
  'VIP',
  'Admin'
}

const roleData = [{
  name: 'Member',
  id: Member.Member
}, {
  name: 'VIP',
  id: Member.VIP
}, {
  name: 'Admin',
  id: Member.Admin
}]

export default roleData