import withLayout from '../../../layout/withLayout'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import bankApi from '../../../api/bank.api'
import { Formik } from 'formik'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import InputSelect from '../../../components/InputSelect'
import schema from '../../../validation/bankListSchema'
import InputSelectBankGroup from '../../../components/InputSelectBankGroup'
import IBank from '../../../model/BankAdminApi/IBank'
import WEB_PATHS from '../../../constant/webPath'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import { MENU_TITLE } from '../../../constant/menuTitle'

const BankEdit = () => {
  const titleHeader = MENU_TITLE.BANK_EDIT
  const typePayment = [{
    id: 0,
    name: "deposit/ฝาก"
  }, {
    id: 1,
    name: "withdraw/ถอน"
  }]
  const { id }: any = useParams()
  const history = useHistory()
  const [bank, setBank] = useState<IBank>({
    id: 0,
    bankId: 0,
    bankAccount: '',
    groupBankId: 0,
    userLogin: '',
    passLogin: '',
    selecttextLogin: '',
    bankType: 0,
    deviceId: '',
    apiRefresh: '',
    apiAccountNumber: '',
    accessToken: '',
    bankOwnerName: '',
    pinCode: ''
  })
  const [bankAdmin, setBankAdmin] = useState<any[]>([])
  const [bankGroup, setBankGroup] = useState<any[]>([])
  const backPage = (): any => {
    history.push({
      pathname: WEB_PATHS.BANK_LIST
    })
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  useEffect(() => {
    (async function fetchBankData(): Promise<any> {
      try {
        const response = await bankApi.getBankById(id as number)
        const bankResponse = await bankApi.getBankAdmin()
        const bankGroupResponse = await bankApi.bankGroup()
        setBank(response)
        setBankAdmin(bankResponse)
        setBankGroup(bankGroupResponse)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  return (
    <Formik
      initialValues={{
        id: bank.id,
        bankId: bank.bankId,
        bankAccount: bank.bankAccount,
        bankOwnerName: bank.bankOwnerName,
        groupBankId: String(bank.groupBankId),
        bankType: bank.bankType,
        userLogin: bank.userLogin,
        password: bank.passLogin,
        selecttextLogin: bank.selecttextLogin,
        deviceId: bank.deviceId,
        apiRefresh: bank.apiRefresh,
        apiAccountNumber: bank.apiAccountNumber,
        accessToken: bank.accessToken,
        pinCode: bank.pinCode
      }}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values): Promise<void> => {
        const data = {
          id: Number(values.id),
          bankId: Number(values.bankId),
          bankAccount: values.bankAccount,
          groupBankId: Number(values.groupBankId),
          userLogin: values.userLogin,
          passLogin: values.password,
          selecttextLogin: values.selecttextLogin,
          bankType: Number(values.bankType),
          deviceId: values.deviceId,
          apiRefresh: values.apiRefresh,
          apiAccountNumber: values.apiAccountNumber,
          accessToken: values.accessToken,
          bankOwnerName: values.bankOwnerName,
          pinCode: values.pinCode
        }
        try {
            await bankApi.saveBankById(data)
            toastActive({state: 'success', description: 'แก้ไขข้อมูลสำเร็จ', duration: 500})
            history.push({
              pathname: WEB_PATHS.BANK_LIST
            })
        } catch (e) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถแก้ไขข้อมูลได้', duration: 500})
        }
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }): React.ReactElement => (
      <form className="pb-4" onSubmit={handleSubmit}>
        <div className="text-2xl flex max-w-max items-center cursor-pointer pb-6 hover:opacity-80" onClick={ () => backPage() }>
          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
          </svg>
          <span className="ml-3 text-20px font-katnitBold">{titleHeader}</span>
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
          <InputSelect
            name="bankType"
            options={typePayment}
            value={Number(values.bankType)}
            textLabelTopSelect="ประเภทธนาคาร"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกประเภทธนาคาร"
            showNumberAndText={false}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankType && errors.bankType}
          />
          <InputSelect
            name="bankId"
            options={bankAdmin}
            value={values.bankId}
            textLabelTopSelect="บัญชีธนาคาร"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกบัญชีธนาคาร"
            showNumberAndText={false}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankId && errors.bankId}
          />
          <Input
            name="bankAccount"
            type="text"
            value={values.bankAccount}
            placeholder="กรุณาพิมเลขบัญชีธนาคาร"
            textLabelTopInput="เลขบัญชีธนาคาร"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankAccount && errors.bankAccount}
          />
          <Input
            name="bankOwnerName"
            type="text"
            value={values.bankOwnerName}
            placeholder="bank owner name"
            textLabelTopInput="Bank Owner Name"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankOwnerName && errors.bankOwnerName}
          />
          <Input
            name="userLogin"
            type="text"
            value={values.userLogin}
            placeholder="user login"
            textLabelTopInput="User login"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.userLogin && errors.userLogin}
          />
          <Input
            name="password"
            type="password"
            value={values.password}
            placeholder="password"
            textLabelTopInput="Password"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.password && errors.password}
          />
          <Input
            name="pinCode"
            type="text"
            value={values.pinCode}
            placeholder="pin code"
            textLabelTopInput="Pin Code"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.pinCode && errors.pinCode}
          />
          <InputSelectBankGroup
            name="groupBankId"
            options={bankGroup}
            value={values.groupBankId}
            textLabelTopSelect="Group Bank"
            showTextLabelTopInput={true}
            labelOptionText="Group Bank"
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.groupBankId && errors.groupBankId}
          />
          <Input
            name="selecttextLogin"
            type="text"
            value={values.selecttextLogin}
            placeholder="select text login"
            textLabelTopInput="Select Text Login"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.selecttextLogin && errors.selecttextLogin}
          />
          <Input
            name="deviceId"
            type="text"
            value={values.deviceId}
            placeholder="device id"
            textLabelTopInput="Device Id"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.deviceId && errors.deviceId}
          />
          <Input
            name="apiRefresh"
            type="text"
            value={values.apiRefresh}
            placeholder="api refresh"
            textLabelTopInput="Api Refresh"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.apiRefresh && errors.apiRefresh}
          />
          <Input
            name="apiAccountNumber"
            type="text"
            value={values.apiAccountNumber}
            placeholder="api account number"
            textLabelTopInput="Api Account Number"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.apiAccountNumber && errors.apiAccountNumber}
          />
          <Input
            name="accessToken"
            type="text"
            value={values.accessToken}
            placeholder="Token"
            textLabelTopInput="Access Token"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.accessToken && errors.accessToken}
          />
        </div>
        <Button
          type="submit"
          buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-4 text-16px"
          buttonText="บันทึก"
        />
      </form>
      )}
    </Formik>
  )
}

export default withLayout(BankEdit, 'Bank List', MENU_TITLE.BANK_EDIT)