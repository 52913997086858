import api from './index'
import quickPanel from '../model/quickPanel'
import API_PATHS from '../constant/apiPath'
import IGameResults from '../model/IGameResults'
import IGameDetails from '../model/IGameDetails'
import IGamePanelNumber from '../model/GamePanelApi/IGamePanelNumber'
import IGamePanelCalculateBet from '../model/GamePanelApi/IGamePanelCalculateBet'
import IGameCalculateBetResult from '../model/GamePanelApi/IGameCalculateBetResult'
import IGameResultTotalBet from '../model/GamePanelApi/IGameResultTotalBet'
import IGameAllResult from '../model/QuickPanelApi/IGameAllResult'

const quickPanelApi = {
  getQuickPanel: async (): Promise<quickPanel[]> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL)
      return response.data
    } catch (e) {
      throw e
    }
  },
  getPanelResult: async (id: number | string, query: string): Promise<IGameResults> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_RESULT.replace(':id', String(id)),{
        params: { qs: query }
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getPanelDetail: async (id: number | string, query: string): Promise<IGameDetails[]> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_DETAILS.replace(':id', String(id)),{
        params: { qs: query }
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getPanelNumberById: async (id: number | string, query: string): Promise<IGamePanelNumber> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_NUMBER_BY_ID.replace(':id', String(id)),{
        params: { qs: query }
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  cancelGamePoll: async (id: number | string): Promise<void> => {
    try {
      await api.post(API_PATHS.QUICK_PANEL_GAME_CANCEL_BY_ID.replace(':id', String(id)))
    } catch (e) {
      throw e
    }
  },
  getGameCalculateBetForm: async (id: number | string, round: number | string): Promise<IGamePanelCalculateBet> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_GAME_CALCULATE_BET.replace(':id', String(id)),{
        params: { round: round }
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getGameCalculateBetResult: async (params: IGamePanelCalculateBet): Promise<IGameCalculateBetResult> => {
    try {
      const response = await api.post(API_PATHS.QUICK_PANEL_GAME_CALCULATE_BET_RESULT, params)
      return response.data
    } catch (e) {
      throw e
    }
  },
  postGameCalculateBetResult: async (params: IGamePanelCalculateBet): Promise<void> => {
    try {
      await api.post(API_PATHS.QUICK_PANEL_GAME_CALCULATE, params)
    } catch (e) {
      throw e
    }
  },
  getRoundGameById: async (id: string | number): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_GAME_ROUND.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  getAllRoundGameById: async (id: string | number): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.GAME_ALL_ROUND.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  getResultTotalBetById: async (id: string | number): Promise<IGameResultTotalBet> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_GAME_RESULT_TOTAL_BET.replace(':roundId', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  getAllResult: async (id: string | number, query: string): Promise<IGameAllResult> => {
    console.log(query, 'query')
    try {
      const response = await api.get(API_PATHS.GAME_ALL_RESULT.replace(':id', String(id)),{
        params: { qs: query }
      })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getPrimeResult: async (id: string | number, query: string): Promise<IGameAllResult> => {
    console.log(query, 'query')
    try {
      const response = await api.get(API_PATHS.GAME_PRIME_RESULT.replace(':id', String(id)),{
        params: { qs: query }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }
}

export default quickPanelApi