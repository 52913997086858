const WEB_PATHS = {
  LOGIN: '/',

  DASHBOARD: '/dashboard',
  OVERVIEW: '/overview',

  SETTING_HOMEPAGE: '/settings/homepage',
  SETTING_LINE_NOTIFICATION: '/settings/line',
  SETTING_PAYMENT: '/payment-transaction',
  SETTING_BOT_DEPOSIT: '/bot/deposit',
  SETTING_BOT_WITHDRAW: '/bot/withdraw',

  WITHDRAW_AUTO_SETTING: '/withdraw-auto',
  
  BLOG_CREATE: '/blog/create',
  BLOG_EDIT: '/blog/edit/:id',
  BLOG_LIST: '/blog',

  USER_EDIT: '/user/member/:id',
  USER_FINANCE: '/user/:id/finance',
  USER_LIMIT_LATE: '/user/:id/limit-late',
  USER_HISTORY: '/user/:id/history',
  USER_TRANSACTION_BET: '/user/:id/transaction',
  USER_TRANSACTION_BET_BY_ID: '/user/:id/transaction/:idBet',
  USER_LIST: '/user/member',
  USER_ADMIN: '/user/admin',
  USER_ADMIN_ID: '/user/admin/:id',
  USER_AFFILIATE: '/user/affiliate/:id',

  BANK_GROUP_LIST: '/bank/group/list',
  BANK_GROUP_CREATE: '/bank/group/create',
  BANK_GROUP_EDIT: '/bank/group/edit/:id',
  BANK_LIST: '/bank/list',
  BANK_CREATE: '/bank/create',
  BANK_EDIT: '/bank/edit/:id',
  BANK_TRANFER: '/bank/tranfer',

  DEPOSIT: '/deposit/:type',
  DEPOSIT_TEMP: '/deposit/bank-temp-history',
  WITHDRAW: '/withdraw/:type',

  GAME_RATE: '/games/rate',
  GAME_LIMIT: '/games/limit',
  GAME_RATE_SYSTEM: '/games/rate/system',
  GAME_PANEL: '/game-panel/:id',
  GAME_PANEL_ALL_RESULT: '/game-panel/:id/all-result',
  GAME_PANEL_PRIME_RESULT: '/game-panel/:id/prime-result',
  GAME_PANEL_LIST_ITEMS: '/game-panel/:id/list-items',
  GAME_PANEL_CALCULATE_BET: '/game-panel/:id/caculate-bet',
  GAME_PANEL_RESULT_TOTAL_BET: '/game-panel/:id/result-total-bet',
  GAME_ROUND: '/games/game-round',
  GAME_ROUND_CREATE: '/games/game-round/create',
  GAME_ROUND_EDIT: '/games/game-round/:id',
  GAME_YEEKEE: '/games/yeekee',
  GAME_SET: '/games/set/setting'
}

export default WEB_PATHS