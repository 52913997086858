import * as Yup from 'yup'

const schema = Yup.object({
  bankId: Yup.string()
    .required('กรุณาเลือกธนาคารต้นทาง').test('bankId', 
    'ธนาคารต้นทางต้องไม่ตรงกับปลายทาง',
    function(bankId) { 
      return bankId !== this.parent.toBankId;
   }),
  toBankId: Yup.string()
    .required('กรุณาเลือกธนาคารปลายทาง')
    .test('bankId', 
    'ธนาคารปลายทางต้องไม่ตรงกับปลายทาง',
    function(toBankId) { 
      return toBankId !== this.parent.bankId;
   }
  ),
  amount: Yup.string()
    .required('กรุณาใส่จำนวนเงิน'),
});

export default schema