import * as Yup from 'yup'

const schema = Yup.object({
  password: Yup.string()
    .matches(/^[aA0-zZ9!#$%&'()*+,-./:;<=>?@[\]^_{|}~]/, 'Characters english or number only !')
    .min(8, 'password more than 8 characters'),
  bankId: Yup.string()
    .required('required'),
  bankAccount: Yup.string()
    .required('required'),
  bankOwnerName: Yup.string()
    .required('required'),
  firstName: Yup.string()
    .required('required'),
  lastName: Yup.string()
    .required('required'),
  phoneNumber: Yup.string()
    .required('required')
});

export default schema