import api from './index'
import API_PATHS from '../constant/apiPath'
import IBank from '../model/BankAdminApi/IBank'
import IBankList from '../model/BankAdminApi/IBankList'
import IBankDeposit from '../model/BankAdminApi/IBankDeposit'
import IBankTranferList from '../model/BankAdminApi/IBankTranferList'

const bankApi = {
  getBankAdmin: async(): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.BANK)
      return response.data
    } catch (e) {
      throw e
    }
  },
  getBankDeposit: async(): Promise<IBankDeposit[]> => {
    try {
      const response = await api.get(API_PATHS.BANK_ADMIN)
      return response.data
    } catch (e) {
      throw e
    }
  },
  getBank: async(): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.BANK)
      return response.data
    } catch (e) {
      throw e
    }
  },
  bankList: async(): Promise<IBankList[]> => {
    try {
      const response = await api.get(API_PATHS.BANK_LIST_ADMIN)
      return response.data
    } catch (e) {
      throw e
    }
  },
  bankGroup: async(): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.BANK_GROUP)
      return response.data
    } catch (e) {
      throw e
    }
  },
  getBankById: async(id: number): Promise<IBank> => {
    try {
      const response = await api.get(API_PATHS.BANK_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  removeBank: async(id: any): Promise<any> => {
    try {
      const response = await api.delete(API_PATHS.BANK_ID.replace(':id', id))
      return response
    } catch (e) {
      throw e
    }
  },
  saveBank: async(value: IBank): Promise<any> => {
    try {
      await api.post(API_PATHS.BANK_LIST_ADMIN, value)
    } catch (e) {
      throw e
    }
  },
  saveBankById: async(values: IBank): Promise<any> => {
    try {
      await api.put(API_PATHS.BANK_LIST_ADMIN, values)
    } catch (e) {
      throw e
    }
  },
  saveBankGroup: async(values: any): Promise<any> => {
    try {
      await api.post(API_PATHS.BANK_GROUP, values)
    } catch (e) {
      throw e
    }
  },
  updateBankGroup: async(values: any): Promise<any> => {
    try {
      await api.put(API_PATHS.BANK_GROUP, values)
    } catch (e) {
      throw e
    }
  },
  getBankGroupById: async(id: number): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.BANK_GROUP_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  removeBankGroup: async(id: any): Promise<any> => {
    try {
      const response = await api.delete(API_PATHS.BANK_GROUP_ID.replace(':id', id))
      return response
    } catch (e) {
      throw e
    }
  },
  getBankTranferList: async(): Promise<IBankTranferList[]> => {
    try {
      const response = await api.get(API_PATHS.BANK_TRANFER_LIST)
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveBankTranferWithDraw: async(values: any): Promise<any> => {
    try {
      await api.post(API_PATHS.BANK_TRANFER_WITHDRAW, values)
    } catch (e) {
      throw e
    }
  },
  setNotificationBankTransfer: async(params: string): Promise<any> => {
    try {
      await api.get(API_PATHS.BANK_TRANFER_FOR_NOTI, {params: { qs: params }})
    } catch (e) {
      throw e
    }
  }
}

export default bankApi