import withLayout from '../../../layout/withLayout'
import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import botSettingApi from '../../../api/botSetting.api'
import bankApi from '../../../api/bank.api'
import Button from '../../../components/Button'
import * as Yup from 'yup'
import IBetConditionList from '../../../model/SettingsApi/IBotConditionList'
import WEB_TITLE from '../../../constant/webTitle'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BreadCrump from '../../../components/BreadCrump'
import IBankList from '../../../model/BankAdminApi/IBankList'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import Input from '../../../components/Input'

const botWithdraw = () => {
  const [active, setActive] = useState<boolean>(false)
  const [dataList, setDataList] = useState<IBetConditionList[]>([])
  const [bank, setBank] = useState<IBankList[]>([{
    bankAccount: '',
    bankName: '',
    bankOwnerName: '',
    groupBankName: '',
    id: 0,
    logoUrl: '',
    bankType: ''
  }])
  useEffect(() => {
    (async function fetchData(): Promise<any> {
      try {
        const response = await botSettingApi.getBotSetting()
        const data = await bankApi.bankList()
        setActive(response.isBotActive)
        setDataList(response.conditionList)
        setBank(data)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  const renderSwitch = (props: any) => {
    return (
      <div
        onClick={(): void => {
          props.setFieldValue('isBotActive', !props.values.isBotActive)
        }}
        className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <div>
          <input
            type="checkbox"
            name="isBotActive"
            defaultChecked={false}
            value={props.values.isBotActive}
            className={ props.values.isBotActive
              ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
              : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
            }
          />
          <label className={ props.values.isBotActive
            ? 'toggle-label h-6 block rounded-full bg-background-10 cursor-pointer'
            : 'toggle-label h-6 block rounded-full bg-background-9 cursor-pointer'
            }>
          </label>
        </div>
      </div>
    )
  }
  const getBankAccount = (id: any = 0) => {
    const data = bank.filter(item => item.bankAccount === id)
    return data.length > 0 ? data[0].id : ''
  }
  const renderSelectBank = (props: any, item: any, index: number) => {
    return (
      <div>
        <label className="text-16px sm:hidden block pb-1">ธนาคาร</label>
       <div>
        <select
          className="sm:w-[380px] w-full sm:h-[40px] h-8 text-text-2 border border-border-0 bg-white rounded-lg text-right"
          name={`conditionList.${index}.bankAccount`}
          value={item.bankAccount}
          onBlur={props.handleBlur}
          onChange={e => {
            const bankAccount = getBankAccount(e.target.value)
            props.setFieldValue(`conditionList.${index}.bankAccount`, e.target.value)
            props.setFieldValue(`conditionList.${index}.bankId`, bankAccount)
          }}
          aria-label="select"
        >
          <option value="">กรุณาเลือกบัญชีธนาคาร</option>
          {bank.map((o: IBankList, index) => {
            return (
              <option key={index} value={o.bankAccount}>
                {o.bankName} - {o.bankAccount}
              </option>
            )
          })}
        </select>
       </div>
       {props.errors &&
          props.errors.conditionList &&
          props.errors.conditionList[index] &&
          props.errors.conditionList[index].bankAccount &&
          props.touched &&
          props.touched.conditionList &&
          props.touched.conditionList[index] &&
          props.touched.conditionList[index].bankAccount && props.errors.conditionList[index].bankAccount ? 
        <label className="flex items-center pt-1.5">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM6.54545 3.27273C6.54545 3.12806 6.48799 2.98933 6.38569 2.88703C6.2834 2.78474 6.14466 2.72727 6 2.72727C5.85534 2.72727 5.7166 2.78474 5.61431 2.88703C5.51201 2.98933 5.45455 3.12806 5.45455 3.27273V6.54545C5.45455 6.69012 5.51201 6.82886 5.61431 6.93115C5.7166 7.03344 5.85534 7.09091 6 7.09091C6.14466 7.09091 6.2834 7.03344 6.38569 6.93115C6.48799 6.82886 6.54545 6.69012 6.54545 6.54545V3.27273ZM6.54545 8.45455C6.54545 8.30988 6.48799 8.17114 6.38569 8.06885C6.2834 7.96656 6.14466 7.90909 6 7.90909C5.85534 7.90909 5.7166 7.96656 5.61431 8.06885C5.51201 8.17114 5.45455 8.30988 5.45455 8.45455V8.72727C5.45455 8.87194 5.51201 9.01068 5.61431 9.11297C5.7166 9.21526 5.85534 9.27273 6 9.27273C6.14466 9.27273 6.2834 9.21526 6.38569 9.11297C6.48799 9.01068 6.54545 8.87194 6.54545 8.72727V8.45455Z" fill="#DC1B1B"/>
          </svg>
          <p className="text-red-500 text-xs text-left pl-1">{props.errors.conditionList[index].bankAccount}</p>
        </label>
        : null
      }
      </div>
    )
  }
  const renderRow = (props: any): React.ReactNode => {
    if (props.values.conditionList.length <= 0) return 
    return props.values.conditionList.map((item: any, index: number) => {
      return (
          <div
            className={index === 0
              ? 'sm:items-end sm:flex-row flex flex-col pt-3 pb-3 sm:pb-0 border-border-0 sm:border-t sm:border-b-0 border-t-0 border-b'
              : 'sm:items-end sm:flex-row flex flex-col pt-3 pb-3 sm:border-transparent border-border-0 sm:border-t sm:border-b-0 border-t-0 border-b'
            }
            key={index}>
            <div className="flex sm:max-w-[718px]">
              <div className="flex sm:gap-2 gap-1">
                <div>
                  <label className="text-16px block sm:hidden pb-1">ราคาเริ่มต้น</label>
                  <div className="flex w-full flex-1">
                    <Input
                      name={`conditionList.${index}.priceStart`}
                      type="text"
                      value={item.priceStart}
                      placeholder="กรอกจำนวนเงิน"
                      textLabelTopInput="ลิมิต"
                      showTextLabelTopInput={false}
                      classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
                      inputClassName="bg-white sm:w-[131px] w-full sm:h-[40px] placeholder-text-1 text-right border border-border-0 h-8 text-text-2 py-3 px-2.5 rounded-lg"
                      divClassName="flex flex-col w-full"
                      disabled={false}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      error={
                        props.errors &&
                        props.errors.conditionList &&
                        props.errors.conditionList[index] &&
                        props.errors.conditionList[index].priceStart &&
                        props.touched &&
                        props.touched.conditionList &&
                        props.touched.conditionList[index] &&
                        props.touched.conditionList[index].priceStart && props.errors.conditionList[index].priceStart
                      }
                    />
                  </div>
                </div>
                <div>
                  <label className="text-16px block sm:hidden pb-1">ราคาเริ่มต้น</label>
                  <div className="flex w-full flex-1">
                    <Input
                      name={`conditionList.${index}.priceEnd`}
                      type="text"
                      value={item.priceEnd}
                      placeholder="กรอกจำนวนเงิน"
                      textLabelTopInput="ลิมิต"
                      showTextLabelTopInput={false}
                      classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
                      inputClassName="bg-white sm:w-[131px] w-full sm:h-[40px] placeholder-text-1 text-right border border-border-0 h-8 text-text-2 py-3 px-2.5 rounded-lg"
                      divClassName="flex flex-col w-full"
                      disabled={false}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      error={
                        props.errors &&
                        props.errors.conditionList &&
                        props.errors.conditionList[index] &&
                        props.errors.conditionList[index].priceEnd &&
                        props.touched &&
                        props.touched.conditionList &&
                        props.touched.conditionList[index] &&
                        props.touched.conditionList[index].priceEnd && props.errors.conditionList[index].priceEnd
                      }
                      />
                  </div>
                </div>
                <div className="sm:block hidden">
                {renderSelectBank(props ,item, index)}
                </div>
              </div>
              <div className="flex sm:block sm:items-baseline sm:mb-0 items-end mb-0.5">
                { props.values.conditionList.length > 1 &&
                <div className="sm:w-10 sm:h-10 w-8 h-8 flex items-center mx-2">
                  <button
                    type="button"
                    className="sm:p-2.5 p-1.5 group"
                    onClick={() => removeRow(props as any, index as number)}
                  >
                    <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="30" height="30" rx="5" stroke="#D83434" strokeWidth="2"/>
                      <rect x="6" y="14" width="20" height="4" fill="#D83434"/>
                    </svg>
                    <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="30" height="30" rx="5" stroke="#B00909" strokeWidth="2"/>
                      <rect x="6" y="14" width="20" height="4" fill="#B00909"/>
                    </svg>
                  </button>
                </div>
                }
              </div>
              { index +1 === props.values.conditionList.length &&
                <div className={props.values.conditionList.length < 3 ? 'sm:w-10 sm:h-10 sm:flex items-center hidden' : 'hidden'}>
                  <button
                    type="button"
                    className="p-2.5"
                    onClick={() => addRow(props)}>
                      <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="30" height="30" rx="5" stroke="#3473D8" strokeWidth="2"/>
                        <rect x="6" y="14" width="20" height="4" fill="#3473D8"/>
                        <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#3473D8"/>
                      </svg>
                      <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="30" height="30" rx="5" stroke="#0944DD" strokeWidth="2"/>
                        <rect x="6" y="14" width="20" height="4" fill="#0944DD"/>
                        <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#0944DD"/>
                      </svg>
                  </button>
                </div>
              }
            </div>
            <div className="sm:hidden block mt-3">
              {renderSelectBank(props ,item, index)}
            </div>
          </div>
      )
    })
  }
  const removeRow = (props: any, index: number) => {
    const data = props.values.conditionList
    data.splice(index, 1)
    props.setValues({
      isBotActive: props.values.isBotActive,
      conditionList: data
    })
  }
  const addRow = (props: any) => {
    if (props.values.conditionList.length < 3){
      props.setValues({
        isBotActive: props.values.isBotActive,
        conditionList: [...props.values.conditionList, {
          bankAccount: '',
          bankId: '',
          isActive: true,
          priceEnd: '',
          priceStart: ''
        }]
      })
    }
  }
  const validationSchema = Yup.object().shape({
    conditionList: Yup.array().of(
      Yup.object().shape({
        priceStart: Yup.number()
          .typeError('ตัวเลขเท่านั้น')
          .moreThan(0,'ต้องใส่จำนวนมากกว่า 0')
          .lessThan(Yup.ref('priceEnd'),'ต้องราคาน้อยกว่าค่าสิ้นสุด')
          .required('จำเป็น'),
        priceEnd: Yup.number()
          .typeError('ตัวเลขเท่านั้น')
          .moreThan(Yup.ref('priceStart'),'ต้องราคามากกว่าค่าเริ่มต้น')
          .required('จำเป็น'),
        bankAccount: Yup.string().required('จำเป็น')
      })
    )
  })
  const addIdForCondiditonList = (conditionList: IBetConditionList[]): IBetConditionList[] => {
    let indexId = 1
    return conditionList.reduce((array: IBetConditionList[], current) => {
      array.push({
        id: Number(indexId),
        priceEnd: Number(current.priceEnd),
        priceStart: Number(current.priceStart),
        bankAccount: current.bankAccount,
        isActive: true,
        bankId: current.bankId
      })
      indexId++
      return array
    }, [])
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const checkPriceLessThanMore = (listPrice: IBetConditionList[]): any => {
    const result = { status: false, line: '' }
    if (listPrice.length >= 2) {
      for (let index = 0; index < listPrice.length; index++) {
        if (index > 0) {
          if (Number(listPrice[index].priceStart) > Number(listPrice[index-1].priceStart) &&
              Number(listPrice[index].priceStart) > Number(listPrice[index-1].priceEnd) &&
              Number(listPrice[index].priceEnd) > Number(listPrice[index-1].priceStart) &&
              Number(listPrice[index].priceEnd) > Number(listPrice[index-1].priceEnd)
            ) {
            result.status = true
          } else {
            result.status = false
            result.line = String(index +1)
            break
          }
        }
      }
    }
    return result
  }
  return (
    <div>
      <BreadCrump headTitle="ธนาคาร" showIcon={true} title={MENU_TITLE.BOT_WITHDRAW} />
      <div className="w-full scroll scrollbar-hide pt-6">
        <Formik
          initialValues={{
            isBotActive: active ? active : false,
            conditionList: dataList ? dataList : []
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const checkListPrice = checkPriceLessThanMore(values.conditionList)
            if (checkListPrice.status) {
              try {
                await botSettingApi.saveBotSetting({
                  conditionList: addIdForCondiditonList(values.conditionList),
                  isBotActive: values.isBotActive
                })
                toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
              } catch (e) {
                toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
              }
            } else {
              toastActive({state: 'error', description: `${'เกิดข้อผิดพลาด ข้อมูลบรรทัดที่ ' + checkListPrice.line + ' มากกว่าหรือน้อยกว่า กรุณาแก้ไขข้อมูล'}`, duration: 500})
            }
          }}>
            { props => (
              <form onSubmit={props.handleSubmit}>
                <div className="flex justify-between items-center">
                  <div>
                    {renderSwitch(props as object)}
                    <label
                      className="text-16px text-color-1">
                      {props.values.isBotActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
                    </label>
                  </div>
                  <div className={props.values.conditionList.length < 3 ? 'flex items-center w-8 h-8 sm:hidden' : 'hidden'}>
                    <button
                      type="button"
                      className="group"
                      onClick={() => addRow(props)}>
                        <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#3473D8" strokeWidth="2"/>
                          <rect x="6" y="14" width="20" height="4" fill="#3473D8"/>
                          <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#3473D8"/>
                        </svg>
                        <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="1" y="1" width="30" height="30" rx="5" stroke="#0944DD" strokeWidth="2"/>
                          <rect x="6" y="14" width="20" height="4" fill="#0944DD"/>
                          <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#0944DD"/>
                        </svg>
                    </button>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="pb-2.5 sm:flex hidden gap-3">
                    <div className="sm:w-[128px] text-16px">ราคาเริ่มต้น</div>
                    <div className="sm:w-[128px] text-16px">ราคาสิ้นสุด</div>
                    <div className="sm:w-[128px] text-16px">ธนาคาร</div>
                  </div>
                  {renderRow(props as object)}
                </div>
                <div className="pt-3">
                  <Button
                    buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
                    buttonText="บันทึก"
                    type="submit"
                  />
                </div>
              </form>
            )
          }
        </Formik>
      </div>
    </div>
  )
}

export default withLayout(botWithdraw, WEB_TITLE.BOT, WEB_TITLE.BOT)