import Login from '../pages/Login/index'
import Dashboard from '../pages/Dashboard/index'
import HomePageRun from '../pages/Settings/Homepage/index'
import LineNotification from '../pages/LineNotification'
import PaymentSwitch from '../pages/Settings/PaymentTransaction/paymentSwitch'
import BotWithdraw from '../pages/Settings/PaymentTransaction/botWithdraw'
import BotDeposit from '../pages/Settings/PaymentTransaction/botDeposit'
import Blog from '../pages/Blog/index'
import BlogCreate from '../pages/Blog/create'
import BlogEdit from '../pages/Blog/edit'
import GameLate from '../pages/Game/Late/index'
import GameLimit from '../pages/Game/Limit/index'
import GameLateSystem from '../pages/Game/System/index'
import GameRound from '../pages/Game/Round/index'
import GameRoundCreate from '../pages/Game/Round/create'
import GameRoundEdit from '../pages/Game/Round/edit'
import GameYeeKeeSetting from '../pages/Game/Yeegee/index'
import GameSetSetting from '../pages/Game/GameSet/index'
import User from '../pages/User/Member/index'
import UserFinanceHistory from '../pages/User/financeHistory'
import Admin from '../pages/User/Admin/index'
import EditAdmin from '../pages/User/Admin/edit'
import EditUser from '../pages/User/Member/edit'
import LimitLateUser from '../pages/User/limitLateUser'
import UserHistory from '../pages/User/userHistory'
import TransactionHistoryBetList from '../pages/User/TransactionDetail/index'
import TransactionBetGroup from '../pages/User/TransactionDetail/detail'
import BankGroupList from '../pages/Bank/Group/list'
import BankGroupCreate from '../pages/Bank/Group/create'
import BankGroupEdit from '../pages/Bank/Group/edit'
import BankList from '../pages/Bank/Admin/list'
import BankCreate from '../pages/Bank/Admin/create'
import BankEdit from '../pages/Bank/Admin/edit'
import BankTranfer from '../pages/Bank/Tranfer/index'
import Deposit from '../pages/Deposit/index'
import Withdraw from '../pages/Withdraw/index'
import GamePanel from '../pages/GamePanel/index'
import GamePanelListItems from '../pages/GamePanel/ListNumber/index'
import GamePanelCalculateBet from '../pages/GamePanel/CalculateBet/index'
import GamePanelResultTotalBet from '../pages/GamePanel/ResultTotalBet/index'
import NumberPrimeResult from '../pages/GamePanel/ResultAll/numberPrimeResult'
import NumberAllResult from '../pages/GamePanel/ResultAll/numberAllResult.tsx'
import { AuthGuard } from './guard'
import { Route, useLocation } from 'react-router-dom'
import WEB_PATHS from '../constant/webPath'
import WEB_TITLE from '../constant/webTitle'
import Overview from '../pages/Overview'
import Affiliate from '../pages/User/Affiliate'
import bankTempHistory from '../pages/Deposit/bankTempHistory'
import WithdrawAuto from '../pages/WithdrawAuto'

const routes = [
  {
    public: false,
    mainName: WEB_TITLE.DASHBOARD,
    name: WEB_TITLE.DASHBOARD,
    path: WEB_PATHS.DASHBOARD,
    component: Dashboard
  },
  {
    public: false,
    mainName: WEB_TITLE.OVERVIEW,
    name: WEB_TITLE.OVERVIEW,
    path: WEB_PATHS.OVERVIEW,
    component: Overview
  },
  {
    public: false,
    mainName: WEB_TITLE.HOME,
    name: WEB_TITLE.SETTINGS,
    path: WEB_PATHS.SETTING_HOMEPAGE,
    component: HomePageRun
  },
  {
    public: false,
    mainName: WEB_TITLE.LINE,
    name: WEB_TITLE.LINE,
    path: WEB_PATHS.SETTING_LINE_NOTIFICATION,
    component: LineNotification
  },
  {
    public: false,
    mainName: WEB_TITLE.PAYMENT_TRANSACTION,
    name: WEB_TITLE.PAYMENT_TRANSACTION,
    path: WEB_PATHS.SETTING_PAYMENT,
    component: PaymentSwitch
  },
  {
    public: false,
    mainName: WEB_TITLE.BOT,
    name: WEB_TITLE.BOT,
    path: WEB_PATHS.SETTING_BOT_DEPOSIT,
    component: BotDeposit
  },
  {
    public: false,
    mainName: WEB_TITLE.BOT,
    name: WEB_TITLE.BOT,
    path: WEB_PATHS.SETTING_BOT_WITHDRAW,
    component: BotWithdraw
  },
  {
    public: false,
    mainName: WEB_TITLE.BLOG,
    name: WEB_TITLE.BLOG,
    path: WEB_PATHS.BLOG_CREATE,
    component: BlogCreate
  },
  {
    public: false,
    mainName: WEB_TITLE.BLOG,
    name: WEB_TITLE.BLOG,
    path: WEB_PATHS.BLOG_EDIT,
    component: BlogEdit
  },
  {
    public: false,
    mainName: WEB_TITLE.BLOG,
    name: WEB_TITLE.BLOG,
    path: WEB_PATHS.BLOG_LIST,
    component: Blog
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: WEB_TITLE.GAME,
    path: WEB_PATHS.GAME_RATE_SYSTEM,
    component: GameLateSystem
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: 'GAME ROUND',
    path: WEB_PATHS.GAME_ROUND_CREATE,
    component: GameRoundCreate
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: 'GAME ROUND',
    path: WEB_PATHS.GAME_ROUND_EDIT,
    component: GameRoundEdit
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: 'GAME ROUND',
    path: WEB_PATHS.GAME_ROUND,
    component: GameRound
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: WEB_TITLE.GAME,
    path: WEB_PATHS.GAME_RATE,
    component: GameLate
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: WEB_TITLE.GAME,
    path: WEB_PATHS.GAME_YEEKEE,
    component: GameYeeKeeSetting
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME,
    name: WEB_TITLE.GAME,
    path: WEB_PATHS.GAME_SET,
    component: GameSetSetting
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME_SET,
    name: WEB_TITLE.GAME,
    path: WEB_PATHS.GAME_LIMIT,
    component: GameLimit
  },
  {
    public: false,
    mainName: 'User History',
    name: 'USER HISTORY',
    path: WEB_PATHS.USER_HISTORY,
    component: UserHistory
  },
  {
    public: false,
    mainName: 'User Transaction',
    name: 'USER TRANSACTION',
    path: WEB_PATHS.USER_TRANSACTION_BET_BY_ID,
    component: TransactionBetGroup
  },
  {
    public: false,
    mainName: 'User Transaction',
    name: 'USER TRANSACTION',
    path: WEB_PATHS.USER_TRANSACTION_BET,
    component: TransactionHistoryBetList
  },
  {
    public: false,
    mainName: 'User Affiliate',
    name: 'USER AFFILIATE',
    path: WEB_PATHS.USER_AFFILIATE,
    component: Affiliate
  },
  {
    public: false,
    mainName: 'Limit Rate User',
    name: 'LIMIT RATE USER',
    path: WEB_PATHS.USER_LIMIT_LATE,
    component: LimitLateUser
  },
  {
    public: false,
    mainName: 'User List',
    name: 'USER',
    path: WEB_PATHS.USER_FINANCE,
    component: UserFinanceHistory
  },
  {
    public: false,
    mainName: 'Edit User',
    name: 'EDIT USER',
    path: WEB_PATHS.USER_EDIT,
    component: EditUser
  },
  {
    public: false,
    mainName: 'User List',
    name: 'USER',
    path: WEB_PATHS.USER_LIST,
    component: User
  },
  {
    public: false,
    mainName: 'Edit Admin',
    name: 'EDIT ADMIN',
    path: WEB_PATHS.USER_ADMIN_ID,
    component: EditAdmin
  },
  {
    public: false,
    mainName: 'Admin List',
    name: 'ADMIN',
    path: WEB_PATHS.USER_ADMIN,
    component: Admin
  },
  {
    public: false,
    mainName: 'Bank Group List',
    name: 'BANK GROUP',
    path: WEB_PATHS.BANK_GROUP_LIST,
    component: BankGroupList
  }, {
    public: false,
    mainName: 'Bank Group Create',
    name: 'BANK GROUP CREATE',
    path: WEB_PATHS.BANK_GROUP_CREATE,
    component: BankGroupCreate
  }, {
    public: false,
    mainName: 'Bank Group',
    name: 'BANK GROUP',
    path: WEB_PATHS.BANK_GROUP_EDIT,
    component: BankGroupEdit
  },
  {
    public: false,
    mainName: 'Bank List',
    name: 'BANK LIST',
    path: WEB_PATHS.BANK_LIST,
    component: BankList
  },
  {
    public: false,
    mainName: 'Bank Create',
    name: 'BANK CREATE',
    path: WEB_PATHS.BANK_CREATE,
    component: BankCreate
  },
  {
    public: false,
    mainName: 'Bank Edit',
    name: 'BANK EDIT',
    path: WEB_PATHS.BANK_EDIT,
    component: BankEdit
  },
  {
    public: false,
    mainName: 'Bank Tranfer',
    name: 'BANK Tranfer',
    path: WEB_PATHS.BANK_TRANFER,
    component: BankTranfer
  },
  {
    public: false,
    mainName: 'Deposit',
    name: 'DEPOSIT',
    path: WEB_PATHS.DEPOSIT_TEMP,
    component: bankTempHistory
  },
  {
    public: false,
    mainName: 'Deposit',
    name: 'DEPOSIT',
    path: WEB_PATHS.DEPOSIT,
    component: Deposit
  },
  {
    public: false,
    mainName: 'Withdraw',
    name: 'WITHDRAW',
    path: WEB_PATHS.WITHDRAW,
    component: Withdraw
  },
  {
    public: false,
    mainName: 'Game Panel',
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL_LIST_ITEMS,
    component: GamePanelListItems
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME_PANEL,
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL_CALCULATE_BET,
    component: GamePanelCalculateBet
  },
  {
    public: false,
    mainName: 'Game Panel',
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL_PRIME_RESULT,
    component: NumberPrimeResult
  },
  {
    public: false,
    mainName: 'Game Panel',
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL_ALL_RESULT,
    component: NumberAllResult
  },
  {
    public: false,
    mainName: WEB_TITLE.GAME_PANEL,
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL_RESULT_TOTAL_BET,
    component: GamePanelResultTotalBet
  },
  {
    public: false,
    mainName: 'Game Panel',
    name: 'GAME PANEL',
    path: WEB_PATHS.GAME_PANEL,
    component: GamePanel
  },
  {
    public: false,
    mainName: WEB_TITLE.WITHDRAW_AUTO,
    name: 'GAME PANEL',
    path: WEB_PATHS.WITHDRAW_AUTO_SETTING,
    component: WithdrawAuto
  },
  {
    public: true,
    name: 'LOGIN',
    path: WEB_PATHS.LOGIN,
    component: Login
  }
]

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const RouteWithSubRoutes = ({...route}) => {
  const token = localStorage.getItem('accessToken')
  const query = useQuery()
  if (route.public && !token) {
    return (
      <Route
        path={route.path}
        render={props => (
          <route.component
            {...props}
            name={route.name}
            mainName={route.mainName}
            routes={route.routes}
            query={query}
          />
        )}
      />
    )
  }else {
    return (
      <AuthGuard
        path={route.path}
        name={route.name}
        mainName={route.mainName}
        component={route.component}
        routes={route.routes}
        query={query}/>
    )
  }
}


export default function renderRoute (defaultRoute = routes) {
  return defaultRoute.map((route: any, i: number) => (
    <RouteWithSubRoutes key={i} {...route} />
  ))
} 
