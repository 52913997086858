import humps from 'humps'
// import PubSub from 'pubsub-js'

// step 3
export const serializeResponseInterceptor = [
  ({...response}): Promise<any> => {
    if (!response || !response.data) {
      throw response
    }
    const res: any = humps.camelizeKeys(response.data)
    if (res.code === '401') {
      PubSub.publish('REDIRECT_TO_LOGIN')
      throw res
    }
    return Promise.resolve({
      data: res.data,
      // count: res.count,
      // code: response.status,
      // perPage: res.perPage,
      // totalPage: res.totalPage,
      // totalRow: res.totalRow,
      // currentPage: res.currentPage
    })
  },
  (error: any): Promise<any> => {
    const e: any = humps.camelizeKeys(error.response.data)
    // if (e === undefined || e === null || e === '') {
    //   PubSub.publish('REDIRECT_TO_LOGIN')
    // }
    return Promise.reject(e)
  }
]
