import withLayout from '../../../layout/withLayout'
import WEB_TITLE from '../../../constant/webTitle'
import { useState, useEffect } from 'react'
import quickPanel from '../../../model/quickPanel'
import quickPanelApi from '../../../api/quickPanel.api'
import gameApi from '../../../api/game.api'
import IGameRateLimit from '../../../model/GameRateApi/IGameRateLimit'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BeradCrump from '../../../components/BreadCrump'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IGameLimitDetail from '../../../model/GameRateApi/IGameLimitDetail'

const GameRateSystem = (): React.ReactElement => {
  const [gameRateSystemSelect, setGameRateSystemSelect] = useState<number>(0)
  const [quickPanel, setQuickPanel] = useState<quickPanel[]>([])
  const schema = Yup.object().shape({
    govLottoCateType: Yup.array().of(
      Yup.object().shape({
        limit: Yup.string()
          .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
          .required('required')
      })
    )
  })
  const [gameSystemRate, setGameSystemRate] = useState<IGameRateLimit>({
    id: 0,
    name: '',
    description: '',
    govLottoCateType: []
  })
  const fetchQuickPanel = async (): Promise<void> => {
    const response = await quickPanelApi.getQuickPanel()
    setQuickPanel(response)
  }
  const fetchGameRateSystem = async (id: string): Promise<void> => {
    const response = await gameApi.getGameRateSystemSetting(id)
    setGameSystemRate(response)
  }
  useEffect (() => {
    fetchQuickPanel()
  }, [])
  useEffect(() => {
    if (gameRateSystemSelect > 0) fetchGameRateSystem(String(gameRateSystemSelect))
  }, [gameRateSystemSelect])
  const onChangeSelect = (e: any): void => {
    setGameRateSystemSelect(e.target.value)
  }
  const renderSelectGameType = (): React.ReactNode => {
    if (quickPanel.length < 1) return
    return (
      <select
        name="gameTypeSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
        onChange={onChangeSelect}
        value={gameRateSystemSelect}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          quickPanel.map((item, index) => {
            return (
              <option
                key={index}
                value={item.gameCateId}>
                  {item.name}
              </option>
            )
          })
        }
      </select>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderRow = (props: any): React.ReactNode => {
    return props.values.govLottoCateType.map((lotto: IGameLimitDetail, index: number) => {
      return (
        <div key={index} className="flex w-full pb-3 gap-3">
          <Input
            name={`govLottoCateType.${index}.name`}
            type="text"
            value={lotto.name}
            placeholder=""
            textLabelTopInput="หัวข้อ"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
          <Input
            name={`govLottoCateType.${index}.description`}
            type="text"
            value={lotto.description}
            placeholder=""
            textLabelTopInput="ประเภท"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
          <Input
            name={`govLottoCateType.${index}.limit`}
            type="text"
            value={lotto.limit}
            placeholder="กรอกจำนวนเงิน"
            textLabelTopInput="ลิมิต"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-left text-text-2 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[40%]"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.govLottoCateType &&
              props.errors.govLottoCateType[index] &&
              props.errors.govLottoCateType[index].limit &&
              props.touched &&
              props.touched.govLottoCateType &&
              props.touched.govLottoCateType[index] &&
              props.touched.govLottoCateType[index].limit && props.errors.govLottoCateType[index].limit
            }
          />
        </div>
      )
    })
  }
  return (
    <div>
      <BeradCrump headTitle={MENU_TITLE.GAME} showIcon={true} classTitle="font-katnitBold" title={MENU_TITLE.GAME_LIMIT_SYSTEM} />
      <div className="pt-6 pb-3 sm:w-2/4 w-full">
        {renderSelectGameType()}
      </div>
      {gameRateSystemSelect > 0 &&
        <Formik
        initialValues={{
          id: gameSystemRate.id,
          name: gameSystemRate.name,
          description: gameSystemRate.description,
          govLottoCateType: gameSystemRate.govLottoCateType
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values: any) => {
          const data = values.govLottoCateType.map((item: IGameLimitDetail) => {
            return {
              id: item.gameCateId,
              limit: Number(item.limit),
              gameCateType: item.type,
              gameCateId: Number(gameRateSystemSelect) }
          })
          try {
            await gameApi.updateGameRateSystemSetting(data)
            setGameRateSystemSelect(0)
            toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
          } catch (e) {
            toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
          }
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <div className="sm:w-3/4 w-full">
              <div className="pb-3 w-full sm:flex hidden gap-3 border border-b-border-0">
                <div className="sm:w-[30%] text-16px">หัวข้อ</div>
                <div className="sm:w-[30%] text-16px">ประเภท</div>
                <div className="sm:w-[40%] text-16px">ลิมิต</div>
              </div>
              <div className="pt-3">
                {renderRow(props as any)}
              </div>
            </div>
            <Button
              buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
              buttonText="บันทึก"
              type="submit"
            />
          </form>
        )}
        </Formik>
      }
    </div>
  )
}

export default withLayout(GameRateSystem,  WEB_TITLE.GAME, WEB_TITLE.GAME_RATE_SYSTEM)