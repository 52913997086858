export const MENU_TITLE = {
  'OVERVIEW': 'รายงานผลกำไร',
  'HOME_TEXT_RUN': 'TEXT วิ่งหน้า HOME',
  'LINE_NOTIFICATION': 'Line Notification',
  'PAYMENT_TRANSACTION': 'PAYMENT TRANSACTION',
  'BOT_DEPOSIT': 'เปิด-ปิดฝากอัตโนมัติ',
  'BOT_WITHDRAW': 'เปิด-ปิดถอนอัตโนมัติ',
  'AUTO_WITHDRAW': 'ตั้งค่าถอนอัตโนมัติ',
  'BLOG': 'บล็อค',
  'BLOG_NORMAL': 'บล็อคทั่วไป',
  'BLOG_CREATE': 'สร้างบล็อค',
  'BLOG_EDIT': 'แก้ไขบล็อค',
  'GAME': 'เกมส์',
  'GAME_LIMIT': 'เกมส์ ลิมิต',
  'GAME_LIMIT_SYSTEM': 'เกมส์ ลิมิตเริ่มต้น',
  'GAME_LIMIT_RATE': 'เกมส์เรท',
  'GAME_LIMIT_ROUND': 'เกมส์ ROUND',
  'GAME_LIMIT_ROUND_CREATE': 'เกมส์ ROUND',
  'GAME_LIMIT_ROUND_EDIT': 'เกมส์ ROUND',
  'GAME_YEEGEE': 'ตั้งค่าเกมส์ yeegee',
  'GAME_SET_SETTING': 'ตั้งค่าเกมส์หวยชุด',
  'BANK': 'ธนาคาร',
  'BANK_LIST': 'รายการบัญชีธนาคาร',
  'BANK_CREATE': 'เพิ่มบัญชีธนาคาร',
  'BANK_EDIT': 'แก้ไขข้อมูลบัญชีธนาคาร',
  'BANK_GROUP': 'กรุ๊ปธนาคาร',
  'BANK_GROUP_LIST': 'รายการกรุ๊ปบัญชีธนาคาร',
  'BANK_GROUP_CREATE': 'เพิ่มกรุ๊ปบัญชีธนาคาร',
  'BANK_GROUP_EDIT': 'แก้ไขข้อมูลกรุ๊ปบัญชีธนาคาร',
  'BANK_TRANFER': 'ธนาคารโอนเงินอัตโนมัติ',
  'DEPOSIT': 'ประวัติการฝาก',
  'WITHDRAW': 'ประวัติการถอน',
  'USER_LIST': 'สมาชิก',
  'ADMIN_LIST': 'ผู้ดูแลระบบ',
  'ADMIN_EDIT': 'แก้ไขผู้ดูแลระบบ',
  'USER_EDIT': 'แก้ไขสมาชิก',
  'USER_TRANSACTION_GAME': 'ประวัติการแทงเล่นเกม',
  'USER_TRANSACTION_HISTORY': 'ประวัติฝากถอน',
  'USER_FINANCE_HISTORY': 'รายการเดินบัญชี',
  'USER_LIMIT': 'ลิมิตการแทง',
  'USER_GAME_PLAY_HISTORY': 'ประวัติการเล่นเกม',
  'GAME_CLEAR': 'เคลียหวย',
  'GAME_ALL_RESULT': 'รายการแทงทั้งหมด',
  'GAME_PRIME_RESULT': 'รายการแทงเฉพาะถูกรางวัล',
  'GAME_NUMBER_SHOW': 'รายการแทงแบ่งตามประเลข'
}

export const BREAD_CRUMM = {
  'HOME_TEXT_RUN': 'TEXT วิ่งหน้า HOME',
  'PAYMENT_TRANSACTION': 'PAYMENT TRANSACTION',
  'BOT': 'BOT',
  'BLOG': 'บล็อค',
  'BLOG_CREATE': 'สร้างบล็อค',
  'BLOG_NORMAL': 'บล็อคทั่วไป',
  'BLOG_EDIT': 'แก้ไขบล็อค',
  'GAME': 'เกมส์',
  'GAME_LIMIT': 'เกมส์ ลิมิต',
  'GAME_LIMIT_SYSTEM': 'เกมส์ ลิมิตเริ่มต้น',
  'GAME_LIMIT_RATE': 'เกมส์เรท',
  'GAME_LIMIT_ROUND': 'เกมส์ ROUND',
  'GAME_LIMIT_ROUND_CREATE': 'เกมส์ ROUND',
  'GAME_LIMIT_ROUND_EDIT': 'เกมส์ ROUND',
  'BANK': 'ธนาคาร',
  'BANK_LIST': 'รายการบัญชีธนาคาร',
  'BANK_CREATE': 'เพิ่มบัญชีธนาคาร',
  'BANK_EDIT': 'แก้ไขข้อมูลบัญชีธนาคาร',
  'BANK_GROUP': 'กรุ๊ปธนาคาร',
  'BANK_GROUP_LIST': 'รายการกรุ๊ปบัญชีธนาคาร',
  'BANK_GROUP_CREATE': 'เพิ่มกรุ๊ปบัญชีธนาคาร',
  'BANK_GROUP_EDIT': 'แก้ไขข้อมูลกรุ๊ปบัญชีธนาคาร',
  'BANK_TRANFER': 'ธนาคารโอนเงินอัตโนมัติ',
  'DEPOSIT': 'ประวัติการแจ้งฝาก',
  'WITHDRAW': 'ประวัติการแจ้งถอน',
  'USER_LIST': 'สมาชิก',
  'ADMIN_LIST': 'ผู้ดูแลระบบ',
  'ADMIN_EDIT': 'แก้ไขผู้ดูแลระบบ',
  'USER_EDIT': 'แก้ไขสมาชิก',
  'USER_TRANSACTION_GAME': 'ประวัติการแทงเล่นเกม',
  'USER_TRANSACTION_HISTORY': 'ประวัติฝากถอน',
  'USER_FINANCE_HISTORY': 'รายการเดินบัญชี',
  'USER_LIMIT': 'ลิมิตการแทง',
  'USER_GAME_PLAY_HISTORY': 'ประวัติการเล่นเกม',
  'GAME_CLEAR': 'เคลียหวย',
  'GAME_ALL_RESULT': 'รายการแทงทั้งหมด',
  'GAME_PRIME_RESULT': 'รายการแทงเฉพาะถูกรางวัล',
  'GAME_NUMBER_SHOW': 'รายการแทงแบ่งตามประเลข'
}