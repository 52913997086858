import withLayout from '../../layout/withLayout'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import userApi from '../../api/user.api'
import { useState } from 'react'
import IUserHistory from '../../model/UserApi/IUserHistory'
import Paginations from '../../components/Paginations'
import WEB_PATHS from '../../constant/webPath'
import { MENU_TITLE } from '../../constant/menuTitle'
import TabMenu from './layout/index'
import { dateFormat } from '../../utils/dateFormat'
import ToastStateIcon from '../../components/ToastStateIcon'
import IToastState from '../../model/toastState'
import toast from 'react-hot-toast'

const UserHistory = (route: any): React.ReactElement => {
  const { id }: any = useParams()
  const [count, setCount] = useState<number>(0)
  const [historyData, setHistoryData] = useState<IUserHistory[]>([])
  const [amount, setAmount] = useState({
    deposit: 0,
    withdraw: 0
  })
  const history = useHistory()
  const queryFilter = {
    qs: route.query.query.get('qs') ? route.query.query.get('qs') : '',
    take: route.query.query.get('take') ? Number(route.query.query.get('take')) : 5,
    skip: route.query.query.get('skip') ? Number(route.query.query.get('skip')) : 0
  }
  const backPage = (): void => {
    history.push(WEB_PATHS.USER_EDIT.replace(':id', String(id)))
  }
  useEffect(() => {
    const fetchAmountHistory = async () => {
      try {
        const data = await userApi.useAmountHistory(id)
        setAmount({...data})
      } catch (e) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    }
    fetchAmountHistory()
  }, [])
  const fetchLimitLateDate = async (): Promise<void> => {
    try {
      const { data, filters } = await userApi.userHistory(id, queryFilter)
      setHistoryData(data)
      setCount(filters.count ? filters.count : 0)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
    }
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  useEffect(() => {
    fetchLimitLateDate()
  }, [route])
  const renderFilter = (): React.ReactElement => {
    return (
      <div className="flex w-fit flex-1 text-center pb-5 rounded-lg">
        <div
          onClick={(): void => setHistoryState('all', 5, 0)}
          className={queryFilter.qs === 'all'
            ? 'w-auto py-2 px-3 text-white bg-background-2 rounded-l-lg'
            : 'w-auto py-2 px-3 cursor-pointer bg-background-1 hover:bg-background-2 hover:text-white rounded-l-lg'
          }>
          ทั้งหมด
        </div>
        <div
          onClick={(): void => setHistoryState('deposit', 5, 0)}
          className={queryFilter.qs === 'deposit'
            ? 'w-auto py-2 px-3 text-white bg-background-2'
            : 'w-auto py-2 px-3 cursor-pointer bg-background-1 hover:bg-background-2 hover:text-white'
          }>
          ฝาก
        </div>
        <div
          onClick={(): void => setHistoryState('withdraw', 5, 0)}
          className={queryFilter.qs === 'withdraw'
            ? 'w-auto py-2 px-3 text-white bg-background-2 rounded-r-lg'
            : 'w-auto py-2 px-3 cursor-pointer bg-background-1 hover:bg-background-2 hover:text-white rounded-r-lg'
          }>
          ถอน
        </div>
      </div>
    )
  }
  const setHistoryState = (state: string, take: number, skip: number): void => {
    if (state !== queryFilter.qs) {
      history.replace({
        pathname: WEB_PATHS.USER_HISTORY.replace(':id', String(id)),
        search: `?qs=${state}&skip${skip}&take${take}`
      })
    }
  }
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-fit pb-2.5">
        <div className="flex px-2 items-center overflow-auto scrollbar-hide w-full">
          <div className="sm:w-[5%] w-[30px]">#</div>
          <div className="sm:w-[15%] w-[150px] text-center">ชื่อผู้ใช้งาน</div>
          <div className="sm:w-[20%] w-[220px] text-center">บัญชีธนาคาร</div>
          <div className="sm:w-[20%] w-[220px] text-center">เลขบัญชีธนาคาร</div>
          <div className="sm:w-[15%] w-[200px] text-center px-2.5">จำนวนเงิน</div>
          <div className="sm:w-[15%] w-[150px] text-center">สร้างเมื่อ</div>
          <div className="sm:w-[15%] w-[150px] text-center">อนุมัติเมื่อ</div>
        </div>
        {renderContent(historyData)}
      </div>
    )
  }
  const renderContent = (item: IUserHistory[]): React.ReactNode => {
    if (item.length <= 0) return <div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2 text-text-6">ไม่มีข้อมูล</div>
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={content.id}>
        <div className="sm:w-[5%] w-[30px] text-text-6">{index+1}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-center">{content.username ? content.username : '-'}</div>
        <div className="sm:w-[20%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-center">{content.bankName}</div>
        <div className="sm:w-[20%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-center">{content.bankAccount}</div>
        <div className="sm:w-[15%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-center px-2.5">{content.systemAmount.toLocaleString('th')} ฿</div>
        <div className="sm:w-[15%] w-[150px] text-16px text-text-6 text-center">{dateFormat(content.createdAt, 'dd-MM-yyyy HH:mm')}</div>
        <div className="sm:w-[15%] w-[150px] text-16px text-text-6 text-center">{dateFormat(content.transferredAt, 'dd-MM-yyyy HH:mm')}</div>
      </div>
      )
    })
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.USER_TRANSACTION_HISTORY}</span>
      </div>
      <div>
        <TabMenu state={MENU_TITLE.USER_TRANSACTION_HISTORY} id={id} />
      </div>
      <div className="flex flex-1 sm:w-2/4 w-full sm:flex-row flex-col gap-3 pt-3 pb-5">
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">ยอดฝาก</span>
          <span className="text-text-0">{amount.deposit.toLocaleString('th')} THB</span>
        </div>
        <div className="py-2 px-3 flex justify-between sm:w-full w-full bg-white rounded-lg">
          <span className="text-text-2">ยอดถอน</span>
          <span className="text-text-0">{amount.withdraw.toLocaleString('th')} THB</span>
        </div>
      </div>
      {renderFilter()}
      <div className="sm:w-full w-full min-w-full overflow-x-scroll scrollbar-hide">
        {renderTable()}
      </div>
      <Paginations
        filter={queryFilter}
        count={count}
        divClassName="justify-end mt-5 text-2xl"
      />
    </div>
  )
}

export default withLayout(UserHistory, 'User')