import withLayout from "../../layout/withLayout"
import BeradCrump from "../../components/BreadCrump"
import { MENU_TITLE } from "../../constant/menuTitle"
import quickPanelApi from '../../api/quickPanel.api'
// import IQuickPanel from '../../model/quickPanel'
import { useEffect, useState } from "react"
import IGameRound from '../../model/GamePanelApi/IGameRound'
import IGameResultTotalBet from "../../model/GamePanelApi/IGameResultTotalBet"
import { dateFormat } from "../../utils/dateFormat"

const Overview = (): React.ReactElement => {
  // const [gameRateSelect, setGameRateSelect] = useState<number>(1)
  const [selectRound, setSelectRound] = useState(0)
  const [roundBet, setRoundBet] = useState<IGameRound[]>([])
  const [resultTotalBet, setResultTotalBet] = useState<IGameResultTotalBet>({
    lottoIncome: 0,
    rockPaperScissorsIncome: 0,
    affiliate: 0,
    grandTotal: 0,
    percent: 0,
    incomeTable: []
  })
  // const fetchQuickPanel = async (): Promise<void> => {
  //   const response = await quickPanelApi.getQuickPanel()
  //   setQuickPanel(response)
  // }
  // useEffect(() => {
  //   fetchQuickPanel()
  // }, [])
  // const onChangeSelect = (e: any): void => {
  //   setGameRateSelect(e.target.value)
  // }
  const fetchRoundGameById = async (): Promise<void> => {
    const response = await quickPanelApi.getRoundGameById(1)
    setRoundBet(response)
  }
  useEffect(() => {
    fetchRoundGameById()
  }, [])
  useEffect(() => {
    if(selectRound > 0) {
      fetchResultTotalBet()
    }
  },[selectRound])
  const fetchResultTotalBet = async (): Promise<void> => {
    const response = await quickPanelApi.getResultTotalBetById(selectRound)
    setResultTotalBet(response)
  }
  const renderSelectRound = (): React.ReactNode => {
    return (
      <select
        name="gameTypeSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
        onChange={(e): void => setSelectRound(Number(e.target.value))}
        value={selectRound}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          roundBet.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}>
                  Round : { dateFormat(item.endDate, 'dd-MM-yyyy')}
              </option>
            )
          })
        }
      </select>
    )
  }
  // const [quickPanel, setQuickPanel] = useState<IQuickPanel[]>([])
  // const renderSelect = (): React.ReactNode => {
  //   if (quickPanel.length < 1) return
  //   return (
  //     <select
  //       name="typeGameSelect"
  //       className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
  //       onChange={onChangeSelect}
  //       value={gameRateSelect}
  //     >
  //       <option value="0">กรุณาเลือกประเภทหวย</option>
  //       {quickPanel.map((type, index) => {
  //         return (
  //         <option
  //           key={index} value={type.gameCateId}
  //         >
  //           {type.name}
  //         </option>)
  //       })}
  //     </select>
  //   )
  // }
  const renderOverView = (): React.ReactNode => {
    if(selectRound <= 0) return
    return (
      <div>
        <div className="w-full sm:w-2/4 pt-6">
          <div className="flex justify-between w-full pb-3">
            <span className="text-16px text-text-2">รายได้จากหวย</span>
            <span className="text-16px text-text-3">{resultTotalBet.lottoIncome.toLocaleString('th')} THB</span>
          </div>
          <div className="flex justify-between w-full pb-3">
            <span className="text-16px text-text-2">รายได้จากเป่ายิ้งฉุบ</span>
            <span className="text-16px text-text-3">{resultTotalBet.rockPaperScissorsIncome.toLocaleString('th')} THB</span>
          </div>
          <div className="flex justify-between w-full pb-3">
            <span className="text-16px text-text-2">รายได้จากลิ้งค์ช่วยแชร์</span>
            <span className="text-16px text-text-3">{resultTotalBet.affiliate.toLocaleString('th')} THB</span>
          </div>
          <div className="flex justify-between w-full pb-3">
            <span className="text-16px text-text-2">รายได้รวม</span>
            <span className="text-16px text-text-3">{resultTotalBet.grandTotal.toLocaleString('th')} THB</span>
          </div>
          <div className="flex justify-between w-full pb-3">
            <span className="text-16px text-text-2">คิดจากเปอเซ็น</span>
            <span className="text-16px text-text-3">{resultTotalBet.percent.toLocaleString('th')} THB</span>
          </div>
        </div>
        <div className="flex sm:w-2/4 w-full pt-2">
          <div className="flex w-full flex-col">
            <div className="flex flex-1 text-center px-2.5">
              <div className="w-full text-left">
                ประเภท
              </div>
              <div className="w-full text-right">
                รายได้
              </div>
            </div>
            {renderDetailTable(resultTotalBet.incomeTable)}
          </div>
        </div>
      </div>
    )
  }
  const renderDetailTable = (item: any): React.ReactElement => {
    return item.map((bet: any, index: number) => {
      return (
        <div key={index} className={
          index === 0
          ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2.5 w-full'
          : index % 2 !== 0
          ? 'flex items-center bg-background-12 py-1.5 px-2.5 w-full'
          : 'flex items-center bg-white py-1.5 px-2.5 w-full'
        }>
          <div className="w-full text-left">
            {bet.gameCateName}
          </div>
          <div className="w-full text-right">
            {bet.income.toLocaleString('th')} THB
          </div>
        </div>
      )
    })
  }
  return (
    <div>
      <BeradCrump headTitle="" showIcon={false} classTitle="font-katnitBold" title={MENU_TITLE.OVERVIEW} />
      {/* <div className="pt-6 pb-0.5 sm:w-2/4 w-full">
        {renderSelect()}
      </div> */}
      <div className="pt-3 pb-1 sm:w-2/4 w-full">
        {renderSelectRound()}
      </div>
      {renderOverView()}
    </div>
  )
}

export default withLayout(Overview, 'OVERVIEW')