import withLayout from '../../layout/withLayout'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router'
import IGameResults from '../../model/IGameResults'
import IGameDetails from '../../model/IGameDetails'
import quickPanelApi from '../../api/quickPanel.api'
import WEB_PATHS from '../../constant/webPath'
import IGameRound from '../../model/GamePanelApi/IGameRound'
import gameApi from '../../api/game.api'
import typeGameTitle from '../../constant/typeGameTitle'
import { dateFormat } from '../../utils/dateFormat'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'

const GamePanel = () => {
  const { id }: any = useParams()
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [roundSelect, setRoundSelect] = useState(0)
  const [selectRound, setSelectRound] = useState(0)
  const [modalAlert, setModalAlert] = useState(false)
  const [roundBet, setRoundBet] = useState<IGameRound[]>([])
  const [roundSelectData, setRoundSelectData] = useState<IGameRound[]>([])
  const [totalGameDetails, setTotalGameDetails] = useState<IGameResults>({
    gameCateId: 0,
    userCount: 0,
    betAmount: 0,
    cancelAmount: 0,
    incomeToday: 0,
    betAwaiting: 0,
    gainLose: 0,
    gainLoseAll: 0
  })
  const [total, setTotal] = useState<IGameDetails[]>([])
  useEffect(() => {
    (async function fetchData(): Promise<void> {
      if (selectRound > 0) {
        try {
          const response = await quickPanelApi.getPanelResult(id, String(selectRound))
          const detail = await quickPanelApi.getPanelDetail(id, String(selectRound))
          setTotalGameDetails({...response})
          setTotal(detail)
        } catch (error) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
        }
      }
    })()
  }, [selectRound])
  const fetchRoundGameById = async (): Promise<void> => {
    try {
      const response = await quickPanelApi.getAllRoundGameById(id)
      setRoundBet(response)
      setSelectRound(response.at(-1).id)
    } catch (error) {
      toastActive({state: 'success', description: 'เกิดข้อผิดพลาด ไม่สามารถเรียกเกม round ได้', duration: 500})
    }
  }
  useEffect(() => {
    fetchRoundGameById()
  }, [id])
  const renderDetailTotal = (): React.ReactNode  => {
    return (
      <div>
        <div className="pt-2 flex gap-2.5 flex-wrap">
          <div className="flex bg-white p-2.5 rounded-md w-fit">
            <span className="min-w-[140px]">เดิมพันทั้งหมด(บาท)</span>
            <span className="min-w-[140px] text-right">
              {(totalGameDetails.gainLoseAll + totalGameDetails.gainLose).toLocaleString('th')} THB
            </span>
          </div>
          <div className="flex bg-white p-2.5 rounded-md w-fit">
            <span className="min-w-[140px]">ยกเลิกโพย(บาท)</span>
            <span className="min-w-[140px] text-right">
              {totalGameDetails.cancelAmount.toLocaleString('th')} THB
            </span>
          </div>
          <div className="flex bg-white p-2.5 rounded-md w-fit">
            <span className="min-w-[140px]">จำนวนคนแทง(คน)</span>
            <span className="min-w-[140px] text-right">
              {totalGameDetails.userCount.toLocaleString('th')} คน
            </span>
          </div>
          <div className="flex bg-white p-2.5 rounded-md w-fit">
            <span className="min-w-[140px]">เดิมพันรวม(บาท)</span>
            <span className="min-w-[140px] text-right">
              {totalGameDetails.gainLose.toLocaleString('th')} THB
            </span>
          </div>
        </div>
      </div>
    )
  }
  const cancelPollSubmit = async (): Promise<void> => {
    try {
      await quickPanelApi.cancelGamePoll(id)
      toastActive({state: 'success', description: 'ยกเลิกโพยสำเร็จ', duration: 500})
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถยกเลิกโพยได้', duration: 500})
    }
  }
  useEffect(() => {
    const fetchRoundData = async (): Promise<void> => {
      if (isOpenModal){
        try {
          const response = await gameApi.getRoundGameById(id)
          setRoundSelectData(response)
        } catch (error) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
        }
      }
    }
    isOpenModal ? fetchRoundData() : ''
  },[isOpenModal])
  const reCalculate = async (): Promise<void> => {
    try {
      const data =  {
        gameCateId: Number(id),
        gameRound: String(roundSelect)
      }
      await gameApi.postGameReCalculate(data)
      toastActive({state: 'success', description: 'คำนวนใหม่สำเร็จ', duration: 500})
      setRoundSelect(0)
      setRoundSelectData([])
      setIsOpenModal(false)
      setModalAlert(true)
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถคำนวนหวยใหม่ได้', duration: 500})
    }
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderButtonMenuFunction = () => {
    return (
      <div className="sm:w-full w-full py-5 flex">
        <div className="flex sm:w-[100%] flex-1 gap-3 flex-wrap">
        {Number(id) !== 5 ?
          <div onClick={(): void => history.push({
              pathname: WEB_PATHS.GAME_PANEL_ALL_RESULT.replace(':id', String(id)),
              search: `qs=${String(selectRound)}`
            })} className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-10 hover:bg-background-11 py-2 px-8 cursor-pointer">
            ดูรายการแทงทั้งหมด
          </div>
          : null }
          {Number(id) !== 5 ?
          <div
            onClick={(): void => history.push({
              pathname: WEB_PATHS.GAME_PANEL_LIST_ITEMS.replace(':id', String(id)),
              search: `qs=${String(selectRound)}`
            })}
            className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-10 hover:bg-background-11 py-2 px-8 cursor-pointer">
            ดูรายการแทงแบ่งตามเลข
          </div>
          : null }
          {Number(id) !== 5 ?
          <div onClick={(): void => history.push({
              pathname: WEB_PATHS.GAME_PANEL_PRIME_RESULT.replace(':id', String(id)),
              search: `qs=${String(selectRound)}`
            })} className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-10 hover:bg-background-11 py-2 px-8 cursor-pointer">
            ดูรายการแทงเฉพาะถูกรางวัล
          </div>
          : null }
          {Number(id) !== 5 ?
          <div
            onClick={(): any => history.push({
              pathname: WEB_PATHS.GAME_PANEL_CALCULATE_BET.replace(':id', String(id)),
            })}
            className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-10 hover:bg-background-11 py-2 px-8 cursor-pointer"
            >
            คำนวนหวย
          </div>
          : null }
          <div className="flex sm:w-full gap-3">
            <div
              onClick={(): Promise<void> => cancelPollSubmit()}
              className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-13 hover:bg-background-14 py-2 px-8 cursor-pointer"
              >
              ยกเลิกโพย
            </div>
            {Number(id) !== 5 ?
            <div
              onClick={(): void => setIsOpenModal(!isOpenModal)}
              className="w-fit whitespace-nowrap text-center rounded-lg text-white bg-background-13 hover:bg-background-14 py-2 px-8 cursor-pointer"
            >
              คำนวนหวยใหม่
            </div>
            : null }
          </div>
        </div>
      </div>
    )
  }
  const renderModalReCalculate = (): React.ReactElement => {
    return (
      <div className="fixed z-10 inset-0">
        <div className="flex justify-center">
          <div onClick={(): void => setIsOpenModal(!isOpenModal)} className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" aria-hidden="true"></div>
          <div className="w-80 inline-block p-4 align-bottom bg-white rounded-lg text-left overflow-hidden scrollbar-hide shadow-xl transform transition-all sm:my-36 mt-36 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="w-full flex justify-between pb-5 items-center">
              <div className="text-20px">คำนวนหวยใหม่</div>
              <div>
                <svg className="cursor-pointer hover:opacity-75" onClick={(): void => setIsOpenModal(!isOpenModal)} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 11.266C14 11.5802 13.89 11.8474 13.67 12.0673L12.0673 13.67C11.8474 13.89 11.5802 14 11.266 14C10.9517 14 10.6846 13.89 10.4646 13.67L7 10.2054L3.53535 13.67C3.31538 13.89 3.04826 14 2.73401 14C2.41975 14 2.15264 13.89 1.93266 13.67L0.329966 12.0673C0.109989 11.8474 0 11.5802 0 11.266C0 10.9517 0.109989 10.6846 0.329966 10.4646L3.79461 7L0.329966 3.53535C0.109989 3.31538 0 3.04826 0 2.73401C0 2.41975 0.109989 2.15264 0.329966 1.93266L1.93266 0.329966C2.15264 0.109989 2.41975 0 2.73401 0C3.04826 0 3.31538 0.109989 3.53535 0.329966L7 3.79461L10.4646 0.329966C10.6846 0.109989 10.9517 0 11.266 0C11.5802 0 11.8474 0.109989 12.0673 0.329966L13.67 1.93266C13.89 2.15264 14 2.41975 14 2.73401C14 3.04826 13.89 3.31538 13.67 3.53535L10.2054 7L13.67 10.4646C13.89 10.6846 14 10.9517 14 11.266Z" fill="#1E2339"/>
                </svg>
              </div>
            </div>
            <div className="bg-white">
              {renderRoundSelect()}
            </div>
            <div className="w-full pt-3">
              <button
                onClick={(): void => {
                  if (roundSelect > 0) {
                    reCalculate()
                  }
                }}
                className={
                roundSelect > 0
                  ? 'className="px-2 py-3 w-full rounded-lg bg-background-2 hover:bg-background-3 text-white'
                  : 'hidden'
                }>
              คำนวนหวยใหม่
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderRoundSelect = (): any => {
    if (roundSelectData === undefined) return
    return (
      <select
        name="gameTypeSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
        onChange={(e): void => setRoundSelect(Number(e.target.value))}
        value={roundSelect}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          roundSelectData.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}>
                  งวด : {dateFormat(item.startDate, 'dd-MM-yyyy')} - {dateFormat(item.endDate, 'dd-MM-yyyy')}
              </option>
            )
          })
        }
      </select>
    )
  }
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-fit pb-2.5">
        <div className="flex px-2 items-center overflow-auto scrollbar-hide w-full">
          <div className="sm:w-[5%] w-[30px]">#</div>
          <div className="sm:w-[20%] w-[150px] text-left">บัญชีผู้ใช้</div>
          <div className="sm:w-[15%] w-[220px] text-center">ประเภทเกม</div>
          <div className="sm:w-[20%] w-[220px] text-right">เลขที่เล่น</div>
          <div className="sm:w-[15%] w-[200px] text-right px-2.5">จำนวนเงิน</div>
          <div className="sm:w-[15%] w-[150px] text-center">วันที่เล่น</div>
          <div className="sm:w-[15%] w-[150px] text-center">สถานะ</div>
        </div>
        {renderContent(total)}
      </div>
    )
  }
  const renderContent = (item: IGameDetails[]): React.ReactNode => {
    if (item.length <= 0) return <div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2">ไม่มีข้อมูล</div>
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={index}>
        <div className="sm:w-[5%] w-[30px] text-text-6">{index+1}</div>
        <div className="sm:w-[20%] w-[150px] overflow-hidden truncate text-16px text-text-6">
          <span onClick={(): void => history.push({pathname: WEB_PATHS.USER_EDIT.replace(':id', String(content.userId))})} className="hover:cursor-pointer hover:underline hover:text-blue-600">{content.username ? content.username : '-'}</span>
        </div>
        <div className="sm:w-[15%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-center">{content.lottoGameType}</div>
        <div className="sm:w-[20%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-right">{content.playNumber}</div>
        <div className="sm:w-[15%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-right px-2.5">{content.playAmount ? content.playAmount.toLocaleString('th') : '-'}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-center">{dateFormat(content.transactionDate, 'dd-MM-yyyy HH:mm')}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-center">{content.statusString}</div>
      </div>
      )
    })
  }
  const renderSelectRound = (): React.ReactNode => {
    if (roundBet.length < 1) return
    return (
      <select
        name="gameTypeSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px sm:w-2/4 w-full rounded-lg bg-white"
        onChange={(e): void => {
          setSelectRound(Number(e.target.value))
        }}
        value={selectRound}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          roundBet.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}>
                  Round : { dateFormat(item.endDate, 'dd-MM-yyyy')}
                  {/* Round : {dateFormat(item.startDate, 'dd-MM-yyyy')} - {dateFormat(item.closeDate, 'dd-MM-yyyy')} */}
              </option>
            )
          })
        }
      </select>
    )
  }
  return (
    <div>
      <div className="text-20px pb-6 font-katnitBold">
        {typeGameTitle[Number(id)]}
      </div>
      {renderSelectRound()}
      {selectRound > 0 ? renderDetailTotal() : null}
      {selectRound > 0 ? renderButtonMenuFunction() : null}
      <div className="sm:w-full w-full min-w-full overflow-x-scroll scrollbar-hide">
      {selectRound > 0 ? renderTable() : null}
      </div>
      {isOpenModal &&
        renderModalReCalculate()
      }
      {modalAlert &&
        <div className="justify-center items-center bg-gray-100 bg-opacity-40 flex x-hidden y-auto fixed inset-0 z-10"
        >
          <div className="relative my-6 w-1/2 bg-white p-4 text-center rounded-lg shadow-lg">
            <div>Success</div>
            <div className="flex items-center justify-center h-16">
              คำนวนหวยสำเร็จ
            </div>
            <div className="flex justify-end items-end">
              <button
                className="border rounded-lg border-black cursor-pointer p-2 hover:bg-gray-300"
                onClick={(): void => setModalAlert(!modalAlert)}>Close</button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default withLayout(GamePanel, 'Game Panel')