import withLayout from "../../layout/withLayout"
import WEB_TITLE from '../../constant/webTitle'
import BeradCrump from "../../components/BreadCrump"
import { MENU_TITLE } from "../../constant/menuTitle"
import { useEffect, useState } from "react"
import withdrawApi from '../../api/withdraw.api'
import IWithdrawAutoHead from '../../model/WithdrawApi/IWithdrawAutoHeadList'
import ToastStateIcon from "../../components/ToastStateIcon"
import IToastState from "../../model/toastState"
import toast from "react-hot-toast"
import IWithdrawAutoHeadContent from '../../model/WithdrawApi/IWithdrawAutoHeadContent'
import { Formik } from 'formik'
import IWithdrawAutoBankList from '../../model/WithdrawApi/IWithdrawAutoBankList'
import IBankDeposit from "../../model/BankAdminApi/IBankDeposit"
import bankSelectAuto from "../../validation/bankSelectAuto"

const WithdrawAuto = (): React.ReactElement => {
  const [withdrawAutoHead, setWithdrawAutoHead] = useState<IWithdrawAutoHead[]>([])
  const [bankSelectList, setBankSelectList] = useState<IWithdrawAutoBankList[]>([])
  const [reloadElement, setReloadElement] = useState<boolean>(true)
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  useEffect(() => {
    fetchWithDrawAuto()
    fetchWithdrawAutoBankSelect()
  }, [])
  const fetchWithdrawAutoBankSelect = async (): Promise<void> =>{
    try {
      const response = await withdrawApi.withdrawAutoGetListBank()
      setBankSelectList(response)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลได้', duration: 500})
    }
  }
  const fetchWithDrawAuto = async (): Promise<void> => {
    try {
      const response = await withdrawApi.withdrawAutoGetHead()
      console.log(bankSelectList, 'bankSelectList')
      setWithdrawAutoHead(response)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลได้', duration: 500})
    }
  }
  const renderGroupBankAuto = (): React.ReactNode => {
    if (withdrawAutoHead.length <= 0) return <></>
    return withdrawAutoHead.map((withdrawAuto: IWithdrawAutoHead, index: number) => {
      if (withdrawAuto.transferList !== null)
      return (
        <div key={index} className="rounded-lg bg-background-1 p-2.5">
          <div>
            <span className="font-katnitBold">
              Group name : {withdrawAuto.groupBankName}
            </span>
          </div>
          <div className="py-2.5">
            {renderSelectBank(withdrawAuto.transferList, withdrawAuto.groupBankId)}
          </div>
        </div>
      )
    })
  }
  const renderSelectBank = (withdrawBank: IWithdrawAutoHeadContent[], groupBankId: number): React.ReactElement => {
    return (
      <Formik
      initialValues={{
        groupBankId: groupBankId,
        selectBankGroup: withdrawBank,
        bankSelect: ''
      }}
      enableReinitialize={true}
      validationSchema={bankSelectAuto}
      onSubmit={async (values): Promise<void> => {
        setReloadElement(false)
        const result = {
          list: [{
            id: values.selectBankGroup[0].id,
            adminBankId: values.selectBankGroup[0].adminBankAccountId,
            groupBankId: values.groupBankId,
            targetAdminBankId: Number(values.bankSelect)
          }]
        }
        try {
          await withdrawApi.withdrawAutoUpdateBank(result)
          toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
          fetchWithdrawAutoBankSelect()
          fetchWithDrawAuto()
          setReloadElement(true)
        } catch (e) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
        }
      }}
    >
    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }): React.ReactElement => (
      <form className="flex flex-col" onSubmit={handleSubmit}>
        { withdrawBank ?
          withdrawBank.map((bank: IWithdrawAutoHeadContent, index: number) => {
            if (bankSelectList.length > 0) {
              return (
                <div
                  className="flex flex-col"
                  key={index}
                >
                  <div className="flex items-center py-2.5">
                    <div className="flex items-center">
                      <img src={bank.adminLogoUrl} alt="logo_bank" className="w-10 rounded-lg" />
                      <span className="pl-3">{bank.adminBankName} - {bank.adminBankAccount} - {bank.adminBankOwnerName}</span>
                    </div>
                  </div>
                  <div>
                    <span className="pr-2.5">โอนหา</span>
                    <select
                      className="border border-background-2 sm:w-fit w-[322px]"
                      name={`selectBankGroup.${index}.targetAdminBankId`}
                      value={values.selectBankGroup[index].targetAdminBankId}
                      onChange={(e): void => {
                        setFieldValue('bankSelect', e.target.value)
                        handleChange(e)
                      }}
                    >
                      <option key={index} value="0">
                        กรุณาเลือกธนาคาร
                      </option>
                      {
                        bankSelectList.map((bankSelect: IBankDeposit, index: number) => {
                          if (bank.adminBankId !== bankSelect.id) {
                            return <option
                              key={index}
                              value={bankSelect.id}
                            >
                              {bankSelect.bankOwnerName} / {bankSelect.name} - {bankSelect.bankAccount}
                            </option>
                          }
                        })
                      }
                    </select>
                    {errors.bankSelect && touched.bankSelect
                      ? <label
                        className="block text-red-500 text-left">
                          {errors.bankSelect}
                        </label>
                      : null
                    }
                  </div>
                  <button
                    type="submit"
                    className="bg-background-2 w-fit mt-3 text-white p-2.5 text-20px rounded-lg hover:opacity-90">
                      บันทึก
                  </button>
                </div>
              )
            }
          })
          : null
        }
      </form>
    )}
    </Formik>
    )
  }
  return (
    <div>
      <BeradCrump headTitle="ธนาคาร" showIcon={true} title={MENU_TITLE.AUTO_WITHDRAW} />
      <div className="py-5">
        <div>ตั้งค่าสำหรับให้บอทโอนเงินทุกๆ ชั่วโมง</div>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-2.5 pt-5">
          {reloadElement ? renderGroupBankAuto() : null}
        </div>
      </div>
    </div>
  )
}

export default withLayout(WithdrawAuto, WEB_TITLE.WITHDRAW_AUTO, WEB_TITLE.WITHDRAW_AUTO)