import classnames from 'classnames'

const BeradCrump = ({
  headTitle,
  title,
  showIcon,
  classTitle
}: {
  headTitle: string
  title: string
  showIcon?: boolean
  classTitle?: string
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      <div className="text-20px text-text-3">{headTitle}</div>
      <div className={ showIcon ? 'px-2.5 pt-0.5 block' : 'hidden'}>
        <svg className={Number(title.length) <= 0 ? 'hidden' : 'block'} width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.14883 11.9761L5.84531 6.40903C6.05156 6.20694 6.05156 5.79328 5.84531 5.59276L0.148828 0.024098C0.134818 0.010394 0.118265 0.00218459 0.101004 0.000379573C0.0837426 -0.00142544 0.0664477 0.00324451 0.0510359 0.0138718C0.0356245 0.0244992 0.0226984 0.0406687 0.01369 0.0605881C0.0046816 0.0805075 -5.67301e-05 0.103399 1.66178e-08 0.126724L3.57639e-07 2.72727C3.68198e-07 2.80779 0.0292963 2.88358 0.0773429 2.93095L3.375 6.00011L0.0773437 9.27273C0.0292971 9.32009 1.23212e-06 9.39588 1.24268e-06 9.4764L1.55702e-06 11.8735C1.57048e-06 11.9761 0.0867206 12.0361 0.14883 11.9761Z"
          fill="#878787"/>
        </svg>
      </div>
      <div className={classnames('text-20px text-text-2 font-katnitBold', classTitle)}>{title}</div>
    </div>
  )
}

export default BeradCrump