import { Route, Redirect } from 'react-router-dom';
import WEB_TITLE from '../constant/webTitle';

export const AuthGuard = ({ component: Component, ...props }: any) => (
  <Route
    {...props}
    render={routeProps => {
      const token = localStorage.getItem('accessToken')
      const state = { redirect: '/dashboard' }
      document.title = WEB_TITLE.HEAD_NAME + props.name
      if (token && props.path === '/') {
        return <Redirect to={state.redirect} />
      } else {
        return token !== null ? (
          <Component {...routeProps} props={props} />
        ) : (
          <Redirect to="/" />
        );
      }
    }}
  />
);

