import withLayout from '../../../layout/withLayout'
import { useState, useEffect } from 'react'
import IGameRound from '../../../model/GamePanelApi/IGameRound'
import quickPanelApi from '../../../api/quickPanel.api'
import { useParams } from 'react-router-dom'
import IGameResultTotalBet from '../../../model/GamePanelApi/IGameResultTotalBet'

const Index = (): React.ReactElement => {
  const { id }: any = useParams()
  const [selectRound, setSelectRound] = useState(0)
  const [roundBet, setRoundBet] = useState<IGameRound[]>([])
  const [resultTotalBet, setResultTotalBet] = useState<IGameResultTotalBet>({
    lottoIncome: 0,
    rockPaperScissorsIncome: 0,
    affiliate: 0,
    grandTotal: 0,
    percent: 0,
    incomeTable: []
  })
  const fetchRoundGameById = async (): Promise<void> => {
    const response = await quickPanelApi.getRoundGameById(id)
    setRoundBet(response)
  }
  const fetchResultTotalBet = async (): Promise<void> => {
    const response = await quickPanelApi.getResultTotalBetById(selectRound)
    setResultTotalBet(response)
  }
  useEffect(() => {
    fetchRoundGameById()
  }, [])
  useEffect(() => {
    if(selectRound > 0) {
      fetchResultTotalBet()
    }
  },[selectRound])
  const renderSelectRound = (): React.ReactNode => {
    if (roundBet.length < 1) return
    return (
      <select
        name="gameTypeSelect"
        className="rounded-sm p-1 border border-black"
        onChange={(e): void => setSelectRound(Number(e.target.value))}
        value={selectRound}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          roundBet.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}>
                  Round : {item.id}
              </option>
            )
          })
        }
      </select>
    )
  }
  const renderDetailTable = (item: any): React.ReactElement => {
    return item.map((bet: any, index: number) => {
      return (
        <div key={index} className="flex flex-1 text-center">
          <div className="w-full border border-black text-text-2">
            {bet.gameCateName}
          </div>
          <div className="w-full border border-black text-text-2">
            {bet.income}
          </div>
        </div>
      )
    })
  }
  return (
    <div>
      <div>
        {renderSelectRound()}
      </div>
      {selectRound > 0 &&
        <div>
          <div className="pt-5">
            <p>รายได้จากหวย {resultTotalBet.lottoIncome}</p>
            <p>รายได้จากเป่ายิ้งฉุบ {resultTotalBet.rockPaperScissorsIncome}</p>
            <p>รายได้จากลิ้งค์ช่วยแชร์ {resultTotalBet.affiliate}</p>
            <p>รายได้รวม {resultTotalBet.grandTotal}</p>
            <p>คิดจากเปอเซ็น {resultTotalBet.percent}</p>
          </div>
          <div className="flex">
            <div className="flex flex-col mt-4">
              <div className="border border-black px-3 bg-gray-400">
                รายละเอียดรายได้แต่ละประเภท
              </div>
              <div className="flex flex-1 text-center">
                <div className="w-full border border-black">
                  ประเภท
                </div>
                <div className="w-full border border-black">
                  รายได้
                </div>
              </div>
              {renderDetailTable(resultTotalBet.incomeTable)}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default withLayout(Index, 'Game Panel')