import WEB_TITLE from "../../../constant/webTitle"
import withLayout from "../../../layout/withLayout"
import { MENU_TITLE } from '../../../constant/menuTitle'
import { FormikProps, useFormik } from 'formik'
import bankTranferSchema from '../../../validation/bankTranferSchema'
import InputSelect from '../../../components/InputSelect'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useEffect, useState } from "react"
import bankApi from "../../../api/bank.api"
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IBankTranferList from '../../../model/BankAdminApi/IBankTranferList'

interface IFormikData {
  bankId: string
  toBankId: string
  amount: string
}

const BankTranfer = (): React.ReactElement => {
  const titleHeader = MENU_TITLE.BANK_TRANFER
  const [bankData, setBankData] = useState<IBankTranferList[] | []>([])
  useEffect(() => {
    (async function fetchBankData(): Promise<any> {
      try {
        const response = await bankApi.getBankTranferList()
        setBankData(response)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  const sendNewLineNoti = async (bankId: string): Promise<void> => {
    try {
      const result = await bankApi.setNotificationBankTransfer(bankId)
      console.log(result);
    } catch (error) {
      console.log(error)
    }
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const onSubmit = async (values: any): Promise<void> => {
    const result = {
      bankId: Number(bankData.find(item => item.bankAccount === values.bankId)?.id),
      toBankId: Number(bankData.find(item => item.bankAccount === values.toBankId)?.id),
      amount: Number(values.amount)
    }
    try {
      await bankApi.saveBankTranferWithDraw(result)
      toastActive({state: 'success', description: 'แก้ไขข้อมูลสำเร็จ', duration: 500})
      formik.resetForm()
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถแก้ไขข้อมูลได้', duration: 500})
    }
  }
  const formik: FormikProps<IFormikData> = useFormik<IFormikData>({
    initialValues: {
      bankId: '',
      toBankId: '',
      amount: ''
    },
    validateOnChange: true,
    validationSchema: bankTranferSchema,
    onSubmit: onSubmit
  })
  return (
    <div className="ml-3">
      <div className="text-2xl flex items-center cursor-pointer max-w-max">
        <span className="text-20px font-katnitBold">{titleHeader}</span>
      </div>
      <div className="pt-6">
        <div>
          <InputSelect
            name="bankId"
            options={bankData}
            value={formik.values.bankId}
            textLabelTopSelect="บัญชีธนาคารต้นทาง"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกบัญชีธนาคารต้นทาง"
            showNumberAndText={true}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={formik.handleBlur}
            onChange={(e): void => {
              if (e.target.value !== '') {
                const bankId = bankData.findIndex(bankItem => bankItem.bankAccount === e.target.value)
                sendNewLineNoti(String(bankData[bankId].bankId))
              }
              formik.handleChange(e)
            }}
            error={formik.touched.bankId && formik.errors.bankId}
          />
        </div>
        <div>
          <InputSelect
            name="toBankId"
            options={bankData}
            value={formik.values.toBankId}
            textLabelTopSelect="บัญชีธนาคารปลายทาง"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกบัญชีธนาคารปลายทาง"
            showNumberAndText={true}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.bankId && formik.errors.bankId}
          />
        </div>
        <div>
          <Input
            name="amount"
            type="text"
            value={formik.values.amount}
            placeholder="จำนวนเงิน"
            textLabelTopInput="จำนวนเงิน"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.amount && formik.errors.amount}
          />
        </div>
      </div>
      <Button
        type="button"
        onClick={(): void => formik.handleSubmit()}
        buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-4 text-16px"
        buttonText="บันทึก"
      />
    </div>
  )
}

export default withLayout(BankTranfer, WEB_TITLE.BANK_TRANFER)