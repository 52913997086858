import withLayout from "../../../layout/withLayout"
import WEB_PATHS from "../../../constant/webPath"
import { useHistory, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import ToastStateIcon from "../../../components/ToastStateIcon"
import IToastState from "../../../model/toastState"
import toast from "react-hot-toast"
import IUserAffiliateRevenue from "../../../model/UserApi/IUserAffiliateRevenue"
import userApi from "../../../api/user.api"
import IUserAffiliateList from "../../../model/UserApi/IUserAffiliateList"
import IUserAffiliateHistory from "../../../model/UserApi/IUserAffiliateHistory"
import { dateFormat } from "../../../utils/dateFormat"

const affiliatePage = (): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const [yearSelect, setYearSelect] = useState('')
  const [yearData, setYearData] = useState([])
  useEffect(() => {
    const getYearSelect = (): any => {
      const year = []
      const newYear = new Date()
      for (let index = 0; index < 3; index++) {
        year.push(String(Number(newYear.getFullYear()) - index))
      }
      return year.sort()
    }
    setYearData(getYearSelect())
  }, [])
  const [affiliateList, setAffiliateList] = useState<IUserAffiliateList[]>([])
  const [affiliateRevenue, setAffiliateRevenue] = useState<IUserAffiliateRevenue>({
    userId: 0,
    affiliateAmount: 0,
    updatedAt: ''
  })
  const [affiliateHistory, setAffiliateHistory] = useState<IUserAffiliateHistory[]>([])
  const backPage = (): void => {
    history.push({
      pathname: WEB_PATHS.USER_EDIT.replace(':id', id)
    })
  }
  const renderSelect = (): React.ReactNode => {
    if (yearData.length <= 0) return
    return (
      <select
        name="typeGameSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px mt-3 sm:w-1/4 w-full rounded-lg bg-white"
        onChange={(e): void => setYearSelect(e.target.value)}
        value={yearSelect}
      >
        <option value="">กรุณาเลือกปีที่ต้องการ</option>
        {yearData.map((type: string | any, index: number) => {
          return (
          <option
            key={index} value={type}
          >
            {type}
          </option>)
        })}
      </select>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  useEffect(() => {
    const fetchAffiliateRevenue = async (): Promise<void> => {
      try {
        const revenue = await userApi.useAffiliateRevenue(id)
        setAffiliateRevenue(revenue)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงรายได้ช่วยแชร์ได้', duration: 500})
      }
    }
    const fetchAffiliateHistory = async (): Promise<void> => {
      try {
        const history = await userApi.useAffiliateHistory(id)
        setAffiliateHistory(history)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงรายการย้อนหลังได้', duration: 500})
      }
    }
    fetchAffiliateHistory()
    fetchAffiliateRevenue()
  }, [])
  useEffect(() => {
    const fetchAffiliateList = async (year: string): Promise<void> => {
      try {
        const affiliateList = await userApi.useAffiliateList(id, year)
        affiliateList.length > 0 ? setAffiliateList(affiliateList) : toastActive({state: 'warning', description: 'เกิดข้อผิดพลาด ข้อมูลของปีนี้ว่างเปล่า', duration: 500})
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงประวัติการเคลมได้', duration: 500})
      }
    }
    if (yearSelect !== '') fetchAffiliateList(yearSelect)
  }, [yearSelect])
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-auto pb-2.5">
        <div className="flex w-full items-center overflow-auto scrollbar-hide px-3">
          <div className="sm:w-[20%] w-[10%]">#</div>
          <div className="sm:w-[26%] w-[30%] text-left">จำนวนเงินที่เคลม</div>
          <div className="sm:w-[26%] w-[30%] text-left">ยอดรวม</div>
          <div className="sm:w-[26%] w-[30%] text-left">วันที่ทำรายการ</div>
        </div>
        {
          renderContent(affiliateHistory)
        }
      </div>
    )
  }
  const renderContent = (item: IUserAffiliateHistory[]): React.ReactNode => {
    if (item.length <= 0) {
      return (
        <div className="w-full items-center text-16px bg-background-1 px-2.5 py-2 text-center text-text-6">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div className="px-2 w-full">
        {item.map((history, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[20%] w-[10%] text-text-6">{index +1}.</div>
              <div className="sm:w-[26%] w-[30%] overflow-hidden truncate text-16px text-text-6">{history.amount}</div>
              <div className="sm:w-[26%] w-[30%] text-16px text-text-6 text-left">{history.balance}</div>
              <div className="sm:w-[26%] w-[30%] text-16px text-text-6 text-left">{dateFormat(history.createdAt, 'dd-MM-yyyy')}</div>
            </div>
          )
        })}
      </div>
    )
  }
  const renderTableList = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-[900px] pb-2.5">
        <div className="flex w-full items-center overflow-auto scrollbar-hide px-3">
          <div className="sm:w-[20%] w-[100px]">#</div>
          <div className="sm:w-[26%] w-[200px] text-left">ชื่อผู้ใช้งาน</div>
          <div className="sm:w-[26%] w-[300px] text-right">ยอดเดิมพัน</div>
          <div className="sm:w-[26%] w-[300px] text-right">ส่วนแบ่ง</div>
        </div>
        {
          renderContentList(affiliateList)
        }
      </div>
    )
  }
  const renderContentList = (item: IUserAffiliateList[]): React.ReactNode => {
    if (item.length <= 0) {
      return (
        <div className="w-full items-center text-16px bg-background-1 px-2.5 py-2 text-center text-text-6">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div className="px-2 w-full">
        {item.map((list, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[20%] w-[100px] text-text-6">{index +1}.</div>
              <div className="sm:w-[26%] w-[200px] overflow-hidden truncate text-16px text-text-6">{list.username}</div>
              <div className="sm:w-[26%] w-[300px] text-16px text-text-6 text-right">{parseFloat(Number(list.price.toLocaleString('th')).toFixed(2))} ฿</div>
              <div className="sm:w-[26%] w-[300px] text-16px text-text-6 text-right">{parseFloat(list.revenue.toFixed(2))} ฿</div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div>
      <div className="text-2xl flex w-fit items-center cursor-pointer pb-6" onClick={() => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">ย้อนกลับหน้าผู้ใช้งาน</span>
      </div>
      <div>จำนวนเงินเคลมที่มีอยู่ตอนนี้ {affiliateRevenue.affiliateAmount.toLocaleString('th')} ฿</div>
      <div className="py-3">
        ประวัติการเคลม
        <div>
          {renderTable()}
        </div>
      </div>
      <div>
        <div className="pb-3">
          {renderSelect()}
        </div>
        เลือกงวดที่ต้องการดูรายได้
        <div className="overflow-auto scrollbar-hide">
          {renderTableList()}
        </div>
      </div>
    </div>
  )
}

export default withLayout(affiliatePage, 'USER AFFILIATE')