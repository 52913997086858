import withLayout from '../../layout/withLayout'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Formik } from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useState } from 'react'
import blogApi from '../../api/blog.api'
import { useHistory } from 'react-router-dom'
import schema from '../../validation/blogSchema'
import WEB_PATHS from '../../constant/webPath'
import WEB_TITLE from '../../constant/webTitle'
import { MENU_TITLE } from '../../constant/menuTitle'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'

const CreateBlog = (mainName: any) => {
  const titleHeader = mainName.mainName
  const history = useHistory()
  const [value, setValue] = useState('')
  const [pin, setPin] = useState(false)
  const backPage = () => {
    history.push({
      pathname: WEB_PATHS.BLOG_LIST
    })
  }
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      // ['clean']
    ],
    // placeholder: 'Text for content...',
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  const renderLabel = (value: boolean) => {
    return value
      ? <label className="toggle-label h-6 block rounded-full bg-background-10 cursor-pointer"></label>
      : <label className="toggle-label h-6 block rounded-full bg-background-9 cursor-pointer"></label>
  }
  const renderInputSwitch = (value: boolean) => {
    return value ?
      <input
        type="checkbox"
        name="pin"
        defaultChecked={false}
        value={String(pin)}
        className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer right-0.5 top-2.5"/>
      : <input
          type="checkbox"
          name="pin"
          defaultChecked={true}
          value={String(pin)}
          className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer left-0.5 top-2.5"/>
  }
  // const renderBlogForm = (setFieldValue: any): React.ReactElement => {
  //   return (
  //     <ReactQuill
  //       placeholder='Text for content...'
  //       className="sm:h-64 h-48 sm:w-1/2 w-full sm:mb-14 mb-20 rounded-lg"
  //       theme="snow"
  //       modules={modules}
  //       formats={formats}
  //       value={value}
  //       onChange={(e) => {
  //         setFieldValue('content', e)
  //         setValue(e)
  //       }}
  //       // onChange={setValue}
  //     >
  //       <div className="my-editing-area"/>
  //     </ReactQuill>
  //   )
  // }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <div>
      <Formik
      initialValues={{
        title: '',
        content: '',
        pinned: false
      }}
      validationSchema={schema}
      onSubmit={async (values): Promise<void> => {
        const data = {
          title: values.title,
          content: value,
          pinned: pin
        }
        try {
          await blogApi.saveBlog(data)
          toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
          history.push({
            pathname: WEB_PATHS.BLOG_LIST
          })
          } catch (e) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
        }
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }): React.ReactElement => (
      <form onSubmit={handleSubmit}>
        <div className="text-2xl flex w-fit items-center cursor-pointer pb-6" onClick={ () => backPage() }>
          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
          </svg>
          <span className="ml-3 text-20px font-katnitBold">{titleHeader}</span>
        </div>
        <div className="flex flex-row items-center">
          <div
            onClick={(): void => setPin(!pin)}
            className="relative py-2 inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
            >
            {renderInputSwitch(pin)}
            {renderLabel(pin)}
          </div>
          <label className="pr-2.5 text-16px">ปักหมุดบล็อค</label>
        </div>
        <Input
          name="title"
          type="text"
          value={values.title}
          placeholder="กรอกชื่อบล็อค"
          textLabelTopInput="หัวข้อ"
          showTextLabelTopInput={false}
          classNameLabel="text-text-2 placeholder-text-1 block"
          inputClassName="sm:w-3/6 w-full text-12px px-2.5 py-3 rounded-lg"
          divClassName="flex flex-col py-3"
          disabled={false}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.title && errors.title}
        />
        <ReactQuill
          placeholder='Text for content...'
          className="sm:h-64 h-48 sm:w-1/2 w-full sm:mb-14 mb-20 rounded-lg"
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={(e) => setValue(e)}
          // onChange={setValue}
        />
        <Button
          type="submit"
          buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
          buttonText="บันทึก"
        />
      </form>
      )}
      </Formik>
    </div>
  )
}

export default withLayout(CreateBlog, WEB_TITLE.BLOG, MENU_TITLE.BLOG_CREATE)