import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'


export function usePortal(props: any): any {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(props[1])
    setMounted(true)
  }, [props[1]])
  return mounted ? createPortal(props[0], ref.current as any) : null
}

export default usePortal