import withLayout from '../../../layout/withLayout'
import WEB_TITLE from '../../../constant/webTitle'
import { useState, useEffect } from 'react'
import gameApi from '../../../api/game.api'
import IGameRoundSelect from '../../../model/IGameRoundSelect'
import IGameRound from '../../../model/GameRoundApi/IGameRound'
import Paginations from '../../../components/Paginations'
import { useHistory } from 'react-router-dom'
import WEB_PATHS from '../../../constant/webPath'
import { dateFormat } from '../../../utils/dateFormat'
import BeradCrump from '../../../components/BreadCrump'
import { MENU_TITLE } from '../../../constant/menuTitle'

const GameRoundList = (route: any): React.ReactElement => {
  const history = useHistory()
  const [yearSelect, setYearSelect] = useState<string>(String(new Date().getFullYear()))
  const [gameRoundSelectList, setGameRoundSelectList] = useState<IGameRoundSelect[]>([])
  const fetchQuickPanel = async (): Promise<void> => {
    const response = await gameApi.getGameRoundSelect()
    setGameRoundSelectList(response)
  }
  const [count, setCount] = useState(0)
  const [gameRoundSelect, setGameRoundSelect] = useState<number>(1)
  const queryFilter = {
    qs: route.query.query.get('qs') ? route.query.query.get('qs') : gameRoundSelect,
    year: yearSelect ? yearSelect : String(new Date().getFullYear()),
    take: route.query.query.get('take') ? Number(route.query.query.get('take')) : 30,
    skip: route.query.query.get('skip') ? Number(route.query.query.get('skip')) : 0
  }
  useEffect(() => {
    fetchGameRound()
  }, [route.query])
  const [gameRoundListData, setGameRoundListData] = useState<IGameRound[]>([])
  const fetchGameRound = async (): Promise<void> => {
    console.log(queryFilter, 'queryFilter')
    try {
      const { data, filters } = await gameApi.getGameRound(queryFilter)
      setGameRoundListData(data)
      setCount(filters.count ? filters.count : 0)
    } catch (error) {
      alert(error)
    }
  }
  useEffect(() => {
    fetchQuickPanel()
  }, [])
  useEffect(() => {
    if (gameRoundSelect > 0 && gameRoundSelectList) fetchGameRound()
  }, [gameRoundSelect, yearSelect])
  const renderSelect = (): React.ReactNode => {
    if (gameRoundSelectList.length < 1) return
    return (
      <select
        name="typeGameSelect"
        className="rounded-lg border border-border-0 bg-white text-text-2 py-2 px-3 sm:mb-0 mb-3 sm:w-[40%] w-full"
        onChange={(e: any): void => {
          history.replace({
            pathname: WEB_PATHS.GAME_ROUND,
            search: `?qs=${e.target.value}&take=30&skip=0`
          })
          setGameRoundSelect(e.target.value)
        }}
        value={gameRoundSelect}
      >
        <option value="">กรุณาเลือกประเภทหวย</option>
        {gameRoundSelectList.map((type, index) => {
          return (
          <option
            key={index} value={type.gameCateId}
          >
            {type.name}
          </option>)
        })}
      </select>
    )
  }
  const editGameRound = (id: number | string): void => {
    history.push(WEB_PATHS.GAME_ROUND_EDIT.replace(':id', String(id)))
  }
  const renderSelectYear = (): React.ReactNode => {
    return (
      <select
        name="typeSelect"
        className="rounded-lg border border-border-0 bg-white text-text-2 py-2 px-3 sm:ml-3 mb-3 sm:mb-0 sm:w-[20%] w-full"
        onChange={(e: any): void => {
          setYearSelect(e.target.value)
          history.replace({
            pathname: WEB_PATHS.GAME_ROUND,
            search: '?&take=30&skip=0'
          })
        }}
        value={yearSelect}
      >
        <option value="">กรุณาเลือกปี</option>
          <option
            value={String(new Date().getFullYear())}
          >
            {new Date().getFullYear()}
          </option>
          <option
            value={String(new Date().getFullYear()+1)}
          >
            {new Date().getFullYear()+1}
          </option>
          <option
            value={String(new Date().getFullYear()+2)}
          >
            {new Date().getFullYear()+2}
          </option>
      </select>
    )
  }
  const renderTable = (gameRoundListData: IGameRound[]): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-[900px]">
        {renderHeader()}
        {renderContent(gameRoundListData)}
      </div>
    )
  }
  const renderContent = (item: IGameRound[]): React.ReactElement => {
    if (item.length <= 0) {
      return (
        <div className="w-full text-center text-text-6">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div>
        {item.map((game, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[5%] w-[70px] sm:min-w-max text-text-6">{index +1}.</div>
              <div className="sm:w-[42.5%] w-[260px] sm:min-w-max text-16px text-text-6">{dateFormat(game.openDate, 'dd-MM-yyyy HH:mm')}</div>
              <div className="sm:w-[42.5%] w-[260px] sm:min-w-max text-16px text-text-6">{dateFormat(game.closeDate, 'dd-MM-yyyy HH:mm')}</div>
              <div className="sm:w-[10%] w-[50px]">
                <div className="group flex justify-end">
                  <svg onClick={(): void => editGameRound(game.id)} className="group-hover:hidden block cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#1E2339"/>
                    <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#1E2339"/>
                  </svg>
                  <svg onClick={(): void => editGameRound(game.id)} className="group-hover:block hidden cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#0F2898"/>
                    <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#0F2898"/>
                  </svg>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const renderHeader = (): React.ReactElement => {
    return (
      <div className="flex px-2 py-3 w-full items-center scrollbar-hide overflow-auto">
        <div className="sm:w-[5%] w-[70px] sm:min-w-max">#</div>
        <div className="sm:w-[42.5%] w-[260px] sm:min-w-max text-16px text-text-2">วันที่เปิดแทง</div>
        <div className="sm:w-[42.5%] w-[260px] sm:min-w-max text-16px text-text-2">วันที่ปิดแทง</div>
        <div className="sm:w-[10%] w-[50px]"></div>
      </div>
    )
  }
  const findGameSelect = (id: number): string | undefined => {
    if (gameRoundSelectList.length > 0) {
      return gameRoundSelectList[gameRoundSelectList.findIndex(item => item.gameCateId === id)].name
    }
  }
  return (
    <div>
      <BeradCrump headTitle={MENU_TITLE.GAME} showIcon={true} classTitle="font-katnitBold" title={MENU_TITLE.GAME_LIMIT_ROUND} />
      <div className="flex w-full sm:flex-row flex-col sm:items-center sm:justify-between pt-6">
        <div className="sm:w-[60%] w-full sm:block flex flex-col">
          {renderSelect()}
          {renderSelectYear()}
        </div>
        <div className="sm:w-[40%] w-full sm:flex hidden sm:justify-end">
          <button
            onClick={(): void => history.push(WEB_PATHS.GAME_ROUND_CREATE)}
            className="bg-background-10 hover:bg-background-11 text-white text-16px sm:w-auto w-full p-2.5 rounded-lg">
              สร้างงวดของเกมทั้งปี
          </button>
        </div>
        <div className="sm:hidden flex w-full justify-between items-center py-3">
          <div className="text-20px font-katnitBold">{findGameSelect(Number(gameRoundSelect))}</div>
          <div>
            <button
              type="button"
              onClick={(): void => history.push(WEB_PATHS.GAME_ROUND_CREATE)}>
                <svg className="group-hover:hidden block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="30" height="30" rx="5" stroke="#3473D8" strokeWidth="2"/>
                  <rect x="6" y="14" width="20" height="4" fill="#3473D8"/>
                  <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#3473D8"/>
                </svg>
                <svg className="group-hover:block hidden" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="30" height="30" rx="5" stroke="#0944DD" strokeWidth="2"/>
                  <rect x="6" y="14" width="20" height="4" fill="#0944DD"/>
                  <rect x="14" y="26" width="20" height="4" transform="rotate(-90 14 26)" fill="#0944DD"/>
                </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="sm:w-full min-w-full">
        <div className="sm:w-full w-full min-w-full scrollbar-hide overflow-x-scroll">
          {renderTable(gameRoundListData)}
        </div>
        <Paginations
          filter={queryFilter}
          count={count}
          divClassName="justify-end my-5 text-2xl"
        />
      </div>
    </div>
  )
}

export default withLayout(GameRoundList, WEB_TITLE.GAME, 'GAME ROUND')