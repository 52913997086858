import withLayout from '../../../layout/withLayout'
import { useState, useEffect } from 'react'
import IGamePanelCalculateBet from '../../../model/GamePanelApi/IGamePanelCalculateBet'
import quickPanelApi from '../../../api/quickPanel.api'
import { useHistory, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import IGamePanelCalculateBetData from '../../../model/GamePanelApi/IGamePanelCalculateBetData'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import * as Yup from 'yup'
import IGameCalculateBetResult from '../../../model/GamePanelApi/IGameCalculateBetResult'
import IGameRound from '../../../model/GamePanelApi/IGameRound'
import WEB_PATHS from '../../../constant/webPath'
import { MENU_TITLE } from '../../../constant/menuTitle'
import { dateFormat } from '../../../utils/dateFormat'
import IToastState from '../../../model/toastState'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'

const CalculateBet = (): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const [selectRound, setSelectRound] = useState(0)
  const [roundBet, setRoundBet] = useState<IGameRound[]>([])
  const [betResultCalculate, setBetResultCalulate] = useState<IGameCalculateBetResult>({
    results: [],
    total: 0
  })
  const [calculateSubmit, setCalculateSubmit] = useState({
    gameCateId: 0,
    gameRound: ''
  })
  useEffect(() => {
    setCalculateSubmit({
      gameCateId: 0,
      gameRound: ''
    })
  }, [])
  const [betFormNumber, setBetFormNumber] = useState<IGamePanelCalculateBet>({
    gameCateId: 0,
    gameCateName: '',
    gameRound: 0,
    results: []
  })
  const fetchFormBetCalculate = async (): Promise<void> => {
    try {
      const response = await quickPanelApi.getGameCalculateBetForm(id, selectRound)
      setBetFormNumber(response)
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถเรียกดูผลได้', duration: 500})
    }
  }
  const fetchRoundGameById = async (): Promise<void> => {
    try {
      const response = await quickPanelApi.getRoundGameById(id)
      setRoundBet(response)
    } catch (error) {
      toastActive({state: 'success', description: 'เกิดข้อผิดพลาด ไม่สามารถเรียกเกม round ได้', duration: 500})
    }
  }
  useEffect(() => {
    fetchRoundGameById()
  }, [])
  useEffect(() => {
    if(selectRound) fetchFormBetCalculate()
  },[selectRound])
  const renderRow = (props: any): React.ReactNode => {
    return props.values.results.map((form: IGamePanelCalculateBetData, index: number) => {
      return (
        <div key={index} className="flex w-full flex-row">
          <Input
            name={`results.${index}.result`}
            type="text"
            value={form.result}
            placeholder={form.gameCateTypeName === 'สามตัวล่าง' || form.gameCateTypeName === 'สามตัวหน้า'
              ? 'xxx,xxx'
              : form.gameCateTypeName === 'หกตัวบน'
              ? 'xxxxxx'
              : form.gameCateTypeName === '3ตัวบน'
              ? 'xxx'
              : 'xx'
            }
            textLabelTopInput={form.gameCateTypeName}
            showTextLabelTopInput={true}
            classNameLabel="text-text-2 mb-2"
            inputClassName="w-full bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
            divClassName="flex w-full flex-col mt-2 text-left"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.results &&
              props.errors.results[index] &&
              props.errors.results[index].result &&
              props.touched &&
              props.touched.results &&
              props.touched.results[index] &&
              props.touched.results[index].result && props.errors.results[index].result
            }
          />
        </div>
      )
    })
  }
  const schema = Yup.object().shape({
    results: Yup.array().of(
      Yup.object().shape({
        result: Yup.string()
          .required('จำเป็น')
      })
    )
  })
  const renderTable = (): React.ReactNode => {
    return (
      <div className="px-2 w-full">
        { betResultCalculate &&
          betResultCalculate.results !== null &&
          betResultCalculate.results.length > 0 ?
          betResultCalculate.results.map((item, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[40%] w-[300px] overflow-hidden truncate text-16px text-text-6 text-left">{item.gameCateTypeName}</div>
              <div className="sm:w-[30%] w-[300px] text-16px text-text-6 text-right">{item.playNumber}</div>
              <div className="sm:w-[30%] w-[300px] overflow-hidden truncate text-16px text-text-6 text-right">{item.playAmount.toLocaleString('th')} ฿</div>
            </div>
          )
        }) :
          <div>ไม่มีคนถูกรางวัล</div>
        }
      </div>
    )
  }
  const renderResultCalculate = (): React.ReactNode => {
    if(calculateSubmit.gameRound === '') return
    return (
      <div>
        <div className="overflow-auto scrollbar-hide">
          <div className="overflow-scroll scrollbar-hide sm:w-full w-[400px] pb-2.5">
            <div className="flex w-full items-center overflow-auto scrollbar-hide px-3">
              <div className="sm:w-[40%] w-[300px] text-left">ประเภทเลข</div>
              <div className="sm:w-[30%] w-[300px] whitespace-nowrap text-right">หมายเลขที่ถูกรางวัล</div>
              <div className="sm:w-[30%] w-[300px] text-right">ยอดจ่ายสุทธิ</div>
            </div>
            <div>
              {renderTable()}
            </div>
          </div>
          <div className="text-right py-3">
            <span className="text-16px text-text-2">ยอดจ่ายทั้งหมด</span>
            <span className="text-16px text-text-2 pl-6">{betResultCalculate.total.toLocaleString('th')} ฿</span>
          </div>
        </div>
        { betResultCalculate.results !== null && betResultCalculate.results.length > 0 &&
          <div className="flex justify-end">
            <Button
              buttonClassName="bg-background-2 hover:bg-background-8 mt-3 text-white py-2 px-3 text-16px"
              buttonText="คำนวนหวย"
              type="button"
              onClick={submitCalculate}
            />
          </div>
        }
      </div>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const submitCalculate = async (): Promise<void> => {
    try {
      await quickPanelApi.postGameCalculateBetResult(betFormNumber)
      toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
      setCalculateSubmit({
        ...calculateSubmit,
        gameCateId: 0,
        gameRound: ''
    })
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถคำนวนหวยได้', duration: 500})
    }
  }
  const renderSelectRound = (): React.ReactNode => {
    if (roundBet.length < 1) return
    return (
      <select
        name="gameTypeSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px sm:w-2/4 w-full rounded-lg bg-white"
        onChange={(e): void => {
          setSelectRound(Number(e.target.value))
          setBetResultCalulate({
            results: [],
            total: 0
          })
        }}
        value={selectRound}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {
          roundBet.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}>
                  Round : {dateFormat(item.startDate, 'dd-MM-yyyy')} - {dateFormat(item.closeDate, 'dd-MM-yyyy')}
              </option>
            )
          })
        }
      </select>
    )
  }
  return (
    <div>
      <div className="flex items-center w-fit cursor-pointer pb-6" onClick={ () => history.push({
        pathname: WEB_PATHS.GAME_PANEL.replace(':id', String(id))
      })}>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.GAME_CLEAR}</span>
      </div>
      <div>
        {renderSelectRound()}
      </div>
      { selectRound > 0 &&
        betFormNumber &&
        betFormNumber.results &&
        betFormNumber.results.length > 0 &&
        betFormNumber.results !== null ?
        <div>
          <div>
            <Formik
              initialValues={{
                gameCateId: betFormNumber.gameCateId,
                gameCateName: betFormNumber.gameCateName,
                gameRound: betFormNumber.gameRound,
                results: betFormNumber.results
              }}
              enableReinitialize
              validationSchema={schema}
              onSubmit={async (values: IGamePanelCalculateBet) => {
                try {
                  const response = await quickPanelApi.getGameCalculateBetResult(values)
                  setBetResultCalulate(response)
                  toastActive({state: 'success', description: 'เรียกดูผลสำเร็จ', duration: 500})
                  setCalculateSubmit({
                    ...calculateSubmit,
                    gameCateId: values.gameCateId,
                    gameRound: String(values.gameRound)
                  })
                  setBetFormNumber(values)
                } catch (error) {
                  toastActive({state: 'success', description: 'เกิดข้อผิดพลาด ไม่สามารถเรียกดูผลได้', duration: 500})
                }
              }}
            >
              {props => (
                <form className="w-full" onSubmit={props.handleSubmit}>
                  <div className="py-3">
                    {props.values.gameCateName} รอบที่ : {props.values.gameRound}
                  </div>
                  <div className="w-full flex sm:flex-row flex-col gap-2">
                    <div className="sm:w-2/4 w-full">
                      {renderRow(props)}
                      <Button
                        buttonClassName={ betResultCalculate && betResultCalculate.results && betResultCalculate.results.length > 0
                          ? 'bg-background-2 hover:bg-background-8 mt-3 text-white py-2 px-3 text-16px hidden'
                          : 'bg-background-2 hover:bg-background-8 mt-3 text-white py-2 px-3 text-16px'
                        }
                        buttonText="เรียกดูผล"
                        type="submit"
                      />
                    </div>
                    <div className={
                      betResultCalculate &&
                      betResultCalculate.results &&
                      betResultCalculate.results.length > 0
                      ? 'sm:w-2/4 w-full'
                      : 'sm:w-2/4 w-full'
                    }>
                      {renderResultCalculate()}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        : <div>
          ผลเกมของเกมยังไม่มี
        </div>
      }
    </div>
  )
}

export default withLayout(CalculateBet, 'Game Panel')