import withLayout from '../../../layout/withLayout'
import { useState, useEffect } from 'react'
import paymentSwitch from '../../../api/paymentSwitch'
import Button from '../../../components/Button'
import { useHistory } from 'react-router-dom'
import WEB_TITLE from '../../../constant/webTitle'
import WEB_PATHS from '../../../constant/webPath'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BreadCrump from '../../../components/BreadCrump'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'

const PaymentSwitch = () => {
  const history = useHistory()
  const [switchPayment, setSwitchPayment] = useState<boolean>(false)
  const [switchBet, setSwitchBet] = useState<boolean>(false)
  useEffect(() => {
    (async function fetchData(): Promise<any> {
      try {
        const response = await paymentSwitch.switchBet()
        setSwitchPayment(response.depositWithdraw)
        setSwitchBet(response.bet)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  const chageSwitch = (value: string, status: boolean) => {
    const state = status ? false : true
    switch (value) {
      case 'depositWithdraw':
        setSwitchPayment(state)
        break;
      case 'bet':
        setSwitchBet(state)
        break;
      default:
        break;
    }
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const handleSubmit = async (): Promise<void> => {
    const data = {
      depositWithdraw: switchPayment === true,
      bet: switchBet === true
    }
    try {
      await paymentSwitch.saveSwitchBet(data)
      history.push({
        pathname: WEB_PATHS.SETTING_PAYMENT
      })
      toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
    }
  }
  const renderLabel = (value: boolean) => {
    return value
      ? <label className="toggle-label block h-6 rounded-full bg-background-10 cursor-pointer"></label>
      : <label className="label-toggle-active toggle-label block h-6 rounded-full bg-background-9 cursor-pointer"></label>
  }
  const renderInputSwitch = (value: boolean, name: string) => {
    return value
    ? <input
        type="checkbox"
        name={name}
        id={name}
        defaultChecked={false}
        value={String(switchPayment)}
        className="toggle-active toggle-checkbox absolute block w-5 h-5 top-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer right-0.5"/>
    : <input
        type="checkbox"
        name={name}
        id={name}
        defaultChecked={true}
        value={String(switchPayment)}
        className="toggle-checkbox absolute block w-5 h-5 top-0.5 left-0.5 rounded-full bg-white border-white border-4 appearance-none cursor-pointer"/>
  }
  return (
    <div>
      <BreadCrump headTitle="ธนาคาร" showIcon={true} title={MENU_TITLE.PAYMENT_TRANSACTION} />
        <div className="pt-6">
        <div>
          <div
            onClick={(): void => chageSwitch('depositWithdraw', switchPayment)}
            className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
            >
            {renderInputSwitch(switchPayment, 'depositWithdraw')}
            {renderLabel(switchPayment)}
          </div>
          <label
            className="text-16px text-text-2">
            ระบบฝากถอน
          </label>
        </div>
        <div className="mt-5">
          <div
            onClick={(): void => chageSwitch('bet', switchBet)}
            className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
            >
            {renderInputSwitch(switchBet, 'bet')}
            {renderLabel(switchBet)}
          </div>
          <label
            className="text-16px text-text-2">
            ระบบการแทง-ยกเลิกการแทง
          </label>
        </div>
      </div>
      <Button
        type="submit"
        buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-3 text-16px"
        buttonText="บันทึก"
        onClick={(): Promise<void> => handleSubmit()}
      />
    </div>
  )
}

export default withLayout(PaymentSwitch, WEB_TITLE.PAYMENT_TRANSACTION, WEB_TITLE.PAYMENT_TRANSACTION)