import Layout from './layoutNavLeft'

function withLayout (WrappedComponent: any, name: string, mainName?: string): React.FunctionComponent {
  const Component = (route: any): React.ReactElement => {
    return (
      <Layout name={name} mainName={mainName}>
        <WrappedComponent route={route.route} name={name} mainName={mainName} query={route.props} />
      </Layout>
    );
  };
  return Component
}

export default withLayout