import withLayout from '../../../layout/withLayout'
import WEB_TITLE from '../../../constant/webTitle'
import { useState, useEffect } from 'react'
import botSettingApi from '../../../api/botSetting.api'
import BreadCrump from '../../../components/BreadCrump'
import { MENU_TITLE } from '../../../constant/menuTitle'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IDeposit from '../../../model/SettingsApi/IDeposit'
import IDepositList from '../../../model/SettingsApi/IDepositList'
import IDepositListContent from '../../../model/SettingsApi/IDepositListContent'
import { Formik } from 'formik'
import Button from '../../../components/Button'

const botWithDraw = (): React.ReactElement => {
  const [depositGroup, setDepositGroup] = useState<IDeposit>({
    isBotActive: false,
    list: []
  })
  const [depositHeadSwitch, setDepositHeadSwitch] = useState<boolean>(false)
  let indexSwitch = 0
  useEffect(() => {
    fetchDepositSwitch()
  }, [])
  const fetchDepositSwitch = async (): Promise<void> => {
    try {
      const response = await botSettingApi.getDepositBot()
      setDepositGroup(response)
      setDepositHeadSwitch(response.isBotActive)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถโหลดข้อมูลได้', duration: 500})
    }
  }
  const renderSwitch = () => {
    return (
      <div
        onClick={(): void => {
          setDepositHeadSwitch(!depositGroup.isBotActive)
          setDepositGroup({
            ...depositGroup,
            isBotActive: !depositGroup.isBotActive,
            list: depositGroup.list
          })
      }}>
        <input
          type="checkbox"
          name="isBotActive"
          defaultChecked={false}
          value={String(depositHeadSwitch)}
          className={ depositHeadSwitch
            ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
            : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
          }
        />
        <label
          className={ depositHeadSwitch
            ? 'toggle-label block h-6 rounded-full bg-background-10 cursor-pointer'
            : 'toggle-label block h-6 rounded-full bg-background-9 cursor-pointer'
          }
        >
        </label>
      </div>
    )
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const submitActiveDeposit = (): void => {
    const data = {
      isBotActive: depositHeadSwitch
    }
    try {
      botSettingApi.updateDepositBot(data)
      toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
      setTimeout(() => {
        fetchDepositSwitch()
      }, 1500)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
    }
  }
  const renderSwitchGroup = (listDepositGroup: IDepositListContent[], groupBankId: number): React.ReactNode => {
    if (listDepositGroup.length <= 0) return (<>ไม่มีการตั้งค่าสำหรับกรุ๊ปนี้</>)
    return listDepositGroup.map((depositSwitch: IDepositListContent, index: number) => {
      return (
        <Formik
          key={depositSwitch.bankId + Math.random()}
          initialValues={{
            groupBankId: groupBankId,
            depositList: listDepositGroup
          }}
          enableReinitialize={true}
          onSubmit={async (values): Promise<void> => {
            const result = {
              list: [{
                id: indexSwitch !== null ? values.depositList[indexSwitch].id : 0,
                adminBankId: indexSwitch !== null ? values.depositList[indexSwitch].adminBankId : 0,
                groupBankId: values.groupBankId,
                isActive: values.depositList[indexSwitch].isActive
              }]
            }
            try {
              botSettingApi.updateDepositBotGroup(result)
              toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
              setTimeout(() => {
                fetchDepositSwitch()
              }, 1500)
            } catch (e) {
              toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
            }
          }}
          >
          {({ handleSubmit, values, handleChange, submitForm, setFieldValue }): React.ReactElement => (
            <div>
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <div
                  className="py-2 flex items-center">
                  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name={`depositList.${index}.isActive`}
                      defaultChecked={false}
                      value={String(values.depositList[index].isActive)}
                      onClick={(): void => {
                        indexSwitch = index
                        setFieldValue(`depositList.${index}.isActive`, !values.depositList[index].isActive)
                        submitForm()
                      }}
                      className={ values.depositList[index].isActive
                        ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
                        : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
                      }
                    />
                    <label
                      onChange={handleChange}
                      className={values.depositList[index].isActive
                        ? 'toggle-label block h-6 rounded-full bg-background-10 cursor-pointer'
                        : 'toggle-label block h-6 rounded-full bg-background-9 cursor-pointer'
                      }
                    >
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <img className="w-7" src={depositSwitch.logoUrl} alt="" />
                    <div className="sm:block hidden">{depositSwitch.name}</div>
                    <div className="sm:block hidden">- {depositSwitch.bankAccount}</div>
                    <div className="sm:block hidden">- {depositSwitch.bankOwnerName}</div>
                    <span className="flex">
                      <span className="sm:block hidden mr-1">/</span>
                      <span>{values.depositList[index].isActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}</span>
                    </span>
                  </div>
                </div>
                <div className="sm:hidden block">
                  <div>ชื่อธนาคาร : {depositSwitch.name}</div>
                  <div>เลขบัญชี : {depositSwitch.bankAccount}</div>
                  <div>ชื่อบัญชี : {depositSwitch.bankOwnerName}</div>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )
    })
  }
  const renderGroupDeposit = (depositGroup: IDepositList[]): React.ReactNode => {
    if (depositGroup.length <= 0) return (<>No data</>)
    return depositGroup.map((depositHead: IDepositList, index: number) => {
      return (
        <div className="rounded-lg bg-background-1 p-2.5" key={index}>
          <div>
            Group name : {depositHead.groupBankName}
          </div>
          <div>
            {renderSwitchGroup(depositHead.depositList, depositHead.groupBankId)}
          </div>
        </div>
      )
    })
  }
  return (
    <div>
      <BreadCrump headTitle="ธนาคาร" showIcon={true} title={MENU_TITLE.BOT_DEPOSIT} />
      <div className="mt-6 w-full border-b border-0 pb-5 border-background-9 scroll scrollbar-hide">
        <div
          className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
          {renderSwitch()}
        </div>
        <label
          className="text-16px text-color-1">
          {depositHeadSwitch ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
        </label>
        <div>
          <Button
            buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-3 text-16px"
            buttonText="บันทึก"
            onClick={(): void => submitActiveDeposit()}
            type="button"
          />
        </div>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-2 pt-5">
        {renderGroupDeposit(depositGroup.list)}
      </div>
    </div>
  )
}

export default withLayout(botWithDraw, WEB_TITLE.BOT, WEB_TITLE.BOT)