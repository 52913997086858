import withLayout from '../../../layout/withLayout'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import userApi from '../../../api/user.api'
import bankApi from '../../../api/bank.api'
import { Formik, FormikProps, useFormik } from 'formik'
import Button from '../../../components/Button'
import { useHistory } from 'react-router-dom'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import schema from '../../../validation/userSchema'
import WEB_PATHS from '../../../constant/webPath'
import roleData from '../../../constant/roleData'
import IUserMember from '../../../model/UserApi/IUserMember'
import useModal from '../../../hook/useModal'
import Modal from '../../../components/Modal'
import IUserAddAmount from '../../../model/UserApi/IUserAddAmount'
import IBankDeposit from '../../../model/BankAdminApi/IBankDeposit'
import schemaUserAddAmount from '../../../validation/userAddAmount'
import { zonedTimeToUtc } from 'date-fns-tz'
import { dateFormat } from '../../../utils/dateFormat'
import { MENU_TITLE } from '../../../constant/menuTitle'
import TabMenu from '../layout/index'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IUserSubAmount from '../../../model/UserApi/IUserSubAmount'
import userSubAmountSchema from '../../../validation/userSubAmount'

interface IBankGroup {
  id: number,
  title: string
}

const EditAdmin = () => {
  const { id }: any = useParams()
  const { stateModal, onChangeStateModal } = useModal()
  const [showModalSubAmount, setShowModalSubAmount] = useState(false)
  const history = useHistory()
  const wallet: IUserAddAmount = {
    userId: id,
    amount: 0,
    bankAccount: '',
    transferredAt: dateFormat(new Date(), 'yyyy-MM-dd'),
    hours: new Date().getHours() <= 9 ? String('0' + new Date().getHours()) : String(new Date().getHours()),
    minute: new Date().getMinutes() <= 9 ? String('0' + new Date().getMinutes()) : String(new Date().getMinutes())
  }
  const genTime = (time: number): string[] => {
    const element = []
    for (let index = 0; index < time; index++) {
      element.push(index > 9 ? String(index) : '0' + index)
    }
    return element
  }
  const hoursTime = genTime(24)
  const minuteTime = genTime(60)
  const [bankGroup, setBankgroup] = useState<[]>([])
  const [bankDeposit, setBankDeposit] = useState<IBankDeposit[]>([])
  const [amount, setAmount] = useState<number>(0)
  const fetchUserWallet = async (id: any): Promise<void> => {
    const response = await userApi.getUserMemberWallet(id)
    setAmount(response)
  }
  const fetchBankSelect = async (): Promise<void> => {
    try {
      const response = await bankApi.getBankDeposit()
      setBankDeposit(response)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
    }
  }
  useEffect(() => {
    if (stateModal) {
      fetchBankSelect()
    }
  }, [stateModal])
  const [user, setUser] = useState<IUserMember>({
    id: 0,
    bankAccount: '',
    bankName: '',
    bankId: '',
    bankOwnerName: '',
    childUsernames: null,
    firstName: '',
    lastName: '',
    fullName: '',
    parentUsername: null,
    phoneNumber: '',
    status: '',
    statusInt: 0,
    username: '',
    password: '',
    isBan: false,
    isGameBan: false,
    groupBankId: 0,
    affiliateCount: 0,
    affiliateClick: 0
  })
  const [bankList, setBankList] = useState([])
  useEffect(() => {
    (async function fetchData(): Promise<any> {
      try {
        const response = await userApi.getUserById(id)
        const bankResponse = await bankApi.getBank()
        const bankGroupSelect = await bankApi.bankGroup()
        setBankgroup(bankGroupSelect)
        setUser(response)
        setBankList(bankResponse)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
    fetchUserWallet(id)
  }, [])
  const backPage = (): any => {
    history.push({
      pathname: WEB_PATHS.USER_ADMIN
    })
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const onSubmitSubAmount = async (values: any): Promise<void> => {
    const newSubAmount = {
      userId: user.id,
      ...values,
      amount: Number(values.amount)
    }
    try {
      await userApi.updateSubAmountUserMemeberWallet(newSubAmount)
      toastActive({state: 'success', description: 'แก้ไขข้อมูลสำเร็จ', duration: 500})
      formik.resetForm()
      setShowModalSubAmount(false)
      fetchUserWallet(id)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถแก้ไขข้อมูลได้', duration: 500})
      setShowModalSubAmount(false)
      formik.resetForm()
    }
  }
  const formik: FormikProps<IUserSubAmount> = useFormik<IUserSubAmount>({
    initialValues: {
      amount: 0,
      remark: ''
    },
    validateOnChange: true,
    validationSchema: userSubAmountSchema,
    onSubmit: onSubmitSubAmount
  })
  const renderModalSubAmount = (): React.ReactElement => {
    return (
      <div className="fixed z-10 inset-0">
        <div className="flex justify-center">
          <div onClick={(): void => {
            setShowModalSubAmount(false)
            formik.resetForm()
          }} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <div className="w-80 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden scrollbar-hide shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="w-full flex justify-between pb-5 items-center">
                <div className="text-20px">ลดยอดเงินให้กับผู้ใช้งาน</div>
                <div>
                  <svg className="cursor-pointer hover:opacity-75" onClick={(): void => {
                      setShowModalSubAmount(false)
                      formik.resetForm()
                    }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 11.266C14 11.5802 13.89 11.8474 13.67 12.0673L12.0673 13.67C11.8474 13.89 11.5802 14 11.266 14C10.9517 14 10.6846 13.89 10.4646 13.67L7 10.2054L3.53535 13.67C3.31538 13.89 3.04826 14 2.73401 14C2.41975 14 2.15264 13.89 1.93266 13.67L0.329966 12.0673C0.109989 11.8474 0 11.5802 0 11.266C0 10.9517 0.109989 10.6846 0.329966 10.4646L3.79461 7L0.329966 3.53535C0.109989 3.31538 0 3.04826 0 2.73401C0 2.41975 0.109989 2.15264 0.329966 1.93266L1.93266 0.329966C2.15264 0.109989 2.41975 0 2.73401 0C3.04826 0 3.31538 0.109989 3.53535 0.329966L7 3.79461L10.4646 0.329966C10.6846 0.109989 10.9517 0 11.266 0C11.5802 0 11.8474 0.109989 12.0673 0.329966L13.67 1.93266C13.89 2.15264 14 2.41975 14 2.73401C14 3.04826 13.89 3.31538 13.67 3.53535L10.2054 7L13.67 10.4646C13.89 10.6846 14 10.9517 14 11.266Z" fill="#1E2339"/>
                  </svg>
                </div>
              </div>
              <Input
                name="amount"
                type="string"
                value={formik.values.amount}
                placeholder="กรอกจำนวนเงิน"
                textLabelTopInput="จำนวนเงิน"
                inputMode="numeric"
                showTextLabelTopInput={true}
                classNameLabel="text-text-2 mb-2"
                inputClassName="w-full bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
                divClassName="flex flex-col mt-2 text-left"
                disabled={false}
                onChange={formik.handleChange}
                error={
                  formik.errors &&
                  formik.errors.amount
                }
              />
              <Input
                name="remark"
                type="string"
                value={formik.values.remark}
                placeholder="กรุณากรอกหมายเหตุ"
                textLabelTopInput="หมายเหตุ"
                showTextLabelTopInput={true}
                classNameLabel="text-text-2 mb-2"
                inputClassName="w-full bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
                divClassName="flex flex-col mt-2 text-left"
                disabled={false}
                onChange={formik.handleChange}
                error={
                  formik.errors &&
                  formik.errors.remark
                }
              />
            </div>
            <div className="flex p-4">
              <button
                type="button"
                onClick={(): void => {
                  formik.handleSubmit()
                }}
                className="px-2 py-3 w-full rounded-lg bg-background-2 text-white hover:bg-background-3">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderModalAmount = (): React.ReactElement => {
    return (
      <div className="fixed z-10 inset-0">
        <div className="flex justify-center">
          <div onClick={onChangeStateModal} className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" aria-hidden="true"></div>
          <div className="w-80 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden scrollbar-hide shadow-xl transform transition-all sm:my-8 mt-[100px] sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white p-4">
              <Formik
                initialValues={{
                  userId: wallet.userId,
                  amount: wallet.amount,
                  bankAccount: wallet.bankAccount,
                  transferredAt: wallet.transferredAt,
                  hours: wallet.hours,
                  minute: wallet.minute
                }}
                enableReinitialize={true}
                validationSchema={schemaUserAddAmount}
                onSubmit={async (values): Promise<void> => {
                  const data: IUserAddAmount = {
                    userId: Number(values.userId),
                    amount: Number(values.amount),
                    bankAccount: values.bankAccount,
                    transferredAt: zonedTimeToUtc(new Date(values.transferredAt), 'Asia/Bangkok').toUTCString(),
                  }
                  if (Number(data.amount) > 0) {
                    try {
                      await userApi.updateUserMemeberWallet(data)
                      toastActive({state: 'success', description: 'เพิ่มเงินให้กับผู้ใช้งานเรียบร้อย', duration: 500})
                      onChangeStateModal()
                      fetchUserWallet(id)
                    } catch (error: any) {
                      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถเพิ่มเงินให้กับผู้ใช้งานได้', duration: 500})
                    }
                  }
                }}
              >
              {({ handleSubmit, values, handleChange, errors, setFieldValue }): React.ReactElement => (
                <form onSubmit={handleSubmit}>
                  <div className="bg-white">
                    <div className="w-full flex justify-between pb-5 items-center">
                      <div className="text-20px">เพิ่มยอดเงินให้กับผู้ใช้งาน</div>
                      <div>
                        <svg className="cursor-pointer hover:opacity-75" onClick={onChangeStateModal} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 11.266C14 11.5802 13.89 11.8474 13.67 12.0673L12.0673 13.67C11.8474 13.89 11.5802 14 11.266 14C10.9517 14 10.6846 13.89 10.4646 13.67L7 10.2054L3.53535 13.67C3.31538 13.89 3.04826 14 2.73401 14C2.41975 14 2.15264 13.89 1.93266 13.67L0.329966 12.0673C0.109989 11.8474 0 11.5802 0 11.266C0 10.9517 0.109989 10.6846 0.329966 10.4646L3.79461 7L0.329966 3.53535C0.109989 3.31538 0 3.04826 0 2.73401C0 2.41975 0.109989 2.15264 0.329966 1.93266L1.93266 0.329966C2.15264 0.109989 2.41975 0 2.73401 0C3.04826 0 3.31538 0.109989 3.53535 0.329966L7 3.79461L10.4646 0.329966C10.6846 0.109989 10.9517 0 11.266 0C11.5802 0 11.8474 0.109989 12.0673 0.329966L13.67 1.93266C13.89 2.15264 14 2.41975 14 2.73401C14 3.04826 13.89 3.31538 13.67 3.53535L10.2054 7L13.67 10.4646C13.89 10.6846 14 10.9517 14 11.266Z" fill="#1E2339"/>
                        </svg>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <Input
                          name="amount"
                          type="text"
                          value={values.amount}
                          placeholder="กรอกจำนวนเงิน"
                          textLabelTopInput="จำนวนเงิน"
                          inputMode="numeric"
                          showTextLabelTopInput={false}
                          classNameLabel="text-text-2 mb-2"
                          inputClassName="w-full bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
                          divClassName="flex flex-col mt-2 text-left"
                          disabled={false}
                          onChange={handleChange}
                          error={
                            errors &&
                            errors.amount
                          }
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-col text-left">
                          <label className="text-black mb-2">บัญชีธนาคาร</label>
                          <select
                            className="w-full px-3 py-1.5 text-left bg-input-0 border border-border-0 rounded-lg"
                            name="bankAccount"
                            value={values.bankAccount}
                            onChange={handleChange}
                            aria-label="select"
                          >
                            <option value="">กรุณาเลือกบัญชีธนาคาร</option>
                            {bankDeposit.map((bankDepositData: IBankDeposit, index: number) => {
                                return (
                                  <option key={index} value={bankDepositData.bankAccount}>
                                    {bankDepositData.name} - {bankDepositData.bankAccount}
                                  </option>
                                )
                            })}
                          </select>
                          {errors.bankAccount
                            ? <label
                              className="block text-red-500 text-right">
                                {errors.bankAccount}
                              </label>
                            : null
                          }
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <Input
                          name="transferredAt"
                          type="date"
                          value={values.transferredAt}
                          placeholder=""
                          textLabelTopInput="วันที่ทำรายการ"
                          showTextLabelTopInput={true}
                          classNameLabel="text-text-2 mb-2"
                          inputClassName="w-full bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
                          divClassName="flex flex-col mt-2 text-left"
                          disabled={false}
                          onChange={(date): void => {
                            setFieldValue('transferredAt', date.target.value as Date)
                          }}
                          error={
                            errors &&
                            errors.transferredAt
                          }
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="text-black my-2">เวลาที่ทำรายการ</div>
                        <div className="flex flex-row flex-1">
                          <div className="w-full mr-1">
                            <select
                              className="w-full px-3 py-1.5 bg-input-0 border border-border-0 rounded-lg"
                              name="hours"
                              value={values.hours}
                              onChange={handleChange}
                              aria-label="select"
                            >
                              <option value="">ชั่วโมงที่โอน</option>
                              {hoursTime.map((hoursTimeDate: string, index: number) => {
                                  return (
                                    <option key={index} value={hoursTimeDate}>
                                      {hoursTimeDate}
                                    </option>
                                  )
                              })}
                            </select>
                            {errors.hours
                              ? <label
                                className="block text-red-500 text-right">
                                  {errors.hours}
                                </label>
                              : null
                            }
                          </div>
                          <div className="w-full">
                            <select
                              className="w-full px-3 py-1.5 bg-input-0 border border-border-0 rounded-lg"
                              name="minute"
                              value={values.minute}
                              onChange={handleChange}
                              aria-label="select"
                            >
                              <option value="">นาทีที่โอน</option>
                              {minuteTime.map((minuteTimeDate: string, index: number) => {
                                  return (
                                    <option key={index} value={minuteTimeDate}>
                                      {minuteTimeDate}
                                    </option>
                                  )
                              })}
                            </select>
                            {errors.minute
                              ? <label
                                className="block text-red-500 text-right">
                                  {errors.minute}
                                </label>
                              : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex pt-5">
                    <button type="submit" className="px-2 py-3 w-full rounded-lg bg-background-2 text-white hover:bg-background-3">เพิ่มยอดเงิน</button>
                  </div>
                </form>
              )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.ADMIN_EDIT}</span>
      </div>
      <div>
        <TabMenu state={MENU_TITLE.ADMIN_EDIT} id={id} type="admin" />
      </div>
      <div className="sm:w-2/4 flex sm:flex-row flex-col sm:flex-1 pt-3.5">
        <div className="py-2 px-3 flex justify-between sm:w-[400px] w-full bg-white">
          <span className="text-text-2">ยอดเงินคงเหลือ</span>
          <span className="text-text-0">{amount.toLocaleString('th')} THB</span>
        </div>
        <button
          onClick={(): void => onChangeStateModal()}
          className="bg-background-10 hover:bg-background-11 sm:w-[300px] w-full text-16px py-2 px-3 sm:ml-3 mt-3 sm:mt-0 text-white rounded-lg">
            เพิ่มยอดเงินคงเหลือ
        </button>
      </div>
      <button
        onClick={(): void => history.push({pathname: WEB_PATHS.USER_AFFILIATE.replace(':id', id)})}
        className="bg-background-5 hover:bg-background-10 bg-back w-auto text-16px py-2 px-3 mt-3 text-white rounded-lg">
          {`จำนวนในการเข้าสมัครผ่านลิ้งค์ช่วยแชร์ ${user.affiliateClick} ครั้ง / ลูกค่ายทั้งหมด ${user.affiliateCount} คน `}
      </button>
      <Formik
      initialValues={{
        id: user.id,
        bankAccount: user.bankAccount,
        bankId: user.bankId,
        bankOwnerName: user.bankOwnerName,
        childUsernames: user.childUsernames,
        firstName: user.firstName,
        lastName: user.lastName,
        parentUsername: user.parentUsername,
        phoneNumber: user.phoneNumber,
        status: user.status,
        statusInt: user.statusInt,
        username: user.username,
        password: user.password,
        isBan: user.isBan,
        isGameBan: user.isGameBan,
        groupBankId: user.groupBankId
      }}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values): Promise<void> => {
        const data = {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          bankId: Number(values.bankId),
          statusInt: Number(values.statusInt),
          bankAccount: values.bankAccount,
          bankOwnerName: values.bankOwnerName,
          password: values.password,
          isBan: values.isBan,
          isGameBan: values.isGameBan,
          groupBankId: values.groupBankId
        }
        try {
            await userApi.saveUserById(data)
            toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
            history.push({
              pathname: WEB_PATHS.USER_ADMIN,
              search: `?qs=&take=5&skip=0`
            })
        } catch (e) {
          alert(e)
        }
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, errors, touched }): React.ReactElement => (
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 pb-3">
          <Input
            name="username"
            type="text"
            value={values.username}
            placeholder="กรุณาพิมชื่อผู้ใช้งาน"
            textLabelTopInput="ชื่อผู้ใช้งาน"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full bg-gray-300 rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={true}
          />
          <Input
            name="password"
            type="text"
            value={values.password}
            placeholder="ถ้าเปลี่ยนรหัสผ่านกรุณากรอกรหัสผ่านใหม่"
            textLabelTopInput="รหัสผ่าน"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.password && errors.password}
          />
          <div className="flex flex-col mt-2 text-left">
            <div className="flex flex-col text-lef">
              <label className="text-black mb-2">ประเภทกรุ๊ปธนาคาร</label>
              <select
                className="w-full px-3 py-1.5 bg-white rounded-lg text-sm shadow border border-gray-300 placeholder-gray-500 text-gray-900 bg-no-repeat bg-right"
                name="groupBankId"
                value={values.groupBankId}
                onBlur={handleBlur}
                onChange={handleChange}
                aria-label="select"
              >
                <option value="">กรุณาเลือกประเภท</option>
                {bankGroup.map((bankGroupData: IBankGroup, index: number) => {
                    return (
                      <option key={index} value={bankGroupData.id}>
                        {bankGroupData.title}
                      </option>
                    )
                })}
              </select>
              {touched.groupBankId ? <p className="text-red-500 text-xs pt-0.5">{errors.groupBankId}</p> : null}
            </div>
          </div>
          <InputSelect
            name="bankId"
            options={bankList}
            value={values.bankId}
            textLabelTopSelect="บัญชีธนาคาร"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกบัญชีธนาคาร"
            showNumberAndText={false}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankId && errors.bankId}
          />
          <Input
            name="bankOwnerName"
            type="text"
            value={values.bankOwnerName}
            placeholder="กรุณาพิมชื่อบัญชีธนาคาร"
            textLabelTopInput="ชื่อบัญชีธนาคาร"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankOwnerName && errors.bankOwnerName}
          />
          <Input
            name="bankAccount"
            type="text"
            value={values.bankAccount}
            placeholder="กรุณาพิมเลขบัญชีธนาคาร"
            textLabelTopInput="เลขบัญชีธนาคาร"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.bankAccount && errors.bankAccount}
          />
          <Input
            name="firstName"
            type="text"
            value={values.firstName}
            placeholder="กรุณาพิมชื่อจริง"
            textLabelTopInput="ชื่อจริง"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.firstName && errors.firstName}
          />
          <Input
            name="lastName"
            type="text"
            value={values.lastName}
            placeholder="กรุณาพิมนามสกุล"
            textLabelTopInput="นามสกุล"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.lastName && errors.lastName}
          />
          <Input
            name="phoneNumber"
            type="text"
            value={values.phoneNumber}
            placeholder="กรุณาพิมหมายเลขมือถือ"
            textLabelTopInput="เบอร์มือถือ"
            showTextLabelTopInput={true}
            classNameLabel="text-gray-500 mb-2"
            inputClassName="w-full rounded-lg"
            divClassName="flex flex-col mt-2 text-left"
            disabled={false}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.phoneNumber && errors.phoneNumber}
          />
          <InputSelect
            name="statusInt"
            options={roleData}
            value={Number(values.statusInt)}
            textLabelTopSelect="ประเภทสมาชิก"
            showTextLabelTopInput={true}
            labelOptionText="กรุณาเลือกประเภทสมาชิก"
            showNumberAndText={false}
            classNameLabel="text-black text-gray-500 mb-2"
            divClassName="flex flex-col mt-2 text-left"
            inputClassName="w-full px-3 py-1.5 rounded-lg"
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.statusInt && errors.statusInt}
          />
          <div className="flex flex-col mt-2 text-left">
            <label className="text-gray-500 mb-2">สถานะ</label>
            <div className="flex">
              <div
                className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                onClick={(): void => setFieldValue('isBan', !values.isBan)}
                >
                <input
                    type="checkbox"
                    name="isBan"
                    defaultChecked={values.isBan}
                    value={String(values.isBan)}
                    className={ values.isBan
                      ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
                      : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
                    }
                  />
                  <label className={ values.isBan
                    ? 'toggle-label h-6 block rounded-full bg-background-10 cursor-pointer'
                    : 'toggle-label h-6 block rounded-full bg-background-9 cursor-pointer'
                    }>
                  </label>
              </div>
              <label className="pl-2">{values.isBan ? 'แบนผู้ใช้คนนี้' : 'ยกเลิกแบนของผู้ใช้คนนี้แล้ว'}</label>
            </div>
            <div className="flex mt-4">
              <div
                className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                onClick={(): void => setFieldValue('isGameBan', !values.isGameBan)}
                >
                <input
                    type="checkbox"
                    name="isGameBan"
                    defaultChecked={values.isGameBan}
                    value={String(values.isGameBan)}
                    className={ values.isGameBan 
                      ? 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border border-white appearance-none cursor-pointer top-0.5 right-0.5'
                      : 'toggle-checkbox absolute block w-5 h-5 rounded-full bg-white appearance-none cursor-pointer top-0.5 left-0.5'
                    }
                  />
                  <label className={ values.isGameBan 
                    ? 'toggle-label h-6 block rounded-full bg-background-10 cursor-pointer'
                    : 'toggle-label h-6 block rounded-full bg-background-9 cursor-pointer'
                    }>
                  </label>
              </div>
              <label className="pl-2">{values.isGameBan ? 'แบนการเล่นเกมทั้งหมด' : 'ยกเลิกการแบนเล่นเกมทั้งหมด'}</label>
            </div>
          </div>
        </div>
        <Button
          buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
          buttonText="บันทึก"
          type="submit"
        />
      </form>
      )}
    </Formik>
    {
        showModalSubAmount &&
        <Modal element={renderModalSubAmount()} />
      }
    {
      stateModal &&
      <Modal element={renderModalAmount()} />
    }
    </div>
  )
}

export default withLayout(EditAdmin, 'Admin')