import classnames from 'classnames'

const Input = ({
  name,
  type,
  value,
  disabled,
  inputMode,
  placeholder,
  textLabelTopInput,
  showTextLabelTopInput,
  minDate,
  classNameLabel,
  inputClassName,
  divClassName,onChange,onBlur,error
}: {
  name: string
  type: string
  value: string | number
  inputMode?: "search" | "text" | "none" | "numeric" | "tel" | "url" | "email" | "decimal" | undefined
  disabled?: boolean
  placeholder: string
  textLabelTopInput?: string
  showTextLabelTopInput?: boolean
  minDate?: string | ""
  classNameLabel?: string
  inputClassName?: string
  divClassName?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  error?: any
}): React.ReactElement => {
  return (
    <div
      className={classnames(divClassName, 'relative')}>
      <label className={classnames(showTextLabelTopInput ? '' : 'hidden', classNameLabel)}>{textLabelTopInput}</label>
      <input
        type={type}
        className={classnames(
          'shadow-sm py-2 pl-3 sm:pr-[30px] pr-[22px]',
          inputClassName
        )}
        placeholder={placeholder}
        inputMode={inputMode}
        onChange={onChange}
        onBlur={onBlur}
        min={minDate}
        disabled={disabled}
        value={value}
        name={name}
      />
      {error ? 
      <svg className={showTextLabelTopInput ? 'absolute right-1 sm:right-2 sm:top-[45px] top-[42px]' : 'absolute right-1 sm:right-3 sm:top-4 top-2'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6568 2.34315C12.538 1.22433 11.1126 0.462404 9.56072 0.153722C8.00887 -0.15496 6.40034 0.00346745 4.93853 0.608969C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.46919 4.87104 -8.37707e-07 6.41775 -6.99382e-07 8C-6.07538e-07 9.05058 0.206925 10.0909 0.608963 11.0615C1.011 12.0321 1.60028 12.914 2.34314 13.6569C3.08601 14.3997 3.96792 14.989 4.93853 15.391C5.90913 15.7931 6.94942 16 8 16ZM7.2 4.8C7.2 4.58783 7.28428 4.38435 7.43431 4.23432C7.58434 4.08429 7.78782 4 8 4C8.21217 4 8.41565 4.08429 8.56568 4.23432C8.71571 4.38435 8.8 4.58783 8.8 4.8L8.8 8.8C8.8 9.01218 8.71571 9.21566 8.56568 9.36569C8.41565 9.51572 8.21217 9.6 8 9.6C7.78782 9.6 7.58434 9.51572 7.43431 9.36569C7.28428 9.21566 7.2 9.01218 7.2 8.8L7.2 4.8ZM8 10.4C8.15822 10.4 8.31289 10.4469 8.44445 10.5348C8.57601 10.6227 8.67855 10.7477 8.7391 10.8939C8.79965 11.04 8.81549 11.2009 8.78462 11.3561C8.75376 11.5113 8.67756 11.6538 8.56568 11.7657C8.4538 11.8776 8.31125 11.9538 8.15607 11.9846C8.00088 12.0155 7.84003 11.9997 7.69385 11.9391C7.54767 11.8786 7.42273 11.776 7.33482 11.6445C7.24692 11.5129 7.2 11.3582 7.2 11.2C7.2 10.9878 7.28428 10.7843 7.43431 10.6343C7.58434 10.4843 7.78782 10.4 8 10.4Z" fill="#D83434"/>
      </svg>
      : null
      }
      {error ? 
        <label className="flex items-center pt-1.5">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM6.54545 3.27273C6.54545 3.12806 6.48799 2.98933 6.38569 2.88703C6.2834 2.78474 6.14466 2.72727 6 2.72727C5.85534 2.72727 5.7166 2.78474 5.61431 2.88703C5.51201 2.98933 5.45455 3.12806 5.45455 3.27273V6.54545C5.45455 6.69012 5.51201 6.82886 5.61431 6.93115C5.7166 7.03344 5.85534 7.09091 6 7.09091C6.14466 7.09091 6.2834 7.03344 6.38569 6.93115C6.48799 6.82886 6.54545 6.69012 6.54545 6.54545V3.27273ZM6.54545 8.45455C6.54545 8.30988 6.48799 8.17114 6.38569 8.06885C6.2834 7.96656 6.14466 7.90909 6 7.90909C5.85534 7.90909 5.7166 7.96656 5.61431 8.06885C5.51201 8.17114 5.45455 8.30988 5.45455 8.45455V8.72727C5.45455 8.87194 5.51201 9.01068 5.61431 9.11297C5.7166 9.21526 5.85534 9.27273 6 9.27273C6.14466 9.27273 6.2834 9.21526 6.38569 9.11297C6.48799 9.01068 6.54545 8.87194 6.54545 8.72727V8.45455Z" fill="#DC1B1B"/>
          </svg>
          <p className="text-red-500 text-xs text-left pl-1">{error}</p>
        </label>
        : null
      }
    </div>
    
  )
}

export default Input