import { Formik } from 'formik'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import schema from '../../../validation/bankGroupSchema'
import bankApi from '../../../api/bank.api'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import withLayout from '../../../layout/withLayout'
import { MENU_TITLE } from '../../../constant/menuTitle'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'

interface IBankGroup {
  id: number
  title: string
  depositCount: number
}

const BankGroupEdit = () => {
  const { id }: any = useParams()
  const history = useHistory()
  const titleHeader = MENU_TITLE.BANK_GROUP_EDIT

  const [bankGroup, setBankGroup] = useState<IBankGroup>({
    id: 0,
    title: '',
    depositCount: 0
  })
  useEffect(() => {
    (async function fetchData(): Promise<any> {
      try {
        const bank = await bankApi.getBankGroupById(id)
        setBankGroup(bank)
      } catch (error) {
        toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
      }
    })()
  }, [])
  const backPage = (): any => {
    history.push('/bank/group/list')
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <Formik
      initialValues={{
        id: bankGroup.id,
        title: bankGroup.title,
        depositCount: bankGroup.depositCount
      }}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values): Promise<void> => {
        try {
          const newData = {...values, depositCount: Number(values.depositCount)}
          await bankApi.updateBankGroup(newData)
          toastActive({state: 'success', description: 'แก้ไขกรุ๊ปธนาคารสำเร็จ', duration: 500})
          history.push({pathname: '/bank/group/list'})
        } catch (e) {
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถแก้ไขกรุ๊ปธนาคารได้', duration: 500})
        }
      }}
    >
    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }): React.ReactElement => (
      <form onSubmit={handleSubmit}>
        <div className="text-2xl flex items-center cursor-pointer max-w-max" onClick={ () => backPage() }>
          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
          </svg>
          <span className="ml-3 text-20px font-katnitBold">{titleHeader}</span>
        </div>
        <div className="pt-6">
          <div className="flex flex-col">
            <Input
              name="title"
              type="text"
              value={values.title}
              placeholder="กรุณาใส่ข้อความ"
              textLabelTopInput="ชื่อกรุ๊ปแบงก์"
              showTextLabelTopInput={true}
              classNameLabel="text-text-2 mb-2 text-16px"
              inputClassName="rounded-lg"
              divClassName="flex flex-col sm:w-2/4 w-full text-left"
              disabled={false}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.title && errors.title}
            />
            <Input
              name="depositCount"
              type="text"
              value={values.depositCount}
              placeholder="กรอกจำนวนเงิน"
              textLabelTopInput="จำนวนการโอนเงินเข้าของสมาชิก"
              showTextLabelTopInput={true}
              classNameLabel="text-text-2 mb-2 text-16px"
              inputClassName="rounded-lg"
              divClassName="flex flex-col sm:w-2/4 w-full text-left pt-2"
              disabled={false}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.depositCount && errors.depositCount}
            />
            <Button
              type="submit"
              buttonClassName="bg-background-2 hover:bg-background-8 max-w-max text-white py-2 px-3 mt-4 text-16px"
              buttonText="บันทึก"
            />
          </div>
        </div>
      </form>
    )}
    </Formik>
  )
}

export default withLayout(BankGroupEdit, 'Bank Group', MENU_TITLE.BANK_GROUP_EDIT)