import usePortal from '../hook/usePortal'

const ModalConfirms = ({
  titleHeader,
  detailHeader,
  btnButtonConfirm,
  btnButtonCancel,
  textButtonConfirm,
  textButtonCancel,
  showModalConfirm,
  showRemark,
  customElement
}: {
  titleHeader: string
  detailHeader: string
  btnButtonConfirm?: () => void
  btnButtonCancel?: () => void
  textButtonConfirm: string
  textButtonCancel: string
  showModalConfirm?: boolean
  showRemark?: boolean
  customElement?: React.ReactElement
}): React.ReactElement => {
  if (showModalConfirm) {
    document.onkeydown = (evt): void => {
      evt = evt || window.event
      let isEscape = false
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc')
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape) {
        btnButtonCancel
      }
    }
  }
  const renderModal = (): React.ReactElement => {
    return (
      <div id="modalConfirm" className={
        showModalConfirm
          ? 'fixed z-40 inset-0 flex items-center justify-center min-h-screen'
          : 'fixed -z-30 inset-0'
      }>
        <div
          onClick={btnButtonCancel}
          className={
            showModalConfirm
              ? 'overflow-auto scrollbar-hide inset-0 w-full h-full z-50 fixed bg-modal-0 bg-opacity-80 transition-opacity'
              : 'hidden'
          }></div>
        <div className={
          showModalConfirm
            ? 'z-50 opacity-100 transition-all duration-300 bg-background-1 rounded-lg shadow-card'
            : 'z-0 opacity-0 duration-500 bg-background-1 rounded-lg'
        }>
          <div className="min-w-fit w-80 p-6">
            <div className="text-white">
              <div className="flex justify-between items-center">
                <div className="text-20px w-full font-katnitBold text-text-2">
                  {titleHeader}
                </div>
                <div>
                  <svg onClick={btnButtonCancel} className="cursor-pointer hover:opacity-70" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 11.266C14 11.5802 13.89 11.8474 13.67 12.0673L12.0673 13.67C11.8474 13.89 11.5802 14 11.266 14C10.9517 14 10.6846 13.89 10.4646 13.67L7 10.2054L3.53535 13.67C3.31538 13.89 3.04826 14 2.73401 14C2.41975 14 2.15264 13.89 1.93266 13.67L0.329966 12.0673C0.109989 11.8474 0 11.5802 0 11.266C0 10.9517 0.109989 10.6846 0.329966 10.4646L3.79461 7L0.329966 3.53535C0.109989 3.31538 0 3.04826 0 2.73401C0 2.41975 0.109989 2.15264 0.329966 1.93266L1.93266 0.329966C2.15264 0.109989 2.41975 0 2.73401 0C3.04826 0 3.31538 0.109989 3.53535 0.329966L7 3.79461L10.4646 0.329966C10.6846 0.109989 10.9517 0 11.266 0C11.5802 0 11.8474 0.109989 12.0673 0.329966L13.67 1.93266C13.89 2.15264 14 2.41975 14 2.73401C14 3.04826 13.89 3.31538 13.67 3.53535L10.2054 7L13.67 10.4646C13.89 10.6846 14 10.9517 14 11.266Z" fill="#1E2339"/>
                  </svg>
                </div>
              </div>
              <div className="mt-5 text-left">
                <p className="text-16px text-text-2">{detailHeader}</p>
              </div>
            </div>
            <div className={ showRemark ? 'py-3 block' : 'hidden'}>
              {customElement}
            </div>
            <div className="flex flex-1 text-primary-text-1 pt-3 gap-2.5">
              <button
                type="button"
                onClick={btnButtonCancel}
                className="block w-full py-2 border bg-background-1 border-border-2 hover:text-text-4 hover:border-border-1 hover:bg-secondary-color-0 rounded-lg md:order-1">
                {textButtonCancel}
              </button>
              <button
                type="button"
                onClick={btnButtonConfirm}
                className="block w-full py-2 text-white hover:text-white bg-background-13 hover:bg-background-14 rounded-lg md:order-2">
                {textButtonConfirm}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return usePortal([renderModal(),'#myportal'])
}

export default ModalConfirms