import * as Yup from 'yup'

const schema = Yup.object({
  title: Yup.string()
    .required('required'),
  depositCount: Yup.string()
    .required('required')
    .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น'),
});

export default schema