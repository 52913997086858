const ToastStateIcon = ({ stateIcon }: { stateIcon: string }): React.ReactElement => {
  const onStateShow = (state: string): React.ReactElement => {
    switch (state) {
      case 'success':
        return (
          <div className="w-auto flex justify-items-center items-center bg-background-1 rounded-l-lg h-full">
            <div className="py-2.5 px-3">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24183 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z" fill="#34D844"/>
              </svg>
            </div>
          </div>
        )
      case 'warning':
        return (
          <div className="w-auto flex justify-items-center items-center bg-background-1 rounded-l-lg h-full">
            <div className="py-2.5 px-3">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8.8 11.2C8.8 11.4122 8.71572 11.6157 8.56569 11.7657C8.41566 11.9157 8.21218 12 8 12C7.78783 12 7.58435 11.9157 7.43432 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V7.2C7.2 6.98783 7.28429 6.78434 7.43432 6.63431C7.58435 6.48428 7.78783 6.4 8 6.4C8.21218 6.4 8.41566 6.48428 8.56569 6.63431C8.71572 6.78434 8.8 6.98783 8.8 7.2V11.2ZM8 5.6C7.84178 5.6 7.68711 5.55308 7.55555 5.46517C7.42399 5.37727 7.32145 5.25233 7.2609 5.10615C7.20035 4.95997 7.18451 4.79911 7.21537 4.64393C7.24624 4.48874 7.32244 4.3462 7.43432 4.23431C7.5462 4.12243 7.68875 4.04624 7.84393 4.01537C7.99912 3.9845 8.15997 4.00035 8.30615 4.0609C8.45233 4.12145 8.57727 4.22398 8.66518 4.35554C8.75308 4.4871 8.8 4.64177 8.8 4.8C8.8 5.01217 8.71572 5.21565 8.56569 5.36568C8.41566 5.51571 8.21218 5.6 8 5.6Z" fill="#EABA11"/>
              </svg>
            </div>
          </div>
        )
      case 'error':
        return (
          <div className="w-auto flex justify-items-center items-center bg-background-1 rounded-l-lg h-full">
            <div className="py-2.5 px-3">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.388 10.5739C11.4997 10.4622 11.5556 10.3265 11.5556 10.1669C11.5556 10.0072 11.4997 9.87157 11.388 9.75983L9.62815 8.00001L11.388 6.24019C11.4997 6.12846 11.5556 5.99278 11.5556 5.83316C11.5556 5.67354 11.4997 5.53786 11.388 5.42613L10.5739 4.61206C10.4622 4.50033 10.3265 4.44446 10.1669 4.44446C10.0072 4.44446 9.87157 4.50033 9.75983 4.61206L8.00001 6.37188L6.24019 4.61206C6.12846 4.50033 5.99278 4.44446 5.83316 4.44446C5.67354 4.44446 5.53786 4.50033 5.42613 4.61206L4.61206 5.42613C4.50033 5.53786 4.44446 5.67354 4.44446 5.83316C4.44446 5.99278 4.50033 6.12846 4.61206 6.24019L6.37188 8.00001L4.61206 9.75983C4.50033 9.87157 4.44446 10.0072 4.44446 10.1669C4.44446 10.3265 4.50033 10.4622 4.61206 10.5739L5.42613 11.388C5.53786 11.4997 5.67354 11.5556 5.83316 11.5556C5.99278 11.5556 6.12846 11.4997 6.24019 11.388L8.00001 9.62815L9.75983 11.388C9.87157 11.4997 10.0072 11.5556 10.1669 11.5556C10.3265 11.5556 10.4622 11.4997 10.5739 11.388L11.388 10.5739Z" fill="#D83434"/>
              </svg>
            </div>
          </div>
        )
      default:
        return (
          <div></div>
        )
    }
  }
  return (
    <div>{onStateShow(stateIcon)}</div>
  )
}

export default ToastStateIcon