import withLayout from '../../layout/withLayout'
import { useEffect, useState } from 'react'
import dashboardApi from '../../api/dashboard.api'
import DashboardWallet from '../../model/dashboard'
import WEB_TITLE from '../../constant/webTitle'
import { useHistory } from 'react-router-dom'

const Dashboard = (): React.ReactElement => {
  const titleHeader = 'แดชบอร์ด'
  const history = useHistory()
  const [totalWallet, setTotalWallet] = useState<DashboardWallet>({
    amount: 0,
    count: 0
  })
  useEffect(() => {
    (async function fetchData(): Promise<void> {
      try {
        const response = await dashboardApi.walletAdmin()
        setTotalWallet({...response})
      } catch (error: any) {
        if (error.code === '401') {
          history.push('/')
        }
      }
    })()
  }, [])
  return (
    <div>
      <div className="text-20px pb-2.5 text-text-2 font-katnitBold">{titleHeader}</div>
      <div className="rounded-lg sm:w-72 w-3/4">
        <div className="py-2 px-3 bg-white rounded-lg">
          <div className="flex justify-between pb-3.5">
            <span className="text-text-2 text-16px">Wallet Total :</span>
            <span className="text-text-3 text-16px">{totalWallet.amount.toLocaleString('th')} บาท</span>
          </div>
          <div className="flex justify-between pt-3.5">
            <span className="text-text-2 text-16px">Count Total :</span>
            <span className="text-text-3 text-16px">{totalWallet.count.toLocaleString('th')} คน</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLayout(Dashboard, WEB_TITLE.DASHBOARD)