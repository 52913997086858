import withLayout from '../../../layout/withLayout'
import { useEffect, useState } from 'react'
import userApi from '../../../api/user.api'
import { useHistory } from 'react-router-dom'
import Paginations from '../../../components/Paginations'
import WEB_PATHS from '../../../constant/webPath'
import IUserMember from '../../../model/UserApi/IUserMember'
import { MENU_TITLE } from '../../../constant/menuTitle'
import BeradCrump from '../../../components/BreadCrump'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import ToastStateIcon from '../../../components/ToastStateIcon'

const UserList = (route: any): any => {
  const history = useHistory()
  const queryFilter = {
    qs: route.query.query.get('qs') ? route.query.query.get('qs') : '',
    take: route.query.query.get('take') ? Number(route.query.query.get('take')) : 5,
    skip: route.query.query.get('skip') ? Number(route.query.query.get('skip')) : 0
  }
  let isFocused = false
  const handleKeyDown = (event: any): void => {
    if (event.keyCode === 13) {
      searchGameSet()
    }
  }
  const [count, setCount] = useState<number>(0)
  const [user, setUserList] = useState<IUserMember[]>([])
  const [search, setSearch] = useState('')
  const fetchUserData = async () => {
    try {
      const { data, filters } = await userApi.getUserMember(queryFilter)
      setUserList(data)
      setCount(filters.count ? filters.count : 0)
    } catch (error) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
    }
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  useEffect(() => {
    fetchUserData()
  }, [route])
  useEffect(() => {
    const elemBetList = document.getElementById('search_')
    isFocused = (document.activeElement === elemBetList)
    if (isFocused) {
      window.addEventListener('keydown', handleKeyDown)
      return (): void => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleKeyDown])
  const searchGameSet = (): void => {
    history.replace({
      pathname: history.location.pathname,
      search: `?qs=${search}&take=5&skip=0`
    })
  }
  const editUser = (id: number) => {
    history.push({ pathname: WEB_PATHS.USER_EDIT.replace(':id', String(id))})
  }
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-[800px] pb-2.5">
        <div className="flex w-full items-center overflow-auto scrollbar-hide px-3">
          <div className="sm:w-[2.5%] w-[30px]">#</div>
          <div className="sm:w-[15%] w-[100px] text-left">username</div>
          <div className="sm:w-[15%] w-[100px] text-left">ชื่อ-นามสกุล</div>
          <div className="sm:w-[15%] w-[140px] text-left">ชื่อธนาคาร</div>
          <div className="sm:w-[15%] w-[120px] text-left">เลขที่บัญชี</div>
          <div className="sm:w-[14%] w-[100px] text-left">เบอร์โทรศัพท์</div>
          <div className="sm:w-[14%] w-[80px] text-left">สถานะ</div>
          <div className="sm:w-[9.5% w-[100px] px-2.5"></div>
        </div>
        {
          renderContent(user)
        }
      </div>
    )
  }
  const renderContent = (item: IUserMember[]): React.ReactNode => {
    if (item.length <= 0) {
      return (
        <div className="w-full items-center text-16px bg-background-1 px-2.5 py-2 text-center text-text-6">ยังไม่มีข้อมูล</div>
      )
    }
    return (
      <div className="px-2 w-full">
        {item.map((user, index) => {
          return (
            <div className={
              index === 0
              ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
              : index % 2 !== 0
              ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
              : 'flex items-center bg-white py-1.5 px-2 w-full'
            } key={index}
          >
              <div className="sm:w-[2.5%] w-[30px] text-text-6">{index +1}.</div>
              <div className="sm:w-[15%] w-[100px] overflow-hidden truncate text-16px text-text-6">{user.username}</div>
              <div className="sm:w-[15%] w-[100px] text-16px text-text-6 text-left">{user.fullName}</div>
              <div className="sm:w-[15%] w-[140px] overflow-hidden truncate text-16px text-text-6 text-left">{user.bankName}</div>
              <div className="sm:w-[15%] w-[120px] overflow-hidden truncate text-16px text-text-6 text-left">{user.bankAccount}</div>
              <div className="sm:w-[14%] w-[100px] overflow-hidden truncate text-16px text-text-6 text-left">{user.phoneNumber}</div>
              <div className="sm:w-[14%] w-[80px] overflow-hidden truncate text-16px text-text-6 text-left">{user.status}</div>
              <div className="sm:w-[9.5%] w-[100px]">
                <button onClick={(): void => editUser(Number(user.id))} className="group flex items-center justify-end w-full">
                  <svg className="group-hover:hidden block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#1E2339"/>
                    <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#1E2339"/>
                  </svg>
                  <svg className="group-hover:block hidden" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#0F2898"/>
                    <path d="M15.0769 10.7692C15.0769 9.58333 14.6554 8.56891 13.8125 7.72596C12.9696 6.88301 11.9551 6.46154 10.7692 6.46154C9.58333 6.46154 8.56891 6.88301 7.72596 7.72596C6.88301 8.56891 6.46154 9.58333 6.46154 10.7692C6.46154 11.9551 6.88301 12.9696 7.72596 13.8125C8.56891 14.6554 9.58333 15.0769 10.7692 15.0769C11.9551 15.0769 12.9696 14.6554 13.8125 13.8125C14.6554 12.9696 15.0769 11.9551 15.0769 10.7692ZM20 18.7692C20 19.1026 19.8782 19.391 19.6346 19.6346C19.391 19.8782 19.1026 20 18.7692 20C18.4231 20 18.1346 19.8782 17.9038 19.6346L14.6058 16.3462C13.4583 17.141 12.1795 17.5385 10.7692 17.5385C9.85256 17.5385 8.97596 17.3606 8.13942 17.0048C7.30288 16.649 6.58173 16.1683 5.97596 15.5625C5.37019 14.9567 4.88942 14.2356 4.53365 13.399C4.17788 12.5625 4 11.6859 4 10.7692C4 9.85256 4.17788 8.97596 4.53365 8.13942C4.88942 7.30288 5.37019 6.58173 5.97596 5.97596C6.58173 5.37019 7.30288 4.88942 8.13942 4.53365C8.97596 4.17788 9.85256 4 10.7692 4C11.6859 4 12.5625 4.17788 13.399 4.53365C14.2356 4.88942 14.9567 5.37019 15.5625 5.97596C16.1683 6.58173 16.649 7.30288 17.0048 8.13942C17.3606 8.97596 17.5385 9.85256 17.5385 10.7692C17.5385 12.1795 17.141 13.4583 16.3462 14.6058L19.6442 17.9038C19.8814 18.141 20 18.4295 20 18.7692Z" fill="#0F2898"/>
                  </svg>
                </button>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex sm:flex-row flex-col justify-between sm:pb-6 pb-2">
          <BeradCrump headTitle="ผู้ใช้งาน" showIcon={true} classTitle="font-katnitBold" title={MENU_TITLE.USER_LIST} />
        </div>
        <div className="sm:w-2/4 w-full">
          <div className="relative pb-3">
            <span
              className="absolute sm:-top-3 sm:bottom-0 bottom-0 -top-3 left-1 flex items-center">
              <button
                className="p-1"
                onClick={(): void => searchGameSet()}
              >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0769 6.76923C11.0769 5.58333 10.6554 4.56891 9.8125 3.72596C8.96955 2.88301 7.95513 2.46154 6.76923 2.46154C5.58333 2.46154 4.56891 2.88301 3.72596 3.72596C2.88301 4.56891 2.46154 5.58333 2.46154 6.76923C2.46154 7.95513 2.88301 8.96955 3.72596 9.8125C4.56891 10.6554 5.58333 11.0769 6.76923 11.0769C7.95513 11.0769 8.96955 10.6554 9.8125 9.8125C10.6554 8.96955 11.0769 7.95513 11.0769 6.76923ZM16 14.7692C16 15.1026 15.8782 15.391 15.6346 15.6346C15.391 15.8782 15.1026 16 14.7692 16C14.4231 16 14.1346 15.8782 13.9038 15.6346L10.6058 12.3462C9.45833 13.141 8.17949 13.5385 6.76923 13.5385C5.85256 13.5385 4.97596 13.3606 4.13942 13.0048C3.30288 12.649 2.58173 12.1683 1.97596 11.5625C1.37019 10.9567 0.889423 10.2356 0.533654 9.39904C0.177885 8.5625 0 7.6859 0 6.76923C0 5.85256 0.177885 4.97596 0.533654 4.13942C0.889423 3.30288 1.37019 2.58173 1.97596 1.97596C2.58173 1.37019 3.30288 0.889423 4.13942 0.533654C4.97596 0.177885 5.85256 0 6.76923 0C7.6859 0 8.5625 0.177885 9.39904 0.533654C10.2356 0.889423 10.9567 1.37019 11.5625 1.97596C12.1683 2.58173 12.649 3.30288 13.0048 4.13942C13.3606 4.97596 13.5385 5.85256 13.5385 6.76923C13.5385 8.17949 13.141 9.45833 12.3462 10.6058L15.6442 13.9038C15.8814 14.141 16 14.4295 16 14.7692Z" fill="#C0C0C0"/>
              </svg>
              </button>
            </span>
            <input
              name="search"
              id="search_"
              type="text"
              onChange={(e): void => setSearch(e.target.value)}
              className="w-full border rounded-lg border-border-0 placeholder:text-text-1 text-text-2 p-2 pl-8"
              placeholder="ค้นหา" />
          </div>
        </div>
        <div className="overflow-auto scrollbar-hide">
          {renderTable()}
        </div>
      </div>
      <div className="w-full flex justify-end pt-6">
        <Paginations
          filter={queryFilter}
          count={count}
          divClassName=""
        />
      </div>
    </div>
  )
}

export default withLayout(UserList, 'User', MENU_TITLE.USER_LIST)