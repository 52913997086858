import classnames from 'classnames'

const InputSelectOption = ({
  name,
  value,
  options,
  inputClassName,
  textLabelTopSelect,
  labelOptionText,
  showTextLabelTopInput,
  classNameLabel,
  divClassName,
  showNumberAndText,
  onChange,onBlur,error
}: {
  name: string
  value: string | number
  options: any[]
  onBlur: (e: any) => void
  onChange: (e: any) => void
  error?: any;
  inputClassName?: string
  labelOptionText: string
  showTextLabelTopInput: boolean
  classNameLabel: string
  showNumberAndText?: boolean
  divClassName: string
  textLabelTopSelect?: string
}): React.ReactElement => {
  return (
    <div className={classnames(divClassName)}>
      <label className={classnames(showTextLabelTopInput ? '' : 'hidden', classNameLabel)}>{textLabelTopSelect}</label>
      <select
        className={inputClassName +
          ' shadow w-full'}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        aria-label="select"
      >
        <option value="">{labelOptionText}</option>
        {options.map((o, index) => {
          if (!showNumberAndText) {
            return (
              <option key={index} value={o.id}>
                {o.name}
              </option>
            )
          } else {
            return (
              <option key={index} value={o.bankAccount}>
                {o.name} - {o.bankAccount} - {o?.bankOwnerName}
              </option>
            )
          }
        })}
      </select>
      {error ? <p className="text-red-500 text-xs pt-0.5">{error}</p> : null}
    </div>
  )
}
export default InputSelectOption;