import withLayout from '../../../layout/withLayout'
import { useParams, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import quickPanelApi from '../../../api/quickPanel.api'
import WEB_PATHS from '../../../constant/webPath'
import { MENU_TITLE } from '../../../constant/menuTitle'

interface IGameData {
  gameCateName: string
  gameCateDate: string
  gameCateId: number
  gameDetails: []
}
interface ITableGameData {
  contentHead: []
  details: []
}

const GameListItems = (): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const [gameData, setGameData] = useState<IGameData>({
    gameCateName: '',
    gameCateDate: '',
    gameCateId: 0,
    gameDetails: []
  })
  const fetchGameListItems = async (): Promise<void> => {
    try {
      const response = await quickPanelApi.getPanelNumberById(id, history.location.search.replace('?qs=', ''))
      setGameData({
        gameCateName: response.gameCateName,
        gameCateDate: response.gameCateDate,
        gameCateId: response.gameCateId,
        gameDetails: response.details !== null ? filterDetailItemGameLists(response.details) : []
      })
    } catch (e) {
      alert(e)
    }
  }
  const filterDetailItemGameLists = (item: any[]): any => {
    const newData: any = []
    const data = item.reduce((result, currentValue) => {
      (result[currentValue['gameCateTypeName']] = result[currentValue['gameCateTypeName']] || []).push( currentValue)
      return result
    }, {})
    Object.values(data).map((item: any, index: number) => {
      newData.push({
        contentHead: [{
          title: Object.keys(data)[index],
          key: 'playNumber'
        }, {
          title: 'จำนวนเงิน',
          key: 'playAmount'
        }],
        details: item
      })
    })
    return newData
  }

  useEffect(() => {
    fetchGameListItems()
  }, [])
  const renderTableShowListNumber = (): React.ReactNode => {
    if (gameData.gameDetails.length <= 0) return <div className="text-text-2 text-16px w-full">ไม่มีข้อมูลในงวดนี้</div>
    return gameData.gameDetails.map((item: ITableGameData, index: number) => {
      return (
        <div key={index}>
          {renderHeadTable(item.contentHead)}
          {renderContentTable(item.contentHead, item.details)}
        </div>
      )
    })
  }
  const renderHeadTable = (item: any): React.ReactElement => {
    return (
      <div className="flex items-center">
        {item.map((header: any, index: number) => {
          return (
            <div
              key={index}
              className={ index === 0 ? 'w-full min-w-[100px] pb-2.5 text-left pl-1.5' : 'w-full min-w-[100px] pb-2.5 text-right pr-1.5'}>
              {header.title}
            </div>
          )
        })}
      </div>
    )
  }
  const renderContentTable = (head: any, item: any): React.ReactNode => {
    return item.map((contentHead: any, index: number): any => {
      return (
        <div key={index} className={
          index === 0
          ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
          : index % 2 !== 0
          ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
          : 'flex items-center bg-white py-1.5 px-2 w-full'
        }>
          <div className="w-full text-left">{contentHead.playNumber}</div>
          <div className="w-full text-right">{contentHead.playAmount.toLocaleString('th')}</div>
        </div>
      )
    })
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => history.push({
        pathname: WEB_PATHS.GAME_PANEL.replace(':id', String(id))
      })}>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.GAME_NUMBER_SHOW}</span>
      </div>
       <div>
         <div className="pb-2.5">
           {gameData.gameCateName} ประจำงวดที่ {gameData.gameCateDate}
        </div>
        <div className="x-scroll w-full min-w-full">
          <div
            className="grid sm:grid-cols-2 grid-cols-2 sm:gap-6 gap-2.5">
            {renderTableShowListNumber()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLayout(GameListItems, 'Game Panel')