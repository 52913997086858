import { AxiosRequestConfig } from 'axios'
import Qs from 'qs'
import humps from 'humps'

export const serializeKeyRequestInterceptor = [
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if ((config.headers['Content-Type'] && config.headers['Content-Type'].search('json') !== -1) || !config.headers['Content-Type']) {
      config.data = config.data ? humps.decamelizeKeys(config.data) : config.data
    }
    config.params = config.params ? humps.decamelizeKeys(config.params) : config.params
    config.paramsSerializer = (params): string => {
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    }
    return config
  },
  (error: any): Promise<any> => Promise.reject(error)
]

export const tokenRequestInterceptor = [
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
      const token = 'Bearer ' + localStorage.getItem('accessToken')
      config.headers.Authorization = token
    } catch (e) {
      throw new Error(e as any)
    }
    return config
  },
  (error: any): Promise<any> => Promise.reject(error)
]
