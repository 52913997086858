import withLayout from "../../../layout/withLayout"
import { useHistory, useParams } from "react-router-dom"
import WEB_PATHS from "../../../constant/webPath"
import { MENU_TITLE } from "../../../constant/menuTitle"
import quickPanelApi from "../../../api/quickPanel.api"
import { useEffect, useState } from "react"
import IGameAllResult from "../../../model/QuickPanelApi/IGameAllResult"
import IGameResultData from "../../../model/QuickPanelApi/IGameResultData"
import { dateFormat } from "../../../utils/dateFormat"

const NumberPrimeResult = (): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const [gamePrimeResult, setGamePrimeResult] = useState<IGameAllResult>({
    count: 0,
    total: 0,
    results: []
  })
  useEffect(() => {
    const fetchGameResultAll = async (): Promise<void> => {
      const response = await quickPanelApi.getPrimeResult(id,history.location.search.replace('?qs=', ''))
      setGamePrimeResult(response)
    }
    fetchGameResultAll()
  }, [])
  const renderTable = (): React.ReactElement => {
    return (
      <div className="overflow-scroll scrollbar-hide sm:w-full w-fit pb-2.5">
        <div className="flex px-2 items-center overflow-auto scrollbar-hide w-full">
          <div className="sm:w-[5%] w-[30px]">#</div>
          <div className="sm:w-[15%] w-[150px] text-left">บัญชีผู้ใช้</div>
          <div className="sm:w-[10%] w-[220px] text-center">ประเภทที่เล่น</div>
          <div className="sm:w-[20%] w-[220px] text-right">เลขที่เล่น</div>
          <div className="sm:w-[15%] w-[200px] text-right px-2.5">จำนวนเงิน</div>
          <div className="sm:w-[10%] w-[220px] text-right">เรทที่ได้</div>
          <div className="sm:w-[15%] w-[150px] text-center">วันที่เล่น</div>
          <div className="sm:w-[10%] w-[150px] text-center">สถานะ</div>
        </div>
        {renderContent(gamePrimeResult.results)}
      </div>
    )
  }
  const renderContent = (item: IGameResultData[]): React.ReactNode => {
    if (item.length <= 0) return <div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2">ไม่มีข้อมูล</div>
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={content.userId}>
        <div className="sm:w-[5%] w-[30px] text-text-6">{index+1}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6">
          <span onClick={(): unknown => content.username
              ? history.push({pathname: WEB_PATHS.USER_EDIT.replace(':id', String(content.userId))})
              : ''
            } className="hover:cursor-pointer hover:underline hover:text-blue-600">
            {content.username ? content.username : '-'}
          </span>
        </div>
        <div className="sm:w-[10%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-center">{content.lottoGameType}</div>
        <div className="sm:w-[20%] w-[220px] overflow-hidden truncate text-16px text-text-6 text-right">{content.playNumber}</div>
        <div className="sm:w-[15%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-right px-2.5">{content.playAmount.toLocaleString('th')}</div>
        <div className="sm:w-[10%] w-[200px] overflow-hidden truncate text-16px text-text-6 text-right px-2.5">{content.payRate.toLocaleString('th')}</div>
        <div className="sm:w-[15%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-center">{dateFormat(content.transactionDate, 'dd-MM-yyyy HH:mm')}</div>
        <div className="sm:w-[10%] w-[150px] overflow-hidden truncate text-16px text-text-6 text-center">{content.statusString}</div>
      </div>
      )
    })
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => history.push({
        pathname: WEB_PATHS.GAME_PANEL.replace(':id', String(id))
      })}>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.GAME_PRIME_RESULT}</span>
      </div>
      {renderTable()}
    </div>
  )
}

export default withLayout(NumberPrimeResult, 'Game Panel', 'Game Prime Result')