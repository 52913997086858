import * as Yup from 'yup'

const schemaUserAddAmount = Yup.object({
  amount: Yup.number()
    .moreThan(0, 'ต้องใส่จำนวนมากกว่า 0')
    .typeError('กรุณาใส่ตัวเลขเท่านั้น')
    .required('กรุณากรอกจำนวนเงิน'),
  bankAccount: Yup.string()
    .required('กรุณาเลือกธนาคาร'),
  transferredAt: Yup.string()
    .required('กรุณาเลือกวัน-เวลา'),
  hours: Yup.string()
    .required('กรุณาเลือกชั่วโมง'),
  minute: Yup.string()
    .required('กรุณาเลือกนาที')
});

export default schemaUserAddAmount