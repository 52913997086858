import api from './index'
import IHomePageSetting from '../model/SettingsApi/IHomePageSetting'
import API_PATHS from '../constant/apiPath'
import ILineNotification from '../model/ILineNotification'

const textRunApi = {
  textRun: async(): Promise<IHomePageSetting> => {
    try {
      const response = await api.get(API_PATHS.TEXT_RUN)
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveTextRun: async(value: IHomePageSetting): Promise<void> => {
    try {
      await api.post(API_PATHS.TEXT_RUN_SAVE, value)
    } catch (e) {
      throw e
    }
  },
  getLineNotificationToken: async(): Promise<ILineNotification> => {
    try {
      const response = await api.get(API_PATHS.LINE_NOTIFICATION_SETTING)
      return response.data
    } catch (e) {
      throw e
    }
  },
  UpdateLineNotificationToken: async(values: ILineNotification): Promise<void> => {
    try {
      await api.put(API_PATHS.LINE_NOTIFICATION_SETTING, values)
    } catch (e) {
      throw e
    }
  },
}

export default textRunApi