import api from './index'
import Login from '../model/login'
import API_PATHS from '../constant/apiPath'

const loginApi = {
  loginSession: async (values: Login): Promise<void> => {
    try {
      const response = await api.post(API_PATHS.LOGIN, values)
      localStorage.setItem('accessToken', response.data.token)
    } catch (e) {
      throw e
    }
  }
}

export default loginApi