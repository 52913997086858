import api from './index'
import DashboardWallet from '../model/dashboard'
import API_PATHS from '../constant/apiPath'

const dashboardApi = {
  walletAdmin: async(): Promise<DashboardWallet> => {
    try {
      const response = await api.get(API_PATHS.DASHBOARD)
      return response.data
    } catch (e) {
      throw e
    }
  }
}

export default dashboardApi