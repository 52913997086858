import api from './index'
import API_PATHS from '../constant/apiPath'
import IGameLimitGov from '../model/GameRateApi/IGameLimitGov'
import IGameLimit from '../model/GameRateApi/IGameLimit'
import IGameRateLimit from '../model/GameRateApi/IGameRateLimit'
import IUserLimitRate from '../model/UserApi/IUserLimitRate'
import IGameRound from '../model/GamePanelApi/IGameRound'
import IGameRoundReCalculate from '../model/GamePanelApi/IGameRoundReCalculate'
import IGameTypeSelect from '../model/IGameTypeSelect'
import IFilter from '../model/IFilter'
import IResponseDataWithFilter from '../model/IResponseDataWithFilter'
import IGameRoundSelect from '../model/IGameRoundSelect'
import IGameYeeGeeSetting from '../model/GameYeegee/IGameYeeGee'
import IGameSetSetting from '../model/GameSet/IGameSetSetting'

const gameApi = {
  getGameRateSetting: async(id: string): Promise<IGameLimit> => {
    try {
      const response = await api.get(API_PATHS.GAME_RATE.replace(':id', id))
      return response.data
    } catch (e) {
      throw e
    }
  },
  updateGameRateSetting: async(values: IGameLimitGov[]): Promise<any> => {
    try {
      await api.put(API_PATHS.GAME_RATE.replace('/:id', ''), values)
    } catch (e) {
      throw e
    }
  },
  getGameRate: async(id: number): Promise<IGameRateLimit> => {
    try {
      const response = await api.get(API_PATHS.GAME.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveGameRate: async(values: any): Promise<void> => {
    try {
      await api.put(API_PATHS.GAME.replace('/:id', ''), values)
    } catch (e) {
      throw e
    }
  },
  getLimitRateUser: async(id: number): Promise<IUserLimitRate> => {
    try {
      const response = await api.get(API_PATHS.GAME_RATE_USER_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  getGameSetSetting: async(): Promise<IGameSetSetting> => {
    try {
      const response = await api.get(API_PATHS.GAME_SET_SETTING_GET.replace(':id', '11'))
      return response.data
    } catch (e) {
      throw e
    }
  },
  gameSetUpdateSetting: async(values: IGameSetSetting): Promise<IGameSetSetting> => {
    try {
      const response = await api.put(API_PATHS.GAME_SET_SETTING_UPDATE, values)
      return response.data
    } catch (e) {
      throw e
    }
  },
  getYeeGeeSetting: async(): Promise<IGameYeeGeeSetting> => {
    try {
      const response = await api.get(API_PATHS.GAME_YEE_GEE)
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveYeeGeeSetting: async(values: IGameYeeGeeSetting): Promise<void> => {
    try {
      await api.post(API_PATHS.GAME_YEE_GEE, values)
    } catch (e) {
      throw e
    }
  },
  saveLimitRateUser: async(values: number): Promise<void> => {
    try {
      await api.post(API_PATHS.GAME_RATE_USER, values)
    } catch (e) {
      throw e
    }
  },
  updateLimitRateUser: async(values: number): Promise<void> => {
    try {
      await api.put(API_PATHS.GAME_RATE_USER, values)
    } catch (e) {
      throw e
    }
  },
  getGameRateSystemSetting: async(id: string): Promise<IGameRateLimit> => {
    try {
      const response = await api.get(API_PATHS.GAME_RATE_SYSTEM.replace(':id', id))
      return response.data
    } catch (e) {
      throw e
    }
  },
  updateGameRateSystemSetting: async(values: IGameLimitGov[]): Promise<any> => {
    try {
      await api.put(API_PATHS.GAME_RATE_SYSTEM.replace('/:id', ''), values)
    } catch (e) {
      throw e
    }
  },
  getRoundGameById: async (id: string | number): Promise<IGameRound[]> => {
    try {
      const response = await api.get(API_PATHS.QUICK_PANEL_GAME_ROUND.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  postGameReCalculate: async (params: IGameRoundReCalculate): Promise<void> => {
    try {
      await api.post(API_PATHS.QUICK_PANEL_GAME_RE_CALCULATE, params)
    } catch (e) {
      throw e
    }
  },
  updateLimitAllUser: async (): Promise<void> => {
    try {
      await api.post(API_PATHS.USER_SET_GAME_LIMIT_ALL)
    } catch (e) {
      throw e
    }
  },
  getGameType: async (id: string): Promise<IGameTypeSelect[]> => {
    try {
      const rensponse = await api.get(API_PATHS.GAME_TYPE_SELECT.replace(':cate', String(id)))
      return rensponse.data
    } catch (e) {
      throw e
    }
  },
  getGameRound: async (queryFilter: IFilter): Promise<IResponseDataWithFilter> => {
    try {
      const rensponse = await api.get(API_PATHS.GAME_ROUND,{ params: queryFilter})
      return rensponse.data
    } catch (e) {
      throw e
    }
  },
  getGameRoundGen: async (type: string, year: string): Promise<IGameRound[]> => {
    try {
      const rensponse = await api.get(API_PATHS.GAME_ROUND_GENERATION,{ params: { qs: type, year: year}})
      return rensponse.data
    } catch (e) {
      throw e
    }
  },
  getGameRoundSelect: async (): Promise<IGameRoundSelect[]> => {
    try {
      const rensponse = await api.get(API_PATHS.GAME_ROUND_GAME_LIST)
      return rensponse.data
    } catch (e) {
      throw e
    }
  },
  postGameRound: async (params: IGameRound[]): Promise<void> => {
    try {
      await api.post(API_PATHS.GAME_ROUND, params)
    } catch (e) {
      throw e
    }
  },
  getGameRoundById: async (id: string): Promise<IGameRound> => {
    try {
      const response = await api.get(API_PATHS.GAME_ROUND_GENERATION_BY_ID.replace(':id', id))
      return response.data
    } catch (e) {
      throw e
    }
  },
  updateGameRoundById: async (id: string, params: any): Promise<void> => {
    try {
      await api.put(API_PATHS.GAME_ROUND_GENERATION_BY_ID.replace(':id', id), params)
    } catch (e) {
      throw e
    }
  }
}

export default gameApi