import * as Yup from 'yup'

const gameSetPriceRate = Yup.object({
  rateAmount: Yup.number()
  .required('required')
  // .test(
  //   "noEOrSign", // type of the validator (should be unique)
  //   "Number had an 'e' or sign.", // error message
  //   (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
  // )
});

export default gameSetPriceRate