import config from '../config/index'
import axios from 'axios'
import { serializeKeyRequestInterceptor, tokenRequestInterceptor } from './interceptors/request.interceptor'
import { serializeResponseInterceptor } from './interceptors/response.interceptor'

const api = axios.create({
  baseURL: config.API_URL,
  timeout: 20000
});

api.interceptors.request.use(...serializeKeyRequestInterceptor)
api.interceptors.request.use(...tokenRequestInterceptor)
api.interceptors.response.use(...serializeResponseInterceptor)

export default api