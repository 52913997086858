import api from './index'
import API_PATHS from '../constant/apiPath'
import IDeposit from '../model/SettingsApi/IDeposit'
import IDepositUpdateList from '../model/SettingsApi/IDepositUpdateList'
import IDepositSwicht from '../model/SettingsApi/IDepositSwitch'

const botSettingApi = {
  getBotSetting: async(): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.BOT_SETTING_WITHDRAW)
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveBotSetting: async(values: any): Promise<any> => {
    values.conditionList.forEach((item: any) => {
      item.priceStart = parseFloat(item.priceStart)
      item.priceEnd = parseFloat(item.priceEnd)
      item.bankId = parseFloat(item.bankId)
    })
    const dataRes = {
      isBotActive: values.isBotActive,
      conditionList: values.conditionList
    }
    try {
      await api.post(API_PATHS.BOT_SETTING_WITHDRAW, dataRes)
    } catch (e) {
      throw e
    }
  },
  getDepositBot: async(): Promise<IDeposit> => {
    try {
      const response = await api.get(API_PATHS.BOT_SETTING_DEPOSIT)
      return response.data
    } catch (e) {
      throw e
    }
  },
  updateDepositBot: async(deposit: IDepositSwicht): Promise<void> => {
    try {
      api.post(API_PATHS.BOT_SETTING_DEPOSIT, { ...deposit })
    } catch (e) {
      throw e
    }
  },
  updateDepositBotGroup: async(deposit: IDepositUpdateList): Promise<void> => {
    try {
      api.post(API_PATHS.BOT_SETTING_DEPOSIT_GROUP, deposit)
    } catch (e) {
      throw e
    }
  }
}

export default botSettingApi