import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/tailwind.css'
import { Toaster } from 'react-hot-toast'

ReactDOM.render(
  <React.StrictMode>
    <Toaster
      containerStyle={{
        top: 100
      }}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
