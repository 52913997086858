import { Formik } from 'formik'
import loginApi from '../../api/login.api'
import { useHistory } from 'react-router-dom'
import schema from '../../validation/loginSchema'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'
import WEB_TITLE from '../../constant/webTitle'

export default function Login(): React.ReactElement {
  const history = useHistory()
  document.title = WEB_TITLE.HEAD_NAME
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return(
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={schema}
      onSubmit={async (values, action): Promise<void> => {
        try {
          await loginApi.loginSession(values)
          toastActive({state: 'success', description: 'เข้าสู่ระบบสำเร็จ', duration: 500})
          history.push({ pathname: "/dashboard" })
        } catch (e: any) {
          action.resetForm()
          toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถล็อคอินระบบได้', duration: 500})
        }
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }): React.ReactElement => (
        <div className="bg-background-2 h-screen">
          <div className="w-full flex-col sm:mx-auto items-center sm:my-auto h-screen flex justify-center sm:px-0 px-[20px] rounded-lg">
            <div className="rounded-lg sm:w-[500px] w-full p-0.5">
              <div className="bg-titleBackground-0 text-center rounded-lg">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col bg-background-19 sm:px-[70px] p-5 sm:py-[70px] rounded-lg">
                    <div className="w-full text-40px text-text-2 pb-4">{WEB_TITLE.HEAD_NAME}</div>
                    <div className="text-left text-16px pb-3 font-katnitBold">เข้าสู่ระบบ</div>
                    <div className="w-full pb-5">
                      <label className="relative text-gray-400 focus-within:text-gray-600 block">
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full h-10 px-2.5 py-3 bg-input-0 rounded-lg border border-lineInput-0 placeholder:text-text-1 text-text-2 text-12px"
                            placeholder="กรอกชื่อผู้ใช้"
                            value={values.username}
                            name="username"
                            autoComplete="off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        {errors.username && touched.username &&
                        <div className="absolute w-9 h-9 top-0.5 right-0.5 flex items-center justify-center">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="9.5" fill="#DC1B1B" stroke="white"/>
                            <rect x="8.88889" y="4.44446" width="2.22222" height="7.77778" rx="1.11111" fill="white"/>
                            <circle cx="10" cy="14.4444" r="1.11111" fill="white"/>
                          </svg>
                        </div>
                        }
                      </label>
                      {errors.username && touched.username
                        ? <label className="flex items-center mt-2">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM6.54545 3.27273C6.54545 3.12806 6.48799 2.98933 6.38569 2.88703C6.2834 2.78474 6.14466 2.72727 6 2.72727C5.85534 2.72727 5.7166 2.78474 5.61431 2.88703C5.51201 2.98933 5.45455 3.12806 5.45455 3.27273V6.54545C5.45455 6.69012 5.51201 6.82886 5.61431 6.93115C5.7166 7.03344 5.85534 7.09091 6 7.09091C6.14466 7.09091 6.2834 7.03344 6.38569 6.93115C6.48799 6.82886 6.54545 6.69012 6.54545 6.54545V3.27273ZM6.54545 8.45455C6.54545 8.30988 6.48799 8.17114 6.38569 8.06885C6.2834 7.96656 6.14466 7.90909 6 7.90909C5.85534 7.90909 5.7166 7.96656 5.61431 8.06885C5.51201 8.17114 5.45455 8.30988 5.45455 8.45455V8.72727C5.45455 8.87194 5.51201 9.01068 5.61431 9.11297C5.7166 9.21526 5.85534 9.27273 6 9.27273C6.14466 9.27273 6.2834 9.21526 6.38569 9.11297C6.48799 9.01068 6.54545 8.87194 6.54545 8.72727V8.45455Z" fill="#DC1B1B"/>
                          </svg>
                          <p className="text-red-500 ml-1 text-xs text-left">{errors.username}</p>
                        </label>
                        : null
                      }
                    </div>
                    <div className="w-full pb-6">
                      <label className="relative block">
                        <input
                          type='password'
                          className="w-full h-10 px-2.5 py-3 bg-input-0 rounded-lg border border-lineInput-0 placeholder:text-text-1 text-text-2 text-12px"
                          placeholder="กรอกรหัสผ่าน"
                          value={values.password}
                          name="password"
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.password && touched.password &&
                        <div className="absolute w-9 h-9 top-0.5 right-0.5 flex items-center justify-center">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="9.5" fill="#DC1B1B" stroke="white"/>
                            <rect x="8.88889" y="4.44446" width="2.22222" height="7.77778" rx="1.11111" fill="white"/>
                            <circle cx="10" cy="14.4444" r="1.11111" fill="white"/>
                          </svg>
                        </div>
                        }
                      </label>
                      {errors.password && touched.password
                        ? <label className="flex items-center mt-2">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM6.54545 3.27273C6.54545 3.12806 6.48799 2.98933 6.38569 2.88703C6.2834 2.78474 6.14466 2.72727 6 2.72727C5.85534 2.72727 5.7166 2.78474 5.61431 2.88703C5.51201 2.98933 5.45455 3.12806 5.45455 3.27273V6.54545C5.45455 6.69012 5.51201 6.82886 5.61431 6.93115C5.7166 7.03344 5.85534 7.09091 6 7.09091C6.14466 7.09091 6.2834 7.03344 6.38569 6.93115C6.48799 6.82886 6.54545 6.69012 6.54545 6.54545V3.27273ZM6.54545 8.45455C6.54545 8.30988 6.48799 8.17114 6.38569 8.06885C6.2834 7.96656 6.14466 7.90909 6 7.90909C5.85534 7.90909 5.7166 7.96656 5.61431 8.06885C5.51201 8.17114 5.45455 8.30988 5.45455 8.45455V8.72727C5.45455 8.87194 5.51201 9.01068 5.61431 9.11297C5.7166 9.21526 5.85534 9.27273 6 9.27273C6.14466 9.27273 6.2834 9.21526 6.38569 9.11297C6.48799 9.01068 6.54545 8.87194 6.54545 8.72727V8.45455Z" fill="#DC1B1B"/>
                          </svg>
                          <p className="text-red-500 ml-1 text-xs pt-0.5 text-left">{errors.password}</p>
                        </label>
                        : null
                      }
                    </div>
                    <div className="w-full">
                      <button type={
                        values.username.length < 8 || values.password.length < 8
                        ? 'button'
                        : 'submit'
                      } className={
                        values.username.length < 8 || values.password.length < 8
                        ? 'bg-button-7 group w-full cursor-default py-2.5 rounded-lg text-white'
                        : 'bg-background-2 hover:bg-background-3 group w-full py-2.5 rounded-lg'
                      }>
                        <span className="text-16px text-white">เข้าสู่ระบบ</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="w-full text-center text-white">v.1.3</div>
          </div>
        </div>
      )}
    </Formik>
  )
}