import withLayout from "../../layout/withLayout"
import WEB_TITLE from "../../constant/webTitle"
import { MENU_TITLE } from "../../constant/menuTitle"
import BeradCrump from "../../components/BreadCrump"
import { useEffect, useState } from "react"
import { dateFormat } from "../../utils/dateFormat"
import depositApi from "../../api/deposit.api"
import IFilterBankTemp from "../../model/IFilterBankTemp"
import IDepositBankTemp from "../../model/DepositApi/IDepositBankTemp"
import IDepositBankTempGroupList from "../../model/DepositApi/IDepositBankTempGroupList"
import IDepositBankTempListDetail from "../../model/DepositApi/IDepositBankTempListDetail"
import { useHistory } from "react-router-dom"
import WEB_PATHS from "../../constant/webPath"
import classNames from "classnames"

const bankTempHistory = (route: any): React.ReactElement => {
  let queryFilter: IFilterBankTemp =  {
    type: route.query.query.get('type'),
    groupbank: route.query.query.get('groupbank')
  }
  const history = useHistory()
  const [date, setDate] = useState<any>('')
  const [hour, setHour] = useState('')
  const [minute, setMinute] = useState('')
  const [amount, setAmount] = useState('')
  const [depositGroupBank, setDepositGroupBank] = useState<IDepositBankTemp>({
    groupName: '',
    groupList: []
  })
  const [dateEmptyError, setDateEmptyError] = useState<boolean>(false)
  const [timeEmptyError, setTimeEmptyError] = useState<boolean>(false)
  const genTime = (time: number): string[] => {
    const element = []
    for (let index = 0; index < time; index++) {
      element.push(index > 9 ? String(index) : '0' + index)
    }
    return element
  }
  const hoursTime = genTime(24)
  const minuteTime = genTime(60)
  const getDataWithFilter = (): void => {
    if (!dateEmptyError && !timeEmptyError) {
      if (date !== '' && hour !== '' && minute !== '') {
        queryFilter = {
          ...queryFilter,
          date: date,
          hour: hour,
          minute: minute
        }
      }
      fetchDepositTemp()
    }
  }
  useEffect(() => {
    fetchDepositTemp()
  }, [])
  const fetchDepositTemp = async (): Promise<void> => {
    try {
      const response = await depositApi.depositTempHistory(Number(amount) > 0
        ? {...queryFilter, amount: amount}
        : date !== '' && hour !== '' && minute !== ''
        ? queryFilter = {
        ...queryFilter,
        date: date,
        hour: hour,
        minute: minute
      }
        : queryFilter)
      setDepositGroupBank(response)
      delete queryFilter.date
      delete queryFilter.hour
      delete queryFilter.minute
      delete queryFilter.amount
    } catch (error) {
      console.log(error)
    }
  }
  const renderDepositGroupTemp = (): React.ReactElement => {
    if (depositGroupBank.groupList.length <= 0) return (<div>ไม่มีข้อมูล</div>)
    return (
      <div>
        <div className="text-20px py-2">Group : {depositGroupBank.groupName}</div>
        <div className="flex">{renderGroupList(depositGroupBank.groupList)}</div>
      </div>
    )
  }
  const renderGroupList = (item: IDepositBankTempGroupList[]): React.ReactNode => {
    return item.map((group: IDepositBankTempGroupList, index: number) => {
      return (
        <div key={index} className="pr-5 min-w-[300px]">
          <div>{`${group.bankName.split(' ')[0]}${group.bankAccount}-${group.bankOwnerName}`}</div>
          <div>
            {renderTableGroupListDetail(group.tempBanksList)}
          </div>
        </div>
      )
    })
  }
  const renderTableGroupListDetail = (tempList: IDepositBankTempListDetail[]): React.ReactNode => {
    return (
        <div>
          <table>
            <thead className="border border-black">
              <tr className="text-center">
                <td className="w-60">จำนวนเงิน</td>
                <td className="border border-t-0 border-b-0 border-black w-60">เวลา</td>
                <td className="w-60">รายละเอียด</td>
              </tr>
            </thead>
            <tbody className="border border-black">
              {renderContent(tempList)}
            </tbody>
          </table>
        </div>
      )
  }
  const renderContent = (tempList: IDepositBankTempListDetail[]): React.ReactNode => {
    if (tempList.length <= 0) return (
      <tr>
        <th className="text-center" colSpan={3}>No data</th>
      </tr>
    )
    return tempList.map((temp: IDepositBankTempListDetail,index: number) => {
      return (
        <tr key={index} className={classNames(
          'text-center border border-black border-t-0 border-l-0 border-r-0',
          temp.id === 0 ? 'bg-background-6' : ''
        )}>
          <td onClick={(): void => goToUser(temp.id)}>{Number(temp.amount).toFixed(2)}฿</td>
          <td className="border border-t-0 border-b-0 border-black w-60">{dateFormat(temp.transactionDate, 'dd-MM-yyyy hh:mm')}</td>
          <td>{temp.message}</td>
        </tr>
      )
    })
  }
  const goToUser = (id: number | string): void => {
    if (id !== 0) {
      history.push({pathname: WEB_PATHS.USER_EDIT.replace('id', String(id))})
    }
  }
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col items-center sm:pb-6 pb-2 hover:cursor-pointer hover:opacity-70" onClick={(): any => history.push({pathname: WEB_PATHS.DEPOSIT.replace(':type', String(queryFilter.groupbank))})}>
        <svg className="mr-3" width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <BeradCrump headTitle={MENU_TITLE.DEPOSIT} showIcon={true} classTitle="font-katnitBold" title="ประวัติการฝากเงินในธนาคาร" />
      </div>
      <div className="pt-3 max-w-[300px]">
        <div className="pb-3">ค้นหาด้วยตัวกรอง</div>
        <div>
          <input
            name="date"
            value={date}
            onClick={(): void => {
              const inputDateElement: any = document.querySelector('input[type="date"]')
              inputDateElement.showPicker()
            }}
            onChange={(e): void => {
              setDate(e.target.value)
              if (Number(hour) <= 0 || Number(minute) <= 0) {
                setTimeEmptyError(true)
              } else {
                setTimeEmptyError(false)
              }
              if (e.target.value !== '') {
                setDateEmptyError(false)
              }
              if (Number(hour) > 0 && Number(minute) > 0) {
                setTimeEmptyError(false)
              }
            }}
            type="date"
            data-date-inline-picker="true"
            className="input-test min-w-[300px] p-2 bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1"
            placeholder="MM/DD/YYYY"
            max={dateFormat(new Date(), 'yyyy-MM-dd')}
          />
          {dateEmptyError
            ? <label
              className="block text-red-500 text-left">
                กรุณาเลือกวันที่
              </label>
            : null
          }
        </div>
        <div>
          <div className="text-16px py-3">
            วันที่ต้องการค้นหา
          </div>
          <div className="flex flex-row flex-1">
            <div className="w-full mr-1">
              <select
                className="w-full px-3 py-1.5 text-text-3 bg-input-0 border border-border-0 rounded-lg"
                name="hours"
                value={hour}
                onChange={(e): void => {
                  setHour(e.target.value)
                  if(date === '') {
                    setDateEmptyError(true)
                  } else {
                    setDateEmptyError(false)
                  }
                  if (Number(e.target.value) > 0 && Number(minute) > 0 && date !== '') {
                    setTimeEmptyError(false)
                  } else if (Number(e.target.value) <= 0 && Number(minute) <= 0 || date !== '') {
                    setTimeEmptyError(true)
                  }
                  if (date !== '') {
                    setDateEmptyError(false)
                  }
                }}
                aria-label="select"
              >
                <option value="">ชั่วโมงที่โอน</option>
                {hoursTime.map((hoursTimeDate: string, index: number) => {
                    return (
                      <option key={index} value={hoursTimeDate}>
                        {hoursTimeDate}
                      </option>
                    )
                })}
              </select>
            </div>
            <div className="w-full">
              <select
                className="w-full px-3 py-1.5 text-text-3 bg-input-0 border border-border-0 rounded-lg"
                name="minute"
                value={minute}
                onChange={(e): void => {
                  setMinute(e.target.value)
                  if(date === '') {
                    setDateEmptyError(true)
                  } else {
                    setDateEmptyError(false)
                  }
                  if (Number(hour) > 0 && Number(e.target.value) > 0 && date !== '') {
                    setTimeEmptyError(false)
                  } else if (Number(hour) <= 0 && Number(e.target.value) <= 0 || date !== '') {
                    setTimeEmptyError(true)
                  }
                  if (date !== '') {
                    setDateEmptyError(false)
                  }
                }}
                aria-label="select"
              >
                <option value="">นาทีที่โอน</option>
                {minuteTime.map((minuteTimeDate: string, index: number) => {
                    return (
                      <option key={index} value={minuteTimeDate}>
                        {minuteTimeDate}
                      </option>
                    )
                })}
              </select>
            </div>
          </div>
          {timeEmptyError
            ? <label
              className="block text-red-500 text-left">
                กรุณาเลือกชั่วโมงและนาที
              </label>
            : null
          }
        </div>
        <div className="pb-3">
          <div className="text-16px py-3">
            ค้นหาด้วยจำนวนเงิน
          </div>
          <input
            name="amount"
            type="text"
            value={amount}
            className="w-full p-2 bg-input-0 border border-border-0 rounded-lg text-text-3 placeholder-text-1 text-right"
            autoComplete="off"
            placeholder="จำนวนเงิน"
            onChange={(e):void => {
              if(Number(e.target.value) > 0) setAmount(e.target.value)
            }}
          />
        </div>
        <button
          onClick={(): void => getDataWithFilter()}
          className="text-16px rounded-lg p-3 text-white bg-background-11 w-full hover:bg-background-10">ค้นหา</button>
      </div>
      <div className="overflow-auto scrollbar-hide pb-3">
        {renderDepositGroupTemp()}
      </div>
    </div>
  )
}

export default withLayout(bankTempHistory, WEB_TITLE.DEPOSIT)