const typeGameTitle = [
  '',
  'หวยรัฐบาล',
  'หวยลาว',
  'ธกส',
  'ออมสิน',
  'จับยีกี่',
  'สิงคโปร์ 4D',
  'มาเลเซีย',
  'ฮานอยพิเศษ',
  'เวียดนาม(ฮานอย)',
  'ฮานอย VIP',
  'หวยลาวชุด',
  'นิเคอิ(เช้า)',
  'จีน(เช้า)',
  'ฮั่งเส็ง(เช้า)',
  'ไต้หวัน',
  'นิเคอิ(บ่าย)',
  'เกาหลี',
  'จีน(บ่าย)',
  'ฮั่งเส็ง(บ่าย)',
  'สิงคโปร์',
  'อินเดีย',
  'อิยิปต์',
  'รัสเซีย',
  'อังกฤษ',
  'เยอรมัน',
  'ดาวโจนส์'
]


export default typeGameTitle