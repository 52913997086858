import api from './index'
import API_PATHS from '../constant/apiPath'
import IUserMember from '../model/UserApi/IUserMember'
import IUser from '../model/UserApi/IUser'
import IUserFinanceHistory from '../model/UserApi/IUserFinanceHistory'
import IFilter from '../model/IFilter'
import IResponseDataWithFilter from '../model/IResponseDataWithFilter'
import IUserTransactionBet from '../model/UserApi/IUserTransactionBet'
import IUserTransactionBetGroup from '../model/UserApi/IUserTransactionBetGroup'
import IUserAddAmount from '../model/UserApi/IUserAddAmount'
import IUserAffiliateRevenue from '../model/UserApi/IUserAffiliateRevenue'
import IUserAffiliateHistory from '../model/UserApi/IUserAffiliateHistory'
import IUserAffiliateList from '../model/UserApi/IUserAffiliateList'
import IUserSubAmount from '../model/UserApi/IUserSubAmount'

const userApi = {
  getUserMember: async(params: any): Promise<IUser> => {
    try {
      const response = await api.get(API_PATHS.USER, { params })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getUserMemberWallet: async(id: number): Promise<number> => {
    try {
      const response = await api.get(API_PATHS.USER_WALLET_BY_ID.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  updateUserMemeberWallet: async(params: IUserAddAmount): Promise<void> => {
    try {
      await api.post(API_PATHS.USER_WALLET_UPDATE_BY_ID, params)
    } catch (e) {
      throw e
    }
  },
  updateSubAmountUserMemeberWallet: async(params: IUserSubAmount): Promise<void> => {
    try {
      await api.post(API_PATHS.USER_WALLET_UPDATE_SUB_AMOUNT_BY_ID, params)
    } catch (e) {
      throw e
    }
  },
  getUserAdmin: async(params: any): Promise<IUser> => {
    try {
      const response = await api.get(API_PATHS.ADMIN, { params })
      return response.data
    } catch (e) {
      throw e
    }
  },
  getUserById: async(id: any): Promise<IUserMember> => {
    try {
      const response = await api.get(API_PATHS.USER_ID.replace(':id', id))
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveUserById: async(values: any): Promise<any> => {
    try {
      await api.put(API_PATHS.USER, values)
    } catch (e) {
      throw e
    }
  },
  updateUserStatus: async(values: any): Promise<void> => {
    try {
      await api.post(API_PATHS.USER_BAN, values)
    } catch (e) {
      throw e
    }
  },
  userInfomation: async (): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.MEMBER_PROFILE)
      return response.data
    } catch (e) {
      throw e
    }
  },
  userFinanceHistory: async (id: string, params: any): Promise<IUserFinanceHistory> => {
    try {
      const response = await api.get(API_PATHS.USER_FINANCE_HISTORY.replace(':id', id), { params })
      return response.data
    } catch (e) {
      throw e
    }
  },
  userHistory: async (id: string | number, filter: IFilter): Promise<IResponseDataWithFilter> => {
    const params = {
      userId: id,
      ...filter
    }
    try {
      const response = await api.get(API_PATHS.USER_HISTORY, { params })
      return response.data
    } catch (e) {
      throw e
    }
  },
  userTransaction: async (id: string): Promise<IUserTransactionBet> => {
    try {
      const response = await api.get(API_PATHS.USER_TRANSACTION_BET.replace(':id', id))
      return response.data
    } catch (e) {
      throw e
    }
  },
  userTransactionGroup: async (id: string, idBet: string): Promise<IUserTransactionBetGroup> => {
    const replace = API_PATHS.USER_TRANSACTION_BET_BY_ID.replace(':id', id)
    try {
      const response = await api.get(replace.replace(':idBet', String(idBet)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  useAmountHistory: async (id: string): Promise<any> => {
    try {
      const response = await api.get(API_PATHS.USER_AMOUNT_HISTORY.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  useAffiliateRevenue: async (id: string): Promise<IUserAffiliateRevenue> => {
    try {
      const response = await api.get(API_PATHS.USER_AFFILIATE_REVENUE.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  },
  useAffiliateList: async (id: string, year: string): Promise<IUserAffiliateList[]> => {
    try {
      const response = await api.get(API_PATHS.USER_AFFILIATE_LIST.replace(':id', String(id)), { params: { year: year } })
      return response.data
    } catch (e) {
      throw e
    }
  },
  useAffiliateHistory: async (id: string): Promise<IUserAffiliateHistory[]> => {
    try {
      const response = await api.get(API_PATHS.USER_AFFILIATE_HISTORY.replace(':id', String(id)))
      return response.data
    } catch (e) {
      throw e
    }
  }
}

export default userApi