import api from './index'
import API_PATHS from '../constant/apiPath'
import PaymentSwitch from '../model/paymentSwitch'

const textRunApi = {
  switchBet: async(): Promise<PaymentSwitch> => {
    try {
      const response = await api.get(API_PATHS.SWITCH)
      return response.data
    } catch (e) {
      throw e
    }
  },
  saveSwitchBet: async(values: PaymentSwitch): Promise<any> => {
    try {
      await api.post(API_PATHS.SWITCH, { ...values })
      return
    } catch (e) {
      throw e
    }
  }
}

export default textRunApi