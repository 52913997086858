import WEB_TITLE from "../../constant/webTitle"
import withLayout from "../../layout/withLayout"
import BreakCrump from '../../components/BreadCrump'
import { MENU_TITLE } from "../../constant/menuTitle"
import Input from "../../components/Input"
import { useFormik, FormikProps } from "formik"
import Button from "../../components/Button"
import * as Yup from 'yup'
import ILineNotification from '../../model/ILineNotification'
import textRunApi from "../../api/textRun.api"
import { useState, useEffect } from "react"

const LineNotification = (): React.ReactElement => {
  const [lineToken, setLineToken] = useState<ILineNotification>({
    ownerLineKey: '',
    adminLineKey: ''
  })
  const schema = Yup.object({
    ownerLineKey: Yup.string()
      .required('กรุณากรอกข้อความ'),
      adminLineKey: Yup.string()
      .required('กรุณากรอกข้อความ')
  });
  const fetchLineNotification = async (): Promise<void> => {
    try {
      const response = await textRunApi.getLineNotificationToken()
      setLineToken(response)
    } catch (error) {
      console.log(error)
    }
  }
  const onSubmit = async (values: ILineNotification): Promise<void> => {
    try {
      await textRunApi.UpdateLineNotificationToken(values)
      await fetchLineNotification()
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    fetchLineNotification();
  }, [])
  const formik: FormikProps<ILineNotification> = useFormik<ILineNotification>({
    initialValues: {
      ownerLineKey: lineToken.ownerLineKey,
      adminLineKey: lineToken.adminLineKey
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: onSubmit,
  });
  return (
    <div>
      <BreakCrump headTitle="ระบบ" showIcon={true} title={MENU_TITLE.LINE_NOTIFICATION} />
        <div className="pt-6">
          <div className="flex flex-col">
            <Input
              name="ownerLineKey"
              type="text"
              value={formik.values.ownerLineKey}
              placeholder="token line สำหรับ owner"
              textLabelTopInput="Line token owner"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.ownerLineKey && formik.errors.ownerLineKey}
            />
          </div>
          <div>
            <Input
              name="adminLineKey"
              type="text"
              value={formik.values.adminLineKey}
              placeholder="token line สำหรับ admin"
              textLabelTopInput="Line token admin"
              showTextLabelTopInput={true}
              classNameLabel="text-gray-500 mb-2"
              inputClassName="w-full py-2.5 px-3 placeholder-text-1 text-text-3 bg-white border-color-1 rounded-lg"
              divClassName="flex flex-col mt-2 text-left sm:w-2/4 w-full"
              disabled={false}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.adminLineKey && formik.errors.adminLineKey}
            />
          </div>
          <div>
            <Button
              type="button"
              onClick={():void => {
                formik.handleSubmit()
              }}
              buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 mt-3 text-16px"
              buttonText="บันทึก"
            />
          </div>
        </div>
    </div>
  )
}

export default withLayout(LineNotification, WEB_TITLE.LINE)