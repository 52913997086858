import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import WEB_PATHS from "../../../constant/webPath"
import { MENU_TITLE } from "../../../constant/menuTitle"

const tabUserManage = ({
  state,
  id,
  type
}: {
  state: string
  id: string | number
  type?: string
}): React.ReactElement => {
  const history = useHistory()
  const [menu, setMenu] = useState([{
    name: 'ข้อมูลสมาชิก',
    state: false,
    pathname: type === 'admin' ? WEB_PATHS.USER_ADMIN_ID.replace(':id', String(id)) : WEB_PATHS.USER_EDIT.replace(':id', String(id))
  }, {
    name: 'ประวัติการแทงเล่นเกม',
    state: false,
    pathname: WEB_PATHS.USER_TRANSACTION_BET.replace(':id', String(id)),
    search: ''
  }, {
    name: 'ประวัติฝากถอน',
    state: false,
    pathname: WEB_PATHS.USER_HISTORY.replace(':id', String(id)),
    search: '?qs=all&skip=0&take=5'
  }, {
    name: 'รายการเดินบัญชี',
    state: false,
    pathname: WEB_PATHS.USER_FINANCE.replace(':id', String(id)),
    search: ''
  }, {
    name: 'ลิมิตการแทง',
    state: false,
    pathname: WEB_PATHS.USER_LIMIT_LATE.replace(':id', String(id)),
    search: ''
  }])
  useEffect(() => {
    if (state.length <= 0) {
        history.push({
        pathname: type === 'admin' ? WEB_PATHS.USER_ADMIN : WEB_PATHS.USER_LIST,
        search: '?qs=&take=5&skip=0'
      })
    }
    setMenu(menu.map(i => {
        i.name = i.name
        i.state = false
      return i
    }))
    const newArray = menu
    switch (state) {
      case MENU_TITLE.USER_EDIT:
        changeState(newArray, 'ข้อมูลสมาชิก')
        break;
      case MENU_TITLE.ADMIN_EDIT:
        changeState(newArray, 'ข้อมูลสมาชิก')
        break;
      case MENU_TITLE.USER_TRANSACTION_GAME:
        changeState(newArray, 'ประวัติการแทงเล่นเกม')
        break;
      case MENU_TITLE.USER_TRANSACTION_HISTORY:
        changeState(newArray, 'ประวัติฝากถอน')
        break;
      case MENU_TITLE.USER_FINANCE_HISTORY:
        changeState(newArray, 'รายการเดินบัญชี')
        break;
      case MENU_TITLE.USER_LIMIT:
        changeState(newArray, 'ลิมิตการแทง')
        break;
      default:
        break;
    }
    setMenu(newArray)
  }, [state])
  const changeState = (item: any, title: string): any => {
    return item[menu.findIndex((content: any) => content.name === title)].state = true
  }
  const renderMenu = (): React.ReactNode => {
    return menu.map((title) => {
      return (
        <div className={
          title.state
          ? 'pb-3 sm:w-full w-fit whitespace-nowrap border border-b-border-3 border-l-0 border-r-0 border-t-0'
          : 'pb-3 sm:w-full w-fit whitespace-nowrap group border border-t-0 border-l-0 border-r-0 border-b-transparent hover:border-b-border-3 hover:border-l-0 hover:border-r-0 cursor-pointer'
        }
          onClick={(): void => history.push({
            pathname: title.pathname,
            search: title.search
          })}
          key={title.name}
        >
          <div className={
            title.state
            ? 'text-text-5'
            : 'text-text-0 group-hover:text-text-5'
          }>{title.name}</div>
        </div>
      )
    })
  }
  return (
    <div className="flex items-center sm:w-full w-full max-w-fit flex-row gap-2.5 border border-b-border-0 border-l-0 border-t-0 border-r-0 overflow-auto scrollbar-hide">
      {renderMenu()}
    </div>
  )
}

export default tabUserManage