const WEB_TITLE = {
  HEAD_NAME: 'ST ADMIN',
  DASHBOARD: 'DASHBOARD',
  OVERVIEW: 'OVERVIEW',
  SETTINGS: 'SETTINGS',
  HOME: 'HOME TEXT RUN',
  LINE: 'LINE NOTOFICATION',
  PAYMENT_TRANSACTION: 'PAYMENT TRANSACTION',
  BOT: 'BOT',
  GAME_ROUND: 'Game Round',
  GAME: 'GAME',
  GAME_YEEKEE: 'Game Yeekee Setting',
  GAME_SET: 'Game Set Setting',
  GAME_LIMIT: 'Game Limit',
  GAME_RATE_SYSTEM: 'Game Limit System',
  GAME_RATE: 'Game Rate',
  BLOG: 'BLOG',
  BLOG_EDIT: 'BLOG EDIT',
  USER: 'User',
  MEMBER: 'Member',
  ADMIN: 'Admin',
  BANK: 'Bank',
  BANK_LIST: 'Bank List',
  BANK_GROUP: 'Bank Group',
  DEPOSIT: 'Deposit',
  DEPOSIT_ALL_GROUP: 'Deposit All Group',
  WITHDRAW: 'Withdraw',
  WITHDRAW_AUTO: 'Setting Withdraw Auto',
  GAME_PANEL: 'Game Panel',
  BANK_TRANFER: 'Bank Tranfer'
}

export default WEB_TITLE