import withLayout from '../../../layout/withLayout'
import { useEffect, useState } from 'react'
import gameApi from '../../../api/game.api'
import { Formik } from 'formik'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import * as Yup from 'yup'
import IGameRateLimit from '../../../model/GameRateApi/IGameRateLimit'
import WEB_TITLE from '../../../constant/webTitle'
import quickPanelApi from '../../../api/quickPanel.api'
import IQuickPanel from '../../../model/quickPanel'
import BeradCrump from '../../../components/BreadCrump'
import { MENU_TITLE } from '../../../constant/menuTitle'
import ToastStateIcon from '../../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../../model/toastState'
import IGameRateLimitDetail from '../../../model/GameRateApi/IGameLimitDetail'

const GameLimit = () => {
  const [gameRateSelect, setGameRateSelect] = useState<number>(0)
  const [quickPanel, setQuickPanel] = useState<IQuickPanel[]>([])
  const [checkDataOnChange, setCheckDataOnChange] = useState(false);
  const [gameRate, setGameRate] = useState<IGameRateLimit>({
    id: 0,
    name: '',
    description: '',
    govLottoCateType: []
  })
  const fetchQuickPanel = async (): Promise<void> => {
    const response = await quickPanelApi.getQuickPanel()
    setQuickPanel(response)
  }
  const fetchGameLateData = async (id: number): Promise<void> => {
    const response = await gameApi.getGameRate(id)
    setGameRate(response)
  }
  const updateGameLimitAllUser = async (): Promise<void> => {
    try {
      await gameApi.updateLimitAllUser()
      toastActive({state: 'success', description: 'อัพข้อมูลเกมส์ลิมิต ให้ยูสเซอทั้งหมดแล้ว', duration: 500})
      setCheckDataOnChange(false)
      setGameRateSelect(0)
    } catch (error) {
      console.log(error, 'limit log')
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
    }
  }
  useEffect(() => {
    if (gameRateSelect !== 0) fetchGameLateData(gameRateSelect)
  }, [gameRateSelect])
  useEffect(() => {
    fetchQuickPanel()
  }, [])
  const schema = Yup.object().shape({
    govLottoCateType: Yup.array().of(
      Yup.object().shape({
        limit: Yup.string()
          .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
          .required('กรุณากรอกข้อมูล')
      })
    )
  })
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  const renderRow = (props: any) => {
    return props.values.govLottoCateType.map((lotto: IGameRateLimitDetail, index: number) => {
      return (
        <div key={index} className="flex w-full pb-3 gap-3">
          <Input
            name={`govLottoCateType.${index}.name`}
            type="text"
            value={lotto.name}
            placeholder=""
            textLabelTopInput="หัวข้อ"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={(e: any): void => {
              props.handleChange(e)
              setCheckDataOnChange(false)
            }}
            error={null}
          />
          <Input
            name={`govLottoCateType.${index}.description`}
            type="text"
            value={lotto.description}
            placeholder=""
            textLabelTopInput="ประเภท"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={(e: any): void => {
              props.handleChange(e)
              setCheckDataOnChange(false)
            }}
            error={null}
          />
          <Input
            name={`govLottoCateType.${index}.limit`}
            type="text"
            value={lotto.limit}
            placeholder="กรอกจำนวนเงิน"
            textLabelTopInput="ลิมิต"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-left text-text-2 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[40%]"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={(e: any): void => {
              props.handleChange(e)
              setCheckDataOnChange(false)
            }}
            error={
              props.errors &&
              props.errors.govLottoCateType &&
              props.errors.govLottoCateType[index] &&
              props.errors.govLottoCateType[index].limit &&
              props.touched &&
              props.touched.govLottoCateType &&
              props.touched.govLottoCateType[index] &&
              props.touched.govLottoCateType[index].limit && props.errors.govLottoCateType[index].limit
            }
          />
        </div>
      )
    })
  }
  const onChangeSelect = (e: any): void => {
    setGameRateSelect(e.target.value)
  }
  const renderSelect = (): React.ReactNode => {
    if (quickPanel.length < 1) return
    return (
      <select
        name="typeGameSelect"
        className="border border-border-0 text-text-2 px-3 py-2 text-16px w-full rounded-lg bg-white"
        onChange={onChangeSelect}
        value={gameRateSelect}
      >
        <option value="0">กรุณาเลือกประเภทหวย</option>
        {quickPanel.map((type, index) => {
          return (
          <option
            key={index} value={type.gameCateId}
          >
            {type.name}
          </option>)
        })}
      </select>
    )
  }
  return (
    <div>
      <BeradCrump
        headTitle={MENU_TITLE.GAME}
        showIcon={true}
        classTitle="font-katnitBold"
        title={MENU_TITLE.GAME_LIMIT}
      />
      <div className="pt-6 pb-3 sm:w-2/4 w-full">
        {renderSelect()}
        <Button
          buttonClassName="bg-background-2 mt-3 hover:bg-background-8 text-white py-2 px-3 text-16px"
          buttonText="ซิ้งค์ข้อมูลทั้งหมด"
          onClick={(): void => {
            if (checkDataOnChange) {
              updateGameLimitAllUser()
            } else {
              toastActive({state: 'error', description: 'กรุณากดบันทึกข้อมูลก่อนทำการซิ้งค์ข้อมูล', duration: 500})
            }
          }}
          type="button"
        />
      </div>
      <div>
      {gameRateSelect > 0 &&
        <Formik
        initialValues={{
          id: gameRate.id,
          name: gameRate.name,
          description: gameRate.description,
          govLottoCateType: gameRate.govLottoCateType
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values: IGameRateLimit) => {
          const data = values.govLottoCateType.map((item: IGameRateLimitDetail) => {
            return {
              id: item.gameCateId,
              limit: Number(item.limit),
              gameCateType: item.type,
              gameCateId: Number(gameRateSelect)
            }
          })
          try {
            await gameApi.saveGameRate(data)
            setGameRateSelect(0)
            toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
            setCheckDataOnChange(false)
            } catch (e) {
              toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
              setCheckDataOnChange(true)
            }
          }
        }>
        {props => (
          <form onSubmit={props.handleSubmit}>
            <div className="sm:w-3/4 w-full">
              <div className="pb-3 w-full sm:flex hidden gap-3 border border-b-border-0">
                <div className="sm:w-[30%] text-16px">หัวข้อ</div>
                <div className="sm:w-[30%] text-16px">ประเภท</div>
                <div className="sm:w-[40%] text-16px">ลิมิต</div>
              </div>
              <div className="pt-3">
                {renderRow(props as any)}
              </div>
            </div>
            <Button
              buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
              buttonText="บันทึก"
              type="submit"
            />
          </form>
        )}
        </Formik>
      }
      </div>
    </div>
  )
}

export default withLayout(GameLimit, WEB_TITLE.GAME, WEB_TITLE.GAME_LIMIT)