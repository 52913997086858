import withLayout from '../../layout/withLayout'
import { useParams, useHistory } from 'react-router'
import { useState, useEffect } from 'react'
import gameApi from '../../api/game.api'
import { Formik } from 'formik'
import TabMenu from './layout/index'
import Button from '../../components/Button'
import Input from '../../components/Input'
import * as Yup from 'yup'
import WEB_PATHS from '../../constant/webPath'
import IUserLimitRate from '../../model/UserApi/IUserLimitRate'
import { MENU_TITLE } from '../../constant/menuTitle'
import ToastStateIcon from '../../components/ToastStateIcon'
import toast from 'react-hot-toast'
import IToastState from '../../model/toastState'

const limitLateUser = () => {
  const { id }: any = useParams()
  const history = useHistory()
  const [limitLate, setLimitLate] = useState<IUserLimitRate>({
    id: 0,
    name: '',
    description: '',
    userGovLottoCateType: []
  })
  const fetchLimitLateDate = async () => {
    try {
      const response = await gameApi.getLimitRateUser(id)
      setLimitLate(response)
    } catch (e) {
      toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลได้', duration: 500})
    }
  }
  useEffect(() => {
    fetchLimitLateDate()
  }, [])
  const schema = Yup.object().shape({
    govLottoCateType: Yup.array().of(
      Yup.object().shape({
        limit: Yup.string()
          .matches(/^[0-9]*$/, 'กรุณากรอกตัวเลขเท่านั้น')
          .required('required')
      })
    )
  })
  const renderRow = (props: any) => {
    return props.values.userGovLottoCateType.map((lotto: any, index: number) => {
      return (
        <div key={index} className="flex w-full gap-3 pb-3">
          <Input
            name={`userGovLottoCateType.${index}.name`}
            type="text"
            value={lotto.name}
            placeholder=""
            textLabelTopInput="หัวข้อ"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
          <Input
            name={`userGovLottoCateType.${index}.description`}
            type="text"
            value={lotto.description}
            placeholder=""
            textLabelTopInput="ประเภท"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-background-0 w-full sm:h-[40px] text-left text-text-0 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[30%]"
            disabled={true}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
          <Input
            name={`userGovLottoCateType.${index}.limit`}
            type="text"
            value={lotto.limit}
            placeholder=""
            textLabelTopInput="ลิมิต"
            showTextLabelTopInput={false}
            classNameLabel="text-color-1 sm:text-16px text-12px pb-1 font-katnitBold"
            inputClassName="bg-white w-full sm:h-[40px] placeholder-text-1 text-left text-text-2 border border-border-0 h-8 py-3 px-2.5 rounded-lg"
            divClassName="flex flex-col w-[40%]"
            disabled={false}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
            error={
              props.errors &&
              props.errors.userGovLottoCateType &&
              props.errors.userGovLottoCateType[index] &&
              props.errors.userGovLottoCateType[index].limit &&
              props.touched &&
              props.touched.userGovLottoCateType &&
              props.touched.userGovLottoCateType[index] &&
              props.touched.userGovLottoCateType[index].limit && props.errors.userGovLottoCateType[index].limit
            }
          />
        </div>
      )
    })
  }
  const toastActive = (item: IToastState): any => {
    return toast.custom(
      <div className="flex rounded-lg w-[280px]">
        <ToastStateIcon stateIcon={String(item.state)} />
        <div className="bg-background-1 rounded-r-lg flex items-center w-full text-text-2 text-left">
          {item.description}
        </div>
      </div>,{
        duration: item.duration
      }
    )
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => history.push({pathname: WEB_PATHS.USER_EDIT.replace(':id', id)})}>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.USER_LIMIT}</span>
      </div>
      <div>
        <TabMenu state={MENU_TITLE.USER_LIMIT} id={id} />
      </div>
      <Formik
        initialValues={{
          id: limitLate.id,
          name: limitLate.name,
          description: limitLate.description,
          userGovLottoCateType: limitLate.userGovLottoCateType
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values: any) => {
          const data = values.userGovLottoCateType.map((item: any) => {
            return item.userLimitId === 0
              ? { userId: Number(id), gameCateLimitId: Number(item.gameCateId), limit: Number(item.limit) }
              : { id: Number(item.userLimitId), limit: Number(item.limit) }
          })
          try {
            data[0].id
              ? await gameApi.updateLimitRateUser(data)
              : await gameApi.saveLimitRateUser(data)
              toastActive({state: 'success', description: 'บันทึกข้อมูลสำเร็จ', duration: 500})
              history.push({
                pathname: WEB_PATHS.USER_LIST,
                search: `?qs=&skip=0&take=5`
              })
          } catch (e) {
            toastActive({state: 'error', description: 'เกิดข้อผิดพลาด ไม่สามารถบันทึกข้อมูลได้', duration: 500})
          }
        }}
      >
        {props => (
        <form onSubmit={props.handleSubmit}>
          <div className="sm:w-3/4 w-full pt-3">
            <div className="pb-3 w-full flex gap-3 border border-b-border-0">
              <div className="w-[30%] text-16px">หัวข้อ</div>
              <div className="w-[30%] text-16px">ประเภท</div>
              <div className="w-[40%] text-16px">ลิมิต</div>
            </div>
            <div className="pt-3">
              {renderRow(props as any)}
            </div>
          </div>
          <Button
            buttonClassName="bg-background-2 hover:bg-background-8 text-white py-2 px-3 text-16px"
            buttonText="บันทึก"
            type="submit"
          />
        </form>
        )}
      </Formik>
    </div>
  )
}

export default withLayout(limitLateUser, 'User')