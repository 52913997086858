import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import IFilter from '../model/IFilter'

interface Pagination {
  pageSelect: number
  pageNumber: number[]
  pageMax: number
  nextPage: () => void
  backPage: () => void
  jumpToPage: (pageNumber: number) => void
}

function usePagination (
  filter: IFilter,
  count: number,
): Pagination {
  const history = useHistory()
  const [pageSelect, setPageSelect] = useState(0)
  const [pageNumber, setPageNumber] = useState<number[]>([])
  const [pageMax, setPageMax] = useState(0)
  const fixCountPage = 8
  const countTotal = 8
  const countPageMax = Math.ceil(count / filter.take)
  const paginatonNumber = (): number[] => {
    const page = []
    if (countPageMax <= countTotal) {
      for (let index = 1; index <= countPageMax; index++) {
        page.push(index)
      }
    } else {
      const calStart = Math.floor(pageSelect / fixCountPage) + (Math.floor(pageSelect % fixCountPage) > 0 ? 1 : 0)
      const start = pageSelect <= fixCountPage ? 1 : Math.pow(2,calStart + 1) + 1
      const calEnd = Math.floor(countTotal / fixCountPage) * fixCountPage
      const end = pageSelect <= fixCountPage
        ? fixCountPage
        : calEnd >= pageSelect ? calEnd : countTotal
      for (let index = start; index <= end; index++) {
        page.push(index)
      }
    }
    return page
  }
  const nextPage = (): void => {
    setPageSelect(pageSelect + 1)
    history.push({
      pathname: history.location.pathname,
      search: filter.qs !== undefined
      ? `?qs=${filter.qs}&take=${filter.take}&skip=${Number(filter.take) + Number(filter.skip)}`
      : `?take=${filter.take}&skip=${Number(filter.take) + Number(filter.skip)}`
    })
  }
  const jumpToPage = (pageNumber: number): void => {
    const pageJump = (pageNumber !== 0 ? pageNumber - 1 : 1) * filter.take
    setPageSelect(pageNumber)
    const typeFilter = filter.qs !== undefined
      ? `?qs=${filter.qs}&take=${filter.take}&skip=${pageJump}`
      : `?take=${filter.take}&skip=${pageJump}`
    history.push({
      pathname: history.location.pathname,
      search: typeFilter
    })
  }
  const backPage = (): void => {
    setPageSelect(pageSelect - 1)
    const typeFilter = filter.qs !== undefined
      ? `?qs=${filter.qs}&take=${filter.take}&skip=${filter.skip - filter.take}`
      : `?take=${filter.take}&skip=${filter.skip - filter.take}`
    history.push({
      pathname: history.location.pathname,
      search: typeFilter
    })
  }
  useEffect(() => {
    if (count > 0) {
      setPageSelect(filter.skip * 1 === 0 ? 1 : (filter.skip / filter.take) + 1)
      setPageMax(countPageMax)
      const numberArray = paginatonNumber()
      setPageNumber(numberArray)
    }
  }, [count, filter])
  return {
    pageSelect,
    pageNumber,
    pageMax,
    nextPage,
    backPage,
    jumpToPage
  }
}

export default usePagination