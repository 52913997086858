import withLayout from '../../layout/withLayout'
import IUserFinanceHistoryData from '../../model/UserApi/IUserFinanceHistoryData'
import { useState, useEffect } from 'react'
import userApi from '../../api/user.api'
import { useParams, useHistory } from 'react-router-dom'
import Paginations from '../../components/Paginations'
import { dateFormat } from '../../utils/dateFormat'
import WEB_PATHS from '../../constant/webPath'
import { MENU_TITLE } from '../../constant/menuTitle'
import TabMenu from './layout/index'


const FinanceHistory = (route: any): React.ReactElement => {
  const { id }: any = useParams()
  const history = useHistory()
  const queryFilter = {
    qs: route.query.query.get('qs') ? route.query.query.get('qs') : '',
    take: route.query.query.get('take') ? Number(route.query.query.get('take')) : 5,
    skip: route.query.query.get('skip') ? Number(route.query.query.get('skip')) : 0
  }
  const [count, setCount] = useState<number>(0)
  const [financeHistory, setFinanceHistory] = useState<IUserFinanceHistoryData[]>([])
  const fectFinanceHistory = async (): Promise<void> => {
    const response = await userApi.userFinanceHistory(id, queryFilter)
    setFinanceHistory(response.data)
    setCount(count ? count : 0)
  }
  useEffect(() => {
    fectFinanceHistory()
  }, [route])
  const backPage = (): void => {
    history.push({
      pathname: WEB_PATHS.USER_EDIT.replace(':id', id)
    })
  }
  const renderTableFinance = (): React.ReactElement => {
    return (
      <div className="sm:w-full sm:min-w-[1500px] w-fit pb-2.5">
        <div className="flex items-center w-full px-2">
          <div className="sm:w-[50px] w-[50px]">#</div>
          <div className="sm:w-[200px] w-[200px] text-center">จำนวน</div>
          <div className="sm:w-[200px] w-[200px] text-center">คงเหลือ</div>
          <div className="sm:w-[200px] w-[200px] text-center">ข้อมูล</div>
          <div className="sm:w-[200px] w-[200px] text-center">รายละเอียด</div>
          <div className="sm:w-[200px] w-[200px] text-center">สถานะ</div>
          <div className="sm:w-[200px] w-[200px] text-center">วันที่ทำรายการ</div>
        </div>
        {renderContent(financeHistory)}
      </div>
    )
  }
  const renderContent = (item: IUserFinanceHistoryData[]): React.ReactNode => {
    if (item.length <= 0) return (<div className="w-full text-center text-16px py-2 bg-background-1 border-border-0 border-t-2 text-text-6">ไม่มีข้อมูล</div>)
    return item.map((content, index) => {
      return (
      <div className={
        index === 0
        ? 'flex border-border-0 border-t-2 items-center bg-white py-1.5 px-2 w-full'
        : index % 2 !== 0
        ? 'flex items-center bg-background-12 py-1.5 px-2 w-full'
        : 'flex items-center bg-white py-1.5 px-2 w-full'
      } key={content.id}>
        <div className="sm:w-[50px] w-[50px] text-text-6">{index+1}</div>
        <div className="sm:w-[200px] w-[200px] text-center text-text-6">{content.status === 'IN' ? '+' : '-'}{content.amount.toLocaleString('th')} ฿</div>
        <div className="sm:w-[200px] w-[200px] text-center text-text-6">{content.balance.toLocaleString('th')} ฿</div>
        <div className="sm:w-[200px] w-[200px] overflow-hidden truncate text-16px text-text-6 text-center">{content.name}</div>
        <div className="sm:w-[200px] w-[200px] overflow-hidden text-16px text-text-6 text-center">{content.description}</div>
        <div className="sm:w-[200px] w-[200px] overflow-hidden truncate text-16px text-text-6 text-center">{content.status}</div>
        <div className="sm:w-[200px] w-[200px] overflow-hidden truncate text-16px text-text-6 text-center">{dateFormat(content.createdAt, 'dd-MM-yyyy HH:mm')}</div>
      </div>
      )
    })
  }
  return (
    <div>
      <div className="flex w-fit items-center cursor-pointer pb-6" onClick={ () => backPage() }>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.85117 0.0238832L0.154687 5.59097C-0.0515631 5.79306 -0.0515631 6.20672 0.154687 6.40724L5.85117 11.9759C5.86518 11.9896 5.88174 11.9978 5.899 11.9996C5.91626 12.0014 5.93355 11.9968 5.94896 11.9861C5.96438 11.9755 5.9773 11.9593 5.98631 11.9394C5.99532 11.9195 6.00006 11.8966 6 11.8733L6 9.27273C6 9.19221 5.9707 9.11642 5.92266 9.06905L2.625 5.99989L5.92266 2.72727C5.9707 2.67991 6 2.60412 6 2.5236L6 0.126509C6 0.0238832 5.91328 -0.0361143 5.85117 0.0238832Z" fill="#1E2339"/>
        </svg>
        <span className="ml-3 text-20px font-katnitBold">{MENU_TITLE.USER_FINANCE_HISTORY}</span>
      </div>
      <div className="pb-5">
        <TabMenu state={MENU_TITLE.USER_FINANCE_HISTORY} id={id} />
      </div>
      <div>
        {renderTableFinance()}
      </div>
      <Paginations
        filter={queryFilter}
        count={count}
        divClassName=""
      />
    </div>
  )
}

export default withLayout(FinanceHistory, 'User')